import { ResponsivePie } from "@nivo/pie";
import { tokens } from "../../theme";
import { useTheme } from "@mui/material";

import React from "react";

function GraficaPie(data) {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const formatValueAsMoney = (value) => {
    let location
    let prefix
    if (data.moneda !== undefined) {
      if (data.moneda === 'USD') {
        location = 'en-US'
        prefix = 'USD'
      } else {
        location = 'es-MX'
        prefix = 'MXN'
      }
      
    }
    return `${Number(value).toLocaleString(location, {
      minimumFractionDigits: 2,
    })} ${prefix}`
  };

  return (
    <ResponsivePie
      data={data.data}
      theme={{
        labels: {
          text: {
            fontSize: 10, // Adjust the font size here
          },
        },
        axis: {
          domain: {
            line: {
              stroke: colors.grey[100],
            },
          },
          legend: {
            text: {
              fill: colors.grey[100],
            },
          },
          ticks: {
            line: {
              stroke: colors.grey[100],
              strokeWidth: 1,
            },
            text: {
              fill: colors.grey[100],
            },
          },
        },
        legends: {
          text: {
            fill: colors.grey[100],
          },
        },
        tooltip: {
          container: {
            background: colors.primary[900],
          },
        },
      }}
      colors={/* (datum) => datum.data.color */ { scheme: 'accent' }}
      margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
      innerRadius={0.5}
      padAngle={0.7}
      cornerRadius={3}
      activeOuterRadiusOffset={8}
      borderColor={{
        from: "color",
        modifiers: [["darker", 0.2]],
      }}
      arcLinkLabelsSkipAngle={10}
      arcLinkLabelsOffset={-12}
      arcLinkLabelsTextColor={colors.grey[100]}
      arcLinkLabelsTextOffset={0}
      arcLinkLabelsThickness={2}
      arcLinkLabelsColor={{ from: "color" }}
      enableArcLinkLabels={false}
      enableArcLabels={true}
      arcLabel={d => d.formattedValue }
      arcLabelsRadiusOffset={0.6}
      arcLabelsSkipAngle={7}
      arcLabelsTextColor={{
        from: "color",
        modifiers: [["darker", 2]],
      }}
      legends={[
        {
          anchor: 'bottom-left',
          direction: 'column',
          justify: false,
          translateX: 0,
          translateY: 56,
          itemsSpacing: 2,
          itemWidth: 100,
          itemHeight: 18,
          itemTextColor: '#999',
          itemDirection: 'left-to-right',
          itemOpacity: 1,
          symbolSize: 18,
          symbolShape: 'circle',
          effects: [
            {
              on: 'hover',
              style: {
                itemTextColor: colors.grey[100]
              }
            }
          ]
        }
      ]}
      valueFormat={(value) => formatValueAsMoney(value)}
    />
  );
}

export default GraficaPie;
