import { useEffect, useState } from "react"
import api from "../../../config/axios";
import { Card, CardContent, Typography, CardActions, Button, colors, Grid } from "@mui/material";
import SettingsEthernetIcon from '@mui/icons-material/SettingsEthernet';
import { useTheme } from "@emotion/react";
import { tokens } from "../../../theme";
import { marcarURL } from "../../../config/functions";
import Cookies from "js-cookie";


const Conexiones = (props) => {
    const { setEstatusParent } = props;
    const REDIRECT_URI = `https://qhbvkq4g-3000.usw3.devtunnels.ms/profitnl`;
    // const REDIRECT_URI = `https://siif.elitebordergroup.com/profitnl`;
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    useEffect(() => {
        marcarURL('profitnl');
    }, []);
    const [selectedItem, setSelectedItem] = useState([]);
    useEffect(() => {
        const url = window.location.href;
		if (url.includes("code")) {
			// Espera a que la URL tenga algo antes de llamar a loadData
			const code = url.split("code=")[1];
			const realmId = url.split("realmId=")[1];
			if (code && realmId && selectedItem) {
				updateTokens(url,realmId, selectedItem);
                window.history.replaceState({}, document.title, Cookies.get('profitnl'));
			}
		} else {
			// Llama a loadData inmediatamente
			obtenerEstatusTokens();
		}
    }, [window.location.href]);
    const [estatus, setEstatus] = useState([]);
    const obtenerEstatusTokens = async () => {
        console.log("Obteniendo estatus de tokens");
        const response = await api.get(`/get/estatus/tokens`);
        console.log(response.data);
        if (response.data.success === true) {
            setEstatus(response.data.result);
            setEstatusParent(response.data.result);
        } else {
            setEstatus([]);
            setEstatusParent([]);
        }
    }
    const updateTokens = async (url, realmId, selectedItem) => {
        const response = await api.post(`/update/tokens`, { url, realmId, selectedItem});
        if (response.data.success === true) {
            obtenerEstatusTokens();
        } else {
            setEstatusParent([]);
            setEstatus([]);
        }
    }
    const handleClick = (cardData) => {
        setSelectedItem(cardData);
        const clientId = 'ABZE10xYqBh6WDyQwlVR9MHrVSivOulqRGoerlmzoqzZJB9lxD';
        const redirectUri = encodeURIComponent(REDIRECT_URI); 
        // const redirectUri = encodeURIComponent(`https://siif.elitebordergroup.com/ordencomparativa`); 
        const scope = encodeURIComponent('com.intuit.quickbooks.accounting');
        const authUri = `https://appcenter.intuit.com/connect/oauth2?client_id=${clientId}&redirect_uri=${redirectUri}&scope=${scope}&response_type=code&state=200`; 
    
        // Redirige al usuario a la URL de autorización de Intuit
        window.location.href = authUri;
    };
    const CardEstatus = ({ realmId, nombreEmpresa, estatus, cardData}) => {
        let color = estatus ? 'green' : 'red';
        let mensaje = estatus ? 'Conectado' : 'Desconectado';
        return(
            <Card style={{ borderRadius:'10px',marginTop:'15px', paddingBottom: '15px',width:'400px',background:colors.grey[700]}}>
                <CardContent>
                    <Grid container>
                        <Grid item xs={12} md={12} style={{display: 'flex', alignItems: 'center'}}>
                            <Typography variant="h5" color="textSecondary">
                                {nombreEmpresa} - {realmId}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={12} style={{display: 'flex', alignItems: 'center'}}>
                            <div style={{
                                height: '10px',
                                width: '10px',
                                backgroundColor: color,
                                borderRadius: '50%',
                                display: 'inline-block',
                                marginRight: '10px'
                            }}/>
                            <Typography variant="h5" color="textSecondary">
                                {mensaje}
                            </Typography>
                        </Grid>
                    </Grid>
                </CardContent>
                {
                    !estatus &&
                    <CardActions disableSpacing style={{ justifyContent: 'flex-end' }}>
                        <Button size="small" color="secondary" startIcon={<SettingsEthernetIcon/>} onClick={() => handleClick(cardData)}>
                            Conectar a quickbooks
                        </Button>
                    </CardActions>
                }
            </Card>
        )
    }
    return(
        estatus.map((item, index) => (
            <CardEstatus key={index} realmId={item.realmId} nombreEmpresa={item.nombre_empresa} estatus={item.estatus} cardData={item} />
        ))
    )
}
export default Conexiones;
