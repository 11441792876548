import React from 'react';
import { Box, useTheme, Typography } from '@mui/material';
import { tokens } from '../../theme';

const StatBoxDashboard = ({ cantidadPesos, cantidadDolares, empresa, icon, icon2, variacionMx, variacionUsd }) => {

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    

    let isnull;
    if (variacionMx != null) {
        isnull = true
    } else {
        isnull = false
    }

    return (

        <Box width="100%" m="0 30px">
            <Box display="flex" justifyContent="space-between">
                <Box>
                    {icon}
                    <Typography variant='h5' fontWeight="bold" sx={{ color: colors.grey[100] }}>
                        $ {cantidadPesos} MXN
                    </Typography>
                    <Typography variant='h5' fontWeight="bold" sx={{ color: colors.grey[100] }}>
                        $ {cantidadDolares} USD
                    </Typography>
                </Box>
                <Box>
                    {icon2}
                </Box>
            </Box>
            {isnull &&
                <>
                    <Box display="flex" justifyContent="space-between">


                        <Typography variant='h6' fontWeight="bold" sx={{ color: colors.grey[100] }}>
                           Variación: $ {variacionMx} MXN
                        </Typography>


                    </Box>
                    <Box display="flex" justifyContent="space-between">


                        <Typography variant='h6' fontWeight="bold" sx={{ color: colors.grey[100] }}>
                            Variación: $ {variacionUsd} USD
                        </Typography>


                    </Box>
                </>

            }

            <Box display="flex" justifyContent="space-between">


                <Typography variant='h5' sx={{ color: colors.greenAccent[500] }}>
                    {empresa}
                </Typography>

            </Box>

        </Box>

    )

}

export default StatBoxDashboard
