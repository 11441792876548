import React from 'react';
import { useField } from 'formik';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import 'dayjs/locale/es-mx';
import FormControl from '@mui/material/FormControl';

const MyDateInput = ({ label, name, format, inputFormat, Locale, ...restProps }) => {

  const [field, meta, helpers] = useField(name);

  const handleChange = (value) => {
    const formattedDate = value ? value.format(format) : '';
    helpers.setValue(formattedDate);
  };

  const handleBlur = () => {
    helpers.setTouched(true);
  };

  return (
    <FormControl fullWidth>
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={Locale}>
        <DatePicker
          {...field}
          {...restProps}
          sx={{ width: '100%' }}
          label={label}
          value={field.value ? dayjs(field.value, format) : null}
          onChange={handleChange}
          onBlur={handleBlur}
          error={meta.touched && Boolean(meta.error)}
          helperText={meta.touched && meta.error}
        />
      </LocalizationProvider>
      {
        meta.touched && meta.error ? (
            <div className="error" style={{color: 'red', fontSize: '0.6428571428571428rem'}}>{meta.error}</div>
        ) : null
      }
    </FormControl>
  );
};

export default MyDateInput;
