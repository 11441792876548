import React from 'react';
import { Box, Typography, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import Header from '../../components/Header';

const Operadores = () => {

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    return (

        <Box m="20px">

            <Header title="Operadores" subtitle="Empresas EBTSuite" />

        </Box>
    )

}

export default Operadores