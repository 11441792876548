import React , { useEffect, useState } from 'react'
import { DataGrid, GridToolbar} from "@mui/x-data-grid";
import { Box, useTheme,  Button} from "@mui/material";
import { tokens } from "../../theme";
import Header from '../../components/Header'
import api from '../../config/axios';
import * as Yup from 'yup';

// ICONOS
import AddCircleIcon from '@mui/icons-material/AddCircle';

import ModalCreateGM from '../../components/modals/ModalCreateGM'
import ModalEditGM from '../../components/modals/ModalEditGM'

const Bancos = () => {

    // ===============================================
    // COLORES PARA ESTILOS
    // ===============================================
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    // ===============================================
    // SEGMENTO PARA BUSCAR BANCOS
    // ===============================================

    const [bancos, setbancos] = useState([]);

    useEffect(() => {

        buscarBancos();

    },[])

    const buscarBancos = async () => {

        const response = await api.get('get/bancos');

        
        if(response.data.success === true && response.data.result !== "Sin resultados"){

            
            setbancos(response.data.result);

        }else{

            console.log("ERROR")
            setbancos([]);

        }

    }

    // ===============================================
    // COLUMNAS PARA LA TABLA
    // ===============================================

    const columns = [
        { field: "id", headerName: "ID", flex: 0.5 },
        {
          field: "nombre_banco",
          headerName: "Nombre del banco",
          flex: 2,
          cellClassName: "nombre_Banco",
        },
        {
            field: "clave_banco",
            headerName: "Clave del banco",
            flex: 2,
            cellClassName: "clave_Banco",
          },
    ];

    // ===============================================
    // VALORES PARA SUBMIT DEL FORMULARIO
    // ===============================================

    const [initialFormValues, setInicialFormValues] = useState({
        nombre_banco: '',
        clave_banco: ''
    })

     // ===============================================
    // FUNCION PARA ABRIR O CERRAR EL MODAL CREAR
    // ===============================================
    const [isOpen, setIsOpen] = useState(false);

    const handleOpenModal = () => {
        setIsOpen(true);
    };
    
    const handleCloseModal = () => {
        setIsOpen(false);
    };

    // ===============================================
    // FUNCION PARA ABRIR O CERRAR EL MODAL EDITAR
    // ===============================================

    const [isOpenEdit, setIsOpenEdit] = useState(false);

    const handleOpenModalEdit = () => {
        setIsOpenEdit(true);
    };
    
    const handleCloseModalEdit = () => {
        setIsOpenEdit(false);
    };


    // ===============================================
    // INPUTS PARA ARMAR EL FORMULARIO
    // ===============================================
    const inputForms = [
        {
            componente: "input",
            label: "Nombre del Banco",
            name: "nombre_banco",
            placeholder: "Ingrese Nombre del banco",
            type: "text",
            md: 12,
            xs: 12
        },
        {
            componente: "input",
            label: "clave del Banco",
            name: "clave_banco",
            placeholder: "Ingrese la clave del banco",
            type: "text",
            md: 12,
            xs: 12
        }
    ];

     // ===============================================
    // VALIDACIONES PARA EL FORMULARIO
    // ===============================================

    const yupValidation = Yup.object().shape({
        nombre_banco: Yup.string().required('Requerido'),
        clave_banco: Yup.string().required('Requerido')
    })

    // ===============================================
    // FUNCION PARA EDITAR REGISTRO
    // ===============================================

    const handleRowClick = (params) => {
        
        setInicialFormValues(params.row);
        handleOpenModalEdit();

    }
    return (

        <Box m="20px">

            <Header title="Bancos" subtitle="Catalogos de bancos" />

            <Button variant="contained" 
                sx={{
                        fontWeight:"bold",
                        backgroundColor:colors.blueAccent[700]
                }} 
                startIcon={<AddCircleIcon />}
                onClick={handleOpenModal}
            >
                Agregar Banco
            </Button>

            <Box m="40px 0 0 0"
                height="75vh"
                width="100%"
                sx={{
                "& .MuiDataGrid-root": {
                    border: "none",
                },
                "& .MuiDataGrid-cell": {
                    borderBottom: "none",
                },
                "& .name-column--cell": {
                    color: colors.greenAccent[300],
                },
                "& .MuiDataGrid-columnHeaders": {
                    backgroundColor: colors.blueAccent[700],
                    borderBottom: "none",
                },
                "& .MuiDataGrid-virtualScroller": {
                    backgroundColor: colors.primary[400],
                },
                "& .MuiDataGrid-footerContainer": {
                    borderTop: "none",
                    backgroundColor: colors.blueAccent[700],
                },
                "& .MuiCheckbox-root": {
                    color: `${colors.greenAccent[200]} !important`,
                },
                "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                    color: `${colors.grey[100]} !important`
                }
                }}>

                <DataGrid
                    rows={bancos}
                    columns={columns}
                    initialState={{
                        pagination: {
                        paginationModel: { page: 0, pageSize: 10 },
                        },
                    }}
                    components={{ Toolbar: GridToolbar}}
                    onRowClick={handleRowClick}
                    pageSizeOptions={[5, 10]}
                    checkboxSelection
                />
            </Box>
            
            <ModalCreateGM
                modalTitle={"Crear Banco"}
                swalTitle={"Se ha creado el banco"}
                url={"create/banco"}
                open={isOpen}
                onClose={handleCloseModal}
                size={'40'}
                initialValues={initialFormValues}
                inputForms={inputForms}
                yupValidation={yupValidation}
                refresh={buscarBancos}
            />

            <ModalEditGM
                modalTitle="Editar Banco"
                swalTitle="Se ha editado el Banco!"
                url="update/banco/"
                urlDelete="delete/banco/"
                open={isOpenEdit} 
                onClose={handleCloseModalEdit} 
                openModal={handleOpenModalEdit}
                size='40'
                initialValues={initialFormValues}
                inputForms={inputForms}
                yupValidation={yupValidation}
                refresh={buscarBancos}
            />

        </Box>

    )

}

export default Bancos