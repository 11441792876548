import { useTheme } from "@emotion/react";
import { Box, Button, FormControl, Grid, InputLabel, Menu, MenuItem, Modal, Paper, Select, TextField, Typography } from "@mui/material";
import { useState } from "react";
import { tokens } from "../../../theme";
import { useEffect } from "react";
// import MySelect from "../../../../components/inputs/MySelect";
import { DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// ICONOS
import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';
import PublishIcon from '@mui/icons-material/Publish';

import dayjs from 'dayjs';
import api from "../../../config/axios";
import Swal from "sweetalert2";
import { Done } from "@mui/icons-material";
import { endOfDay } from "date-fns";


const ModalFiltrosAvanzados = (props) => {
	const {open, onClose, onOpen, setData, setShowButton, setLoading } = props;

	const theme  = useTheme();
    const colors = tokens(theme.palette.mode);
    
    const [display, setDisplay] = useState('flex');
    // const [periodos, setPeriodos] = useState([
	// 	{id: 1, descripcion: 'Diario'},
	// 	{id: 2, descripcion: 'Personalizado'},
	// ]);
	const [inicioMes, setInicioMes] = useState(dayjs().startOf('month'));
	const [finMes, setFinMes] = useState(dayjs().endOf('month'));

	// const [fechaInicio, setFechaInicio] = useState(dayjs().subtract(1, 'day').format('YYYY/MM/DD'));
	// const [fechaFin, setFechaFin] = useState(dayjs().format('YYYY/MM/DD'));
	const [fechaInicio, setFechaInicio] = useState(dayjs().startOf('month').format('YYYY/MM/DD'));
	const [fechaFin, setFechaFin] =useState(dayjs().endOf('month').format('YYYY/MM/DD'));


	const [showDatePicker, setShowDatePicker] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0);
		// 
		console.log(inicioMes);
		console.log(finMes);
    },[]);
   
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        height: 'auto',
        width: '900',
        bgcolor: colors.blueAccent[900],
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    }; 
	const [estatus, setEstatus] = useState('D');
	const handleChangeEstatus = (event) => {
		setEstatus(event.target.value);
	};
	const [periodo, setPeriodo] = useState(1);
	const handleChangePeriodo = (event) => {
		setPeriodo(event.target.value);
		if(event.target.value ===  4){ //personalizado
			setShowDatePicker(true);
		}else{// personalizado
			setShowDatePicker(false);
		}
	};
	const [filterBy, setFilterBy] = useState(1);
	const handleChangeFilterBy = (event) => {
		setFilterBy(event.target.value);
	};
	const [orderBy, setOrderBy] = useState(1);
	const handleChangeOrderBy = (event) => {
		setOrderBy(event.target.value);
	};
	const aplicarFiltros = async () => {
		let params = {
			estatus: estatus, // V void, P progress, D delivery, A available
			periodo: periodo, // 1 inicio de mes, 2 fin de mes, 3 personalizado
			filterBy: filterBy, // 1 todos, 2 match perfecto, 3 match semi perfecto, 4 sin match
 			orderBy: orderBy, // 1 numero orden, 2 fecha, 3 match ascendente, 4 match descendente
			fechaInicio: periodo === 4 ? fechaInicio : null, 
			fechaFin: periodo === 4 ? fechaFin : null,
		}
		localStorage.setItem('filtros', JSON.stringify(params));

		const archivo = localStorage.getItem('archivo');
		if(archivo && archivo !== null){
			params = {...params, archivo: archivo};
		}
		setLoading(true);
		onClose();
		const response = await api.post("/get/data/comparativa", { ...params });
        if(response.data.success === true && response.data.result?.error){
			setData([]);
			Swal.fire({
				title: 'Error!',
				text: response.data.result.message,
				icon: 'error',
				confirmButtonText: 'Aceptar'
			});
		}else if (response.data.success === true && response.data.result !== "Sin resultados") {
			setData(response.data.result);
            setShowButton(response.data.result.needsAuth)
		}else{
			setData([]);
            setShowButton(response.data.result.needsAuth)
		}
		setLoading(false);
	};
	return(
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
			style={{
				display: display,
				alignItems: "center",
				justifyContent: "center",
			}}
        >
            <Paper sx={{
				width: "40%",
				p: 3,
				bgcolor: colors.blueAccent[900],
				borderRadius: "10px",
			}}
			>
				<Typography id="modal-modal-title" variant="h3" align="center">Filtros avanzados</Typography>				
				<Grid container spacing={2} paddingBottom={"0.1rem"} sx={{mt:'10px'}}>
					{/* TIPO */}
					<Grid item md={12} xs={12} xl={12}>
						<FormControl sx={{ width: '100%' }}>                
							<InputLabel id="select-label">Estatus</InputLabel>
							<Select
								value={estatus} // Asegúrate de tener un estado para manejar el valor seleccionado
								label="Estatus"
								onChange={handleChangeEstatus} // Asegúrate de tener una función para manejar el cambio de valor
							>
								<MenuItem value={'D'}>DELIVERY</MenuItem>
								<MenuItem value={'P'}>PROGRESS</MenuItem>
								<MenuItem value={'A'}>AVAILABLE</MenuItem>
								<MenuItem value={'V'}>VOID</MenuItem>
							</Select>
						</FormControl>
					</Grid>
					{/* TIPO */}
					<Grid item md={12} xs={12} xl={12}>
						<FormControl sx={{ width: '100%' }}>                
							<InputLabel id="select-label">Periodo</InputLabel>
							<Select
								value={periodo} // Asegúrate de tener un estado para manejar el valor seleccionado
								label="Periodo"
								onChange={handleChangePeriodo} // Asegúrate de tener una función para manejar el cambio de valor
							>
								<MenuItem value={1}>INICIOS DE MES</MenuItem>
								<MenuItem value={2}>FINES DE MES</MenuItem>
								{/* <MenuItem value={3}>MES COMPLETO</MenuItem> */}
								<MenuItem value={4}>PERSONALIZADO</MenuItem>
							</Select>
						</FormControl>
					</Grid>
					<Grid item md={12} xs={12} xl={12}>
						<FormControl sx={{ width: '100%' }}>                
							<InputLabel id="select-label">Filtrar por</InputLabel>
							<Select
								value={filterBy} // Asegúrate de tener un estado para manejar el valor seleccionado
								label="Filtrar por"
								onChange={handleChangeFilterBy} // Asegúrate de tener una función para manejar el cambio de valor
							>
								<MenuItem value={1}>TODOS</MenuItem>
								<MenuItem value={2}>MATCH PERFECTO</MenuItem>
								<MenuItem value={3}>MATCH SEMI PERFECTO</MenuItem>
								<MenuItem value={4}>SIN MATCH</MenuItem>
							</Select>
						</FormControl>
					</Grid>
					<Grid item md={12} xs={12} xl={12}>
						<FormControl sx={{ width: '100%' }}>                
							<InputLabel id="select-label">Ordenar por</InputLabel>
							<Select
								value={orderBy} // Asegúrate de tener un estado para manejar el valor seleccionado
								label="Ordenar por"
								onChange={handleChangeOrderBy} // Asegúrate de tener una función para manejar el cambio de valor
							>
								<MenuItem value={1}>NUMERO ORDEN</MenuItem>
								<MenuItem value={2}>FECHA</MenuItem>
								<MenuItem value={3}>MATCH ASCENDENTE</MenuItem>
								<MenuItem value={4}>MATCH DESCENDENTE</MenuItem>
							</Select>
						</FormControl>
					</Grid>
					{/* {showDatePicker && (
						<Grid item md={12} xs={12} xl={12}>
							<FormControl sx={{ width: '100%' }}>                
								<InputLabel id="select-label">Fecha</InputLabel>
								<Select
									value={fecha} // Asegúrate de tener un estado para manejar el valor seleccionado
									label="Fecha"
									onChange={handleChangeFecha} // Asegúrate de tener una función para manejar el cambio de valor
								>
									<MenuItem value={0}>CARGA</MenuItem>
									<MenuItem value={1}>NOMINA</MenuItem>
									<MenuItem value={2}>FACTURA</MenuItem>
								</Select>
							</FormControl>
						</Grid>
					)} */}
					{showDatePicker && (
						<Grid item md={6} xs={6} xl={6}>
							<LocalizationProvider dateAdapter={AdapterDayjs}>
								<DatePicker
									defaultValue={inicioMes}
									sx={{width: '100%', backgroundColor: colors.greenAccent[700]}}
									views={['year', 'month', 'day']}
									renderInput={(props) => <TextField {...props} fullWidth label="Fecha inicial" />}
									onChange={(value) => setFechaInicio(dayjs(value).format('YYYY/MM/DD'))}
								/>
							</LocalizationProvider>
						</Grid>
					)}
					{showDatePicker && (
						<Grid item md={6} xs={6} xl={6}>
							<LocalizationProvider dateAdapter={AdapterDayjs}>
								<DatePicker
									sx={{width: '100%', backgroundColor: colors.redAccent[700]}}
									defaultValue={finMes}
									views={['year', 'month', 'day']}
									renderInput={(props) => <TextField {...props} fullWidth label="Fecha final" />}
									onChange={(value) => setFechaFin(dayjs(value).format('YYYY/MM/DD'))}
								/>
							</LocalizationProvider>
						</Grid>
					)}	
					{/* SUBMIT */}
					<Grid item md={6} xs={6}>
						<Button variant='contained' sx={{color: "white",width: "100%",borderRadius: "8px" ,marginRight: '10px'}} onClick={onClose} startIcon={<CloseIcon />}>
							Cancelar
						</Button>
					</Grid>
					<Grid item md={6} xs={6}>
						<Button variant='contained' color='success' sx={{color: "white",width: "100%",borderRadius: "8px" ,marginRight: '10px'}} onClick={aplicarFiltros} startIcon={<Done />}>
							Aplicar
						</Button>
					</Grid>
                </Grid>
            </Paper>
        </Modal>
    )
};
export default ModalFiltrosAvanzados;