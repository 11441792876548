import React, { useState, useMemo, useEffect } from "react";
import { Box, Button, Chip, Grid, IconButton, Popover, Typography } from "@mui/material";
import { useDropzone } from "react-dropzone";
import * as XLSX from "xlsx";

// ICONOS
import AddIcon from "@mui/icons-material/Add";
import SettingsEthernetIcon from '@mui/icons-material/SettingsEthernet';

import api from "../../config/axios";
import Header from "../../components/Header";
import { useTheme } from "@emotion/react";
import { tokens } from "../../theme";
// import FileSaver from 'file-saver';
import MySnackBar from '../../components/feedback/MySnackBar';
import img from "../../assets/img/spinner.svg"
import ModalArchivo from "./modals/ModalArchivo";
import ModalFiltrosAvanzados from "./modals/ModalFiltrosAvanzados";
import {
	GridToolbarColumnsButton,
	GridToolbarContainer,
	GridToolbarFilterButton,
	GridToolbarQuickFilter,
} from "@mui/x-data-grid";
import { Done, DoneAll, Error, FilterAlt, Help, Visibility } from "@mui/icons-material";
import CollapsibleTable from "../../components/CollapsibleTable";
import Swal from "sweetalert2";
import { baseURL } from "../../config/url";
import ModalConciliacion from "./modals/ModalConciliacion";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";

// const ExcelJS = require('exceljs');

const Concentrado = () => {
	const [anchorEl, setAnchorEl] = useState(null);
	const [anchorPosition, setAnchorPosition] = useState({ top: 0, left: 0 });
	const [text, setText] = useState("");
	const handleClick = (event, text) => {
		setAnchorEl(event.currentTarget);
		setText(text);
		setAnchorPosition({ top: event.clientY, left: event.clientX });
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const open = Boolean(anchorEl);
	const id = open ? "simple-popover" : undefined;

	const navigate = useNavigate();
	const theme = useTheme();
	const colors = tokens(theme.palette.mode);
	const [showButtonQB, setShowButtonQB] = useState(false);
	const [loading, setLoading] = useState(false);

	const [archivo, setArchivo] = useState(null);
	const [filtros, setFiltros] = useState({
		estatus: 'D',
		periodo: 3, // 1 inicio de mes, 2 fin de mes, 3 personalizado
		filterBy: 1, // 1 todos, 2 match perfecto, 3 match semi perfecto, 4 sin match
		orderBy: 1, // 1 numero orden, 2 fecha, 3 match ascendente, 4 match descendente
		fechaInicio: null, 
		fechaFin: null,
		archivo : archivo,
	});
	const [openSnackbar, setOpenSnackbar] = useState(false);
	const [message, setMessage] = useState("");
	const [action, setAction] = useState("");
	const handleCloseSnackbar = () => {
		setOpenSnackbar(false);
	};
	useEffect(() => {
		const url = window.location.href;
		if (url.includes("code")) {
			// Espera a que la URL tenga algo antes de llamar a loadData
			const code = url.split("code=")[1];
			const realmId = url.split("realmId=")[1];
			if (code && realmId) {
				loadData(url, realmId);
			}
		} else {
			// Llama a loadData inmediatamente
			loadData();
		}
	}, [window.location.href]);
	useEffect(() => {
		if(showButtonQB){
			setMessage("Conecta tu cuenta de QuickBooks para consultar la información");
			setAction("error");
			setOpenSnackbar(true);
		}
		
	}, [showButtonQB]);
	useEffect(() => {
		const localFiltros = localStorage.getItem('filtros');
		const localArchivo = localStorage.getItem('archivo');

		if (localFiltros) {
			setFiltros(JSON.parse(localFiltros));
		}

		if (localArchivo) {
			setArchivo(JSON.parse(localArchivo));
		}
		 // Función de limpieza
		 return () => {
			localStorage.removeItem('filtros');
			localStorage.removeItem('archivo');
		};
	}, []);
	const loadData = async (url = false, realmId = false) => {
		setLoading(true);
		// const archivo = localStorage.getItem('archivo');
		// const filtros = localStorage.getItem('filtros');
		const params = url ? {...filtros, url: url, realmId } : { ...filtros };
		const response = await api.post("/get/data/comparativa", { ...params });
		if(response.data.success === true && response.data.result?.error){
			setData([]);
			Swal.fire({
				title: 'Error!',
				text: response.data.result.message,
				icon: 'error',
				confirmButtonText: 'Aceptar'
			});
		}else if (response.data.success === true && response.data.result !== "Sin resultados"){
			setData(response.data.result);
			setShowButtonQB(response.data.result.needsAuth);
		}else{
			setData([]);
			setShowButtonQB(response.data.result.needsAuth);
		}
		setLoading(false);
	};
	
	// ===============================================
	// COMPONENTE PARA CARGAR ARCHIVO EXCEL
	// ===============================================
	const [data, setData] = useState([]);
	
	const actions = () => {
		return (
			<>	
				<VerConciliadosButton />
				<ConciliacionButton />
				<FiltrosAvanzadosButton />
			</>
		)
	}
	const [openModalArchivo, setOpenModalArchivo] = useState(false);
	const handleOpenModalArchivo = () => {
		setOpenModalArchivo(true);
	};
	const [openFiltrosAvanzados, setOpenFiltrosAvanzados] = useState(false);
	const FiltrosAvanzadosButton = () => {
		// const { classes } = useGridSlotComponentProps();
		const theme = useTheme();

		return (
			<Button
				variant="text"
				color="inherit"
				size="small"
				startIcon={<FilterAlt />}
				style={{ color: theme.palette.text.primary }}
				onClick={() => setOpenFiltrosAvanzados(true)}
			>
				Filtros Avanzados
			</Button>
		);
	};
	const [openConciliacion, setOpenConciliacion] = useState(false);
	const ConciliacionButton = () => {
		const theme = useTheme();
	
		if (data?.count_ready_to_conciliar === 0) {
			return null;
		}
	
		return (
			<Button
				variant="text"
				color="inherit"
				size="small"
				startIcon={<DoneAll />}
				style={{ color: theme.palette.text.primary }}
				onClick={() => setOpenConciliacion(true)}
			>
				Conciliar ordenes
			</Button>
		);
	};
	const VerConciliadosButton = () => {
		const theme = useTheme();

		return (
			<Button
				variant="text"
				color="inherit"
				size="small"
				startIcon={<Visibility />}
				style={{ color: theme.palette.text.primary }}
				onClick={() => navigate("/ordenesconciliadas")}
			>
				Ver Conciliados
			</Button>
		);
	};
	const [vistaActual, setVistaActual] = useState("mcleod");
	
	const [columns, setColumns] = useState([
		{
			field: "ORDER_ID",
			flex: 2,
			headerAlign: "center",
			align: "center",
		},
	]);
	const renderCell = (row) => {
		let color;
		if (row.match.includes('perfecto')) {
			color = 'green';
		} else if (row.match.includes('semi')) {
			color = 'yellow';
		} else {
			color = 'red';
		}
	
		return (
			<div style={{
				height: '10px',
				width: '10px',
				backgroundColor: color,
				borderRadius: '50%',
				display: 'inline-block'
			}}/>
		);
	}
	const renderAction = (row) => {
		return row.ready_to_conciliar ? (
			<IconButton onClick={() => handleConciliar(row)}>
				<DoneAll color="secondary"/>
			</IconButton>
		) :  parseFloat(row.diferencia) !== 0 ? (
			<Chip label={formatCurrency(row.diferencia)} color="error"/>
		) : row.match === "perfecto" ? (
			<IconButton onClick={(event) => handleClick(event, "Subir archivo RTS para conciliar")}>				
				<Help color="info"/>
			</IconButton>
		) : (
			<IconButton onClick={(event) => handleClick(event, "No se puede conciliar si no hay match entre los 3 archivos")}>
				<Help color="error"/>
			</IconButton>
		);
	};
	const formatCurrency = (value) => {
		const formatter = new Intl.NumberFormat("en-US", {
			style: "currency",
			currency: "USD",
			minimumFractionDigits: 2,
			maximumFractionDigits: 2,
		});
		return formatter.format(value);
	};
	const handleConciliar = async (row) => {
		const startDate = new Date(row.order_date);
        const endDate = new Date(row.order_date);
        const params = {
            fechaInicio: startDate,
            fechaFin: endDate,
            user: Cookies.get('user_id'),
            data: [row]
        }
		const response = await api.post("/comparativa/conciliar", {...params});
        if (response.data.success === true) {
            // onClose();
            Swal.fire({
                icon: response.data.icon,
                title: response.data.text
            });
			loadData();
			// setData(response.data.result.data);
            // setShowButton(response.data.result.needsAuth)
		}
		// setLoading(false);
	};
	useEffect(() => {
		if (data === null || data === undefined || data.length === 0) {
			return;
		}
		const newColumns = [
			{ id: "order_num", 				label: "Orden", minWidth: 100},
			{ id: "customer", 				label: "Cliente", minWidth: 100, alignColumn: "left"},
			{ id: "order_type" , 			label: "Tipo", minWidth: 100},
			// { id: "status", 				label: "Estatus", minWidth: 100},
			{ id: "order_date", 			label: "Fecha de orden", minWidth: 100},
			{ id: "total_charge", 			label: "Carga total", minWidth:100 ,currency: true },
			// { id: "total_charge_c", 		label: "Moneda", minWidth: 100},
			{ id: "shipper_arrive_early", 	label: "Llegada temprano", minWidth: 100},
			{ id: "shipper_arrive_late", 	label: "Llegada tarde", minWidth: 100},
			{ id: "hold", 					label: "En espera", minWidth: 100},
			{ id: "ready_to_bill", 			label: "Listo para facturar", minWidth: 100},
			{ id: "match", 					label: "Match", hasRenderCell: true, renderCell: renderCell, minWidth: 100},
			{ id: "action", 				label: "Acción", hasRenderCell: true, renderCell: renderAction, minWidth: 100},
		]
		const newColumnsDetail = [
			{ id: "type", 					label: "Tipo" },
			// { id: "order_num", 				label: "Orden" },
			{ id: "date", 					label: "Fecha de orden" },
			{ id: "days_due", 				label: "Dias" },
			{ id: "amount", 				label: "Cantidad", currency: true },
			{ id: "customer", 				label: "Cliente" },
	];
		setDetailColumns(newColumnsDetail);
		setColumns(newColumns);
	}, [data, vistaActual]);
	
    const [detailColumns, setDetailColumns] = useState([]);
	const ConnectQuickBooksButton = () => {
			const handleClick = () => {
				const clientId = 'ABZE10xYqBh6WDyQwlVR9MHrVSivOulqRGoerlmzoqzZJB9lxD'; // Reemplaza con tu ID de cliente
				const redirectUri = encodeURIComponent(`https://qhbvkq4g-3000.usw3.devtunnels.ms/ordencomparativa`); // Reemplaza con tu URI de redirección
				// const redirectUri = encodeURIComponent(`https://siif.elitebordergroup.com/ordencomparativa`); // Reemplaza con tu URI de redirección
				const scope = encodeURIComponent('com.intuit.quickbooks.accounting'); // Ajusta el alcance según tus necesidades
				const authUri = `https://appcenter.intuit.com/connect/oauth2?client_id=${clientId}&redirect_uri=${redirectUri}&scope=${scope}&response_type=code&state=200`; 
			
				// Redirige al usuario a la URL de autorización de Intuit
				window.location.href = authUri;
			};
		
			return (
				<Button 
					variant="contained"
					sx={{
						ml: 1,
						fontWeight: "bold",
						backgroundColor: colors.greenAccent[700],
					}}
					startIcon={<SettingsEthernetIcon />}
					onClick={handleClick}>
					Conectar a QuickBooks
				</Button>
			);
		};
	return (
		<Box m="20px">
			<Header title="Comparativa" subtitle="Comparativa de archivos" />
			 
			 {
				loading ?
				(<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh', marginBottom: '100px' }}>
					<img
						alt="Cargando..."
						width="200px"
						height="200px"
						src={img}
						style={{ cursor: "pointer", borderRadius: "50%" }}
					/>
				</div>) : 			 
				<Grid container>
					<Grid container spacing={2}>
						<Button
							variant="contained"
							sx={{
								ml: 2,
								fontWeight: "bold",
								backgroundColor: colors.blueAccent[700],
							}}
							startIcon={<AddIcon />}
							onClick={handleOpenModalArchivo}
						>
						Agregar archivo
						</Button>
						{showButtonQB ? <ConnectQuickBooksButton /> : null}
					</Grid>
					<Grid item xs={12} md={12} lg={12}>
						{/* <GridView
							columns={columns}
							data={
								// Primero, verifica si 'data' existe
								(data &&
									// Si 'data' existe, verifica el valor de 'vistaActual'
									(vistaActual === "mcleod"
										? data.mcleod?.results // Si 'vistaActual' es 'mcleod', verifica que 'data.mcleod' existe antes de acceder a 'results'
									: vistaActual === "rts"
										? data.rts?.results // Si 'vistaActual' es 'rts', verifica que 'data.rts' existe antes de acceder a 'results'
									: vistaActual === "quickbooks"
										? data.quickbooks?.results // Si 'vistaActual' es 'quickbooks', verifica que 'data.quickbooks' existe antes de acceder a 'results'
									: [])
								) || []// Si 'data' no existe, usa un array vacío
							}
							handleRowClick={handleRowClick}
							selectedRowId={selectedRowId}
							handleRowSelection={handleRowSelection}
							CustomToolbar={GridToolBar}
							hidden={true}
							getRowId={(row) => row.order_num}
						/> */}
						<CollapsibleTable
							data={data?.data}
							columns={columns}
							detailColumns={detailColumns}
							handleChecks={()=>{}}
							showCheck={false}
							consecutivo={"order_num"}
							actions={actions()}
							header="Lista de ordenes con match"
							refresh={loadData}
						/>
					</Grid>
				</Grid>
			}
			<MySnackBar open={openSnackbar} action={action} message={message} onClose={handleCloseSnackbar}/>
			{/* <ModalConcentrado
				open={openModal}
				onOpen={() => setOpenModal(true)}
				onClose={() => setOpenModal(false)}
				setData={setData}
			/> */}
			<ModalConciliacion 
				open={openConciliacion}
				onOpen={() => setOpenConciliacion(true)}
				onClose={() => setOpenConciliacion(false)}
				data={data}
				refresh={loadData}
			/>
			<ModalArchivo
				open={openModalArchivo}
				onOpen={() => setOpenModalArchivo(true)}
				onClose={() => setOpenModalArchivo(false)}
				setData={setData}
				setShowButton={setShowButtonQB}
				setArchivo={setArchivo} 
				setLoading={setLoading}
				filtros={filtros}
			/>
			<ModalFiltrosAvanzados
				open={openFiltrosAvanzados}
				onOpen={() => setOpenFiltrosAvanzados(true)}
				onClose={() => setOpenFiltrosAvanzados(false)}
				setData={setData}
				setShowButton={setShowButtonQB}
				setFiltros={setFiltros}
				setLoading={setLoading}
				archivo={archivo}
			/>
			<Popover
				id={id}
				open={open}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorReference="anchorPosition"
				anchorPosition={anchorPosition}
				transformOrigin={{
					vertical: "top",
					horizontal: "center",
				}}
				PaperProps={{
					style: {
						backgroundColor: "#141B2D",
						padding: "10px",
						borderRadius: "10px",
					},
				}}
			>
				<Typography variant="h5" align="center">
					{text}
				</Typography>
			</Popover>
		</Box>
	);
};

export default Concentrado;
