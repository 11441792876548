import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material";
import { tokens } from "../theme";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import GraficaPie from "./charts/GraficaPie";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import api from "../config/axios";
import Chip from '@mui/material/Chip';
import GppMaybeIcon from '@mui/icons-material/GppMaybe';

const TarjetaGraficas = ({
  cardTitle, //TITULO DE LA TARJETA
  bancoTitle, //TITULO AL DEL BANCO
  datos, //DATA PARA LLENAR LA GRAFICA DE APERTURA
  datosCierre, //DATA PARA LLENAR LA GRAFICA DE CIERRE
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [moneda, setMoneda] = useState('MXN')
  const [datosAp, setdatosAp] = useState([])
  const [datosClo, setdatosClo] = useState([])
  const [bancos, setBancos] = useState([]);

  const handleChangeSelectMoneda = (event) => {
    setMoneda(event.target.value);
  };

  useEffect(() => {
    if (moneda === 'MXN') {
      let respuesta1 = transformarDatos(datos, moneda)
      setdatosAp(respuesta1)
      
      let respuesta2 = transformarDatosCierre(datosCierre, moneda)
      setdatosClo(respuesta2)
    } else {
      let respuesta1 = transformarDatos(datos, moneda)
      setdatosAp(respuesta1)
      let respuesta2 = transformarDatosCierre(datosCierre, moneda)
      setdatosClo(respuesta2)
    }
  }, [moneda]);

  // ===============================================
  // FUNCION QUE OBTIENE LOS BANCOS
  // ===============================================

  const obtenerBancos = async () => {
    const response = await api.get("/get/bancos");

    if (
      response.data.success === true &&
      response.data.result !== "Sin resultados"
    ) {
      let respuesta = response.data.result
      let arrayBancos = []

      for (let i = 0; i < respuesta.length; i++) {
        const element = respuesta[i].clave_banco;
        arrayBancos.push(element)
      }
      
      setBancos(arrayBancos);
    } else {
      console.log("ERROR");

      setBancos([]);
    }
  };

  function transformarDatos(datos, moneda) {
    obtenerBancos()
    const nuevoObjeto = {};

    for (const clave_banco in datos) {
      const bancos = datos[clave_banco];
      for (const dato of bancos) {
        const id = dato.idSaldoBancos;
        const label = dato.razon_social;
        const value = moneda === "MXN" ? dato.suma_inicio_pesos : dato.suma_inicio_dolares;
        const banco = dato.clave_banco;
        const variacion = moneda === "MXN" ? dato.variacion_mx : dato.variacion_dlls

        nuevoObjeto[id] = {
          label,
          value,
          banco,
          variacion
        };
      }
    }

    const gruposPorLabel = {};

    for (const id in nuevoObjeto) {
      const { label, value, banco, variacion } = nuevoObjeto[id];

      if (!gruposPorLabel[label]) {
        gruposPorLabel[label] = [];
      }

      gruposPorLabel[label].push({ id, value, banco, variacion });
    }

    const arregloTransformado = [];
    for (const label in gruposPorLabel) {
      const elementos = gruposPorLabel[label];

      for (const elemento of elementos) {
        arregloTransformado.push({
          label: label,
          id: label,
          value: elemento.value,
          banco: elemento.banco,
          variacion: elemento.variacion
        });
      }
    }
    return arregloTransformado;
  }

  function transformarDatosCierre(datos, moneda) {
    const nuevoObjeto = {};


    for (const clave_banco in datos) {
      const datosEmpresa = datos[clave_banco];

      for (const dato of datosEmpresa) {

        const id = dato.idSaldoBancos;
        const label = dato.clave_banco;
        const value = moneda === "MXN" ? dato.suma_fin_pesos : dato.suma_fin_dolares;
        const empresa = dato.razon_social;
        const variacion = moneda === "MXN" ? dato.variacion_mx : dato.variacion_dlls

        nuevoObjeto[id] = {
          label,
          value,
          empresa,
          variacion
        };
      }
    }

    const gruposPorLabel = {};

    for (const id in nuevoObjeto) {
      const { label, value, empresa, variacion } = nuevoObjeto[id];

      if (!gruposPorLabel[label]) {
        gruposPorLabel[label] = [];
      }

      gruposPorLabel[label].push({ id, value, empresa, variacion });
    }

    const arregloTransformado = [];

    for (const label in gruposPorLabel) {
      const elementos = gruposPorLabel[label];

      for (const elemento of elementos) {
        arregloTransformado.push({
          id: elemento.empresa,
          label: elemento.empresa,
          value: elemento.value,
          banco: label,
          variacion: elemento.variacion
        });
      }
    }
    
    return arregloTransformado;
  }
  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: colors.primary[400],
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  }));

  function Tarjetas() {
    if (datosAp.length > 0 && datosClo.length > 0) {
      return (
        <Grid container spacing={2}>
          {bancos.map((val, index) => {

            if (datosAp != undefined && datosClo != undefined) {
              const r = datosAp.filter(el => el.banco == val)
              const r2 = datosClo.filter(el => el.banco == val)
              let suma = 0
              r2.map((val, i) => {
                suma = suma + parseFloat(val.variacion.toFixed(2))
              })
              return (
                <>

                  <Grid item xs={6}>
                    <Item>
                      <Box
                        display="flex"
                        justifyContent="space-around"
                        alignItems="center"
                        flexDirection="column"
                      >
                        <Typography variant="h3" fontWeight="600">
                          APERTURA - {val}
                        </Typography>
                      </Box>
                      <Box height="500px" width={"500px"}>
                        <GraficaPie data={r} moneda={moneda} />
                      </Box>
                    </Item>
                  </Grid>
                  <Grid item xs={6}>
                    <Item>
                      <Box
                        display="flex"
                        justifyContent="space-around"
                        alignItems="center"
                        flexDirection="column"
                      >
                        <Typography variant="h3" fontWeight="600">
                          CIERRE - {val}
                        </Typography>
                      </Box>
                      <Box height="500px" width={"500px"}>
                        <GraficaPie data={r2} moneda={moneda} />
                      </Box>
                      <Box>
                        <Typography variant="h4" fontWeight="600">


                          Variación total de la empresa: ${suma.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')} {moneda}
                        </Typography>
                      </Box>
                    </Item>
                  </Grid>
                </>


              )
            }
          })}
        </Grid>
      )
    } else {
      return (
        <Box
          height="100px"
          m="-20px 0 0 0"
          backgroundColor={colors.primary[400]}
          sx={{ marginTop: "20px", paddingTop: "20px" }}
        >
          <center>
            <Chip
              sx={{ width: '300px', fontSize: '20px' }}
              icon={<GppMaybeIcon sx={{ fontSize: '3s0px' }}></GppMaybeIcon>}
              label={"Sin información"}
            />
          </center>
        </Box>
      )
    }

  }
  return (
    <Box sx={{ flexGrow: 1 }}>
      <InputLabel id="demo-simple-select-label">Moneda</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={moneda}
          label="moneda"
          onChange={handleChangeSelectMoneda}
        >
          <MenuItem value={'MXN'}>Pesos</MenuItem>
          <MenuItem value={'USD'}>Dólares</MenuItem>
        </Select>

        <Tarjetas/>
      
    </Box>
  );
};

export default TarjetaGraficas;
