import React from 'react'
import { NavLink } from "react-router-dom";
import { Grid, Box, Typography, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import Header from '../../components/Header'

// ICONOS

import ApartmentIcon from '@mui/icons-material/Apartment';
import GroupIcon from '@mui/icons-material/Group';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import PublicIcon from '@mui/icons-material/Public';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import UploadIcon from '@mui/icons-material/Upload';
import BadgeIcon from '@mui/icons-material/Badge';
import ConnectWithoutContactIcon from '@mui/icons-material/ConnectWithoutContact';

const Catalogos = () => {

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    // Objeto que mapea los nombres de los iconos a los componentes de iconos correspondientes
    
    const iconos = {
        ApartmentIcon,
        GroupIcon,
        LocalShippingIcon,
        PublicIcon,
        AccountBalanceIcon,
        UploadIcon,
        BadgeIcon,
        ConnectWithoutContactIcon
    };

    const datos = {
        catalogo: [
            ["Empresas", "ApartmentIcon", "empresas"],
            ["Bancos", "AccountBalanceIcon", "bancos"],
            ["Clientes", "GroupIcon", "clientes"],
            ["Ejecutivos", "BadgeIcon", "ejecutivos"],
            ["Relación Cliente - Ejecutivo", "ConnectWithoutContactIcon", "relaciones"]
        ]
    }

    const columns = [
        { field: "id", headerName: "ID", flex: 0.5 },
        {
          field: "nombre_comercial",
          headerName: "Nombre Comercial",
          flex: 2,
          cellClassName: "name-column--cell",
        },
        {
          field: "razon_social",
          headerName: "Razon Social",
          flex: 2,
          headerAlign: "left",
          align: "left",
        },
    ];

    return (

        <Box m="20px">

            <Header title="Catalogos" subtitle="Catalogos Disponibles EBTSuite" />

            <Grid container spacing={2}>

            {
            
                datos.catalogo.map((op, index) => {

                const IconoDinamico = iconos[op[1]]; // Obtiene el componente de icono dinámicamente

                return (
                    <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                        <NavLink
                        to={`/${op[2]}`}
                        style={{ textDecoration: "none", color: "inherit" }}
                        activeStyle={{ fontWeight: "bold" }}
                        >
                        <Box
                            sx={{
                            border: 1,
                            p: 2,
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                            textAlign: "center"
                            }}
                        >
                            {IconoDinamico && <IconoDinamico sx={{ fontSize: 48 }} />}
                            <Typography variant="body1" sx={{ mt: 1 }}>
                            {op[0]}
                            </Typography>
                        </Box>
                        </NavLink>
                    </Grid>
                );
            })}


                {/* <Grid item xs={3}>
                    <Link to="/tarjeta2">
                    <Box sx={{ border: 1, p: 2 }}>Tarjeta 2</Box>
                    </Link>
                </Grid> */}
            
            </Grid>

        </Box>

    )

}

export default Catalogos