import { Box, Button } from "@mui/material";
import Header from "../../../components/Header";
import { useEffect, useState } from "react";
import { Select, MenuItem, FormControl, InputLabel } from '@mui/material';

import api from "../../../config/axios";
import {
	GridToolbarColumnsButton,
	GridToolbarContainer,
	GridToolbarFilterButton,
	GridToolbarQuickFilter,
} from "@mui/x-data-grid";
import GridView from "../../../components/Gridview";
import { format, set } from "date-fns";
import CollapsibleTable from "../../../components/CollapsibleTable";
const OrdenesConciliadas = () => {
   
    const [conciliaciones, setConciliaciones] = useState([]);
    const [data, setData] = useState([]);
    const [rows, setRows] = useState([]);
    const [vistaActual, setVistaActual] = useState("mcleod");

    useEffect(() => {
        window.scrollTo(0, 0);
    },[]);
    useEffect(() => {
		if (data === null || data === undefined || data.length === 0) {
			return;
		}
		const newColumns = [
			{ id: "order_num", 				label: "Orden", minWidth: 100},
			{ id: "customer", 				label: "Cliente", minWidth: 100, alignColumn: "left"},
			{ id: "order_type" , 			label: "Tipo", minWidth: 100},
			// { id: "status", 				label: "Estatus", minWidth: 100},
			{ id: "order_date", 			label: "Fecha de orden", minWidth: 100},
			{ id: "total_charge", 			label: "Carga total", minWidth:100 ,currency: true },
			// { id: "total_charge_c", 		label: "Moneda", minWidth: 100},
			{ id: "shipper_arrive_early", 	label: "Llegada temprano", minWidth: 100},
			{ id: "shipper_arrive_late", 	label: "Llegada tarde", minWidth: 100},
		]
		const newColumnsDetail = [
			{ id: "type", 					label: "Tipo" },
			// { id: "order_num", 				label: "Orden" },
			{ id: "date", 					label: "Fecha de orden" },
			{ id: "days_due", 				label: "Dias" },
			{ id: "amount", 				label: "Cantidad", currency: true },
			{ id: "customer", 				label: "Cliente" },
	];
		setDetailColumns(newColumnsDetail);
		setColumns(newColumns);
	}, [data, vistaActual]);
    const loadData = async (a = anio,m = mes) => {
        const response = await api.get('get/ordenes/conciliadas', {params: {anio: a, mes: m}});
        if(response.data.success === true && response.data.result !== "Sin resultados"){
            setData(response.data.result);
            setRows(response.data.result[vistaActual] || []);
        }else{
            setData([]);
            setRows([]);
        }
    };
    const [columns, setColumns] = useState([]);
    const [detailColumns, setDetailColumns] = useState([]);
    const [selectedRowId, setSelectedRowId] = useState([]);

    const formatCurrency = (value) => {
		const formatter = new Intl.NumberFormat("en-US", {
			style: "currency",
			currency: "USD",
			minimumFractionDigits: 2,
			maximumFractionDigits: 2,
		});
		return formatter.format(value);
	};
    const handleRowClick = (row) => {
        setSelectedRowId(row.order_num);
    };
    const handleRowSelection = (row) => {
        setSelectedRowId(row);
    }
    const GridToolbarCambiarVista = () => {
        const handleChange = (event) => {
            setVistaActual(event.target.value);
        };
        
        return (
            <FormControl size="small">
                <Select
                    value={vistaActual}
                    onChange={handleChange}
                    sx={{ fontSize: '0.7rem', height: '30px', lineHeight: '30px' }}
                >
                    <MenuItem value={"rts"}>RTS</MenuItem>
                    <MenuItem value={"mcleod"}>MCLEOD</MenuItem>
                    <MenuItem value={"quickbooks"}>QUICKBOOKS</MenuItem>
                </Select>
            </FormControl>
        );
    };
    const meses = [
        { value: 1,  label: 'Enero' },
        { value: 2,  label: 'Febrero' },
        { value: 3,  label: 'Marzo' },
        { value: 4,  label: 'Abril' },
        { value: 5,  label: 'Mayo' },
        { value: 6,  label: 'Junio' },
        { value: 7,  label: 'Julio' },
        { value: 8,  label: 'Agosto' },
        { value: 9,  label: 'Septiembre' },
        { value: 10, label: 'Octubre' },
        { value: 11, label: 'Noviembre' },
        { value: 12, label: 'Diciembre' },
    ];
      
    const anios = [2024, 2023, 2022];
      
    const [mes, setMes] = useState('');
    const [anio, setAnio] = useState('');

    const handleAnioChange = (event) => {
        setAnio(event.target.value);
        setMes(''); // Resetea el mes cuando el año cambia
    };
    useEffect(() => {
        if(anio && mes){
            loadData(anio, mes);
        }
    }, [anio, mes]);
    const handleMesChange = (event) => {
        setMes(event.target.value);
    };
    const GridToolbarCambiarConciliacion = () => {
        
        return (
            <>
                 <FormControl size="small">
                    <Select
                        labelId="anio-selector-label"
                        value={anio}
                        onChange={handleAnioChange}
                        sx={{ fontSize: '0.8rem', height: '30px', lineHeight: '30px' }}
                    >
                        {anios.map((anio, index) => (
                            <MenuItem key={index} value={anio}>{anio}</MenuItem>
                        ))}
                    </Select>
                </FormControl>

                <FormControl size="small">
                    <Select
                        labelId="mes-selector-label"
                        value={mes}
                        onChange={handleMesChange}
                        sx={{ fontSize: '0.8rem', height: '30px', lineHeight: '30px' }}
                        disabled={!anio} // Deshabilita el select del mes hasta que se seleccione un año
                    >
                        {meses.map((mes, index) => (
                            <MenuItem key={index} value={mes.value}>{mes.label}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </>
        );
    };
    const GridToolBar = () => {
        return (
            <>
                {/* <GridToolbarCambiarVista /> */}
                <GridToolbarCambiarConciliacion />
            </>
        );
    }
    // const Actions = () => {
	// 	return (
	// 		<>	
	// 			<VerConciliadosButton />
	// 			<ConciliacionButton />
	// 			<FiltrosAvanzadosButton />
	// 		</>
	// 	)
	// }
    return (
        <Box m={'20px'}>
            <Header title={"Ordenes Conciliadas"} subtitle={"Ordenes conciliadas al mes"}/>
            {/* <GridView
                columns={columns}
                data={rows
                    // // Primero, verifica si 'data' existe
                    // (data &&
                    //     // Si 'data' existe, verifica el valor de 'vistaActual'
                    //     (vistaActual === "mcleod"
                    //         ? data?.mcleod // Si 'vistaActual' es 'mcleod', verifica que 'data.mcleod' existe antes de acceder a 'results'
                    //     : vistaActual === "rts"
                    //         ? data?.rts // Si 'vistaActual' es 'rts', verifica que 'data.rts' existe antes de acceder a 'results'
                    //     : vistaActual === "quickbooks"
                    //         ? data?.quickbooks // Si 'vistaActual' es 'quickbooks', verifica que 'data.quickbooks' existe antes de acceder a 'results'
                    //     : [])
                    // ) || []// Si 'data' no existe, usa un array vacío
                }
                handleRowClick={handleRowClick}
                selectedRowId={selectedRowId}
                handleRowSelection={handleRowSelection}
                CustomToolbar={GridToolBar}
                hidden={true}
                // getRowId={(row) => row.order_num}
            /> */}
            <CollapsibleTable
                data={data}
                columns={columns}
                detailColumns={detailColumns}
                handleChecks={()=>{}}
                showCheck={false}
                consecutivo={"order_num"}
                actions={GridToolBar()}
                header="Lista de ordenes con match"
                refresh={loadData}
            />
        </Box>
    );
};
export default OrdenesConciliadas;