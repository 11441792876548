import React, { useState, useMemo } from 'react'
import { Box, Button} from "@mui/material";
import Header from '../../components/Header'
import api from '../../config/axios';
import { useDropzone } from "react-dropzone"
import * as XLSX from 'xlsx'
import Swal from 'sweetalert2';
import imgXls from "../../assets/img/xlsx_icon.svg"

// ICONOS
import UploadIcon from '@mui/icons-material/Upload';

const baseStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#1f2a40',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#1f2a40',
    outline: 'none',
    transition: 'border .24s ease-in-out'
};

const focusedStyle = {
    borderColor: '#2196f3'
};
  
  const acceptStyle = {
    borderColor: '#00e676'
};
  
  const rejectStyle = {
    borderColor: '#ff1744'
};

function CargaInfoRts2() {

    const [datos, setdatos] = useState([]);
    const {
        getRootProps,
        getInputProps,
        isFocused,
        isDragAccept,
        isDragReject,
        acceptedFiles
    } = useDropzone({
        accept: {'application/vnd.ms-excel': [], 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': []},
        maxFiles: 1,
        onDrop:(acceptedFiles) => {
            
            const reader = new FileReader();
    
            reader.onload = (e) => {
              const data = e.target.result;
              const workbook = XLSX.read(data, { type: 'binary' });
              const sheetName = workbook.SheetNames[0];
              const sheet = workbook.Sheets[sheetName];
              const jsonData = XLSX.utils.sheet_to_json(sheet);
    
              setdatos(jsonData)
            };
        
            reader.readAsBinaryString(acceptedFiles[0]);
        }
    });
    
    const style = useMemo(() => ({
        ...baseStyle,
        ...(isFocused ? focusedStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {})
    }), [
        isFocused,
        isDragAccept,
        isDragReject
    ]);

    const handleSubmit = () =>{
        if(datos.length !== 0){
            api.post('post/uploadRTS2', datos)
            .then((response) => {
                console.log("Cargado", response.data);
                
                
            })
            .catch((error) => {
                console.log("ocurrio un error: " +error);
            })

            Swal.fire({
                icon: 'success',
                title: "¡Archivo Cargado!",
            })
            setdatos([]);
        }else{
            Swal.fire({
                icon: 'error',
                title: "¡Seleccione un archivo!",
            })
        }
    }
    
  return (
    <Box m="20px">

            <Header title="Cargar Reservas de RTS" subtitle="Cargue el documento..." />
        
            <Box m="40px 0 0 0"
                height="75vh"
                width="100%">
                <div {...getRootProps({style})}>
                    <input {...getInputProps()} />
                    <p>Arrastre el archivo aquí o de clic para seleccionar el archivo.</p>
                </div>
                <aside>
                <h4>Archivo Cargado:</h4>
                <ul id='listaArchivo'>
                    {acceptedFiles.map(file => (
                    <li key={file.path}>
                    <img src={imgXls} style={{height: '20px'}}></img> {file.path} - {file.size} bytes
                    </li>
                    ))}
                </ul>
                </aside>

                <Button type="submit" onClick={() => handleSubmit()} color="success" variant="contained" startIcon={<UploadIcon />} sx={{color: "white", fontWeight: "bold", marginTop: "20px"}}>
                    Subir
                </Button>

                
                    
            </Box>
        </Box>
  )
}

export default CargaInfoRts2