import React, { useState, useEffect } from "react";
import { Box, useTheme, Button, Tabs, Tab, Typography } from "@mui/material";
import { tokens } from "../../theme";
import PropTypes from "prop-types";
import Swal from "sweetalert2";
import Header from "../../components/Header";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import Grid from "@mui/material/Grid";
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
// ICONOS
import ManageSearchIcon from '@mui/icons-material/ManageSearch';

//COMPONENTES
import api from "../../config/axios";
import TarjetaGraficas from "../../components/TarjetaGraficas";
import TarjetaGraficasPorEmpresa from "../../components/Analytics/TarjetaGraficasPorEmpresa"


function AnalyticsBancos() {
  // ===============================================
  // COLORES PARA ESTILOS
  // ===============================================
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  // ===============================================
  // USES STATES
  // ===============================================

  const [selectedDate, setSelectedDate] = useState(null);

  const [bancos, setBancos] = useState([]);
  const [empresa, setEmpresa] = useState([]);

  const [datosPorBancos, setdatosPorBancos] = useState([])

  const [datosPorEmpresa, setdatosPorEmpresa] = useState([])

  const [moneda, setMoneda] = useState('MXN')

  const handleChangeSelectMoneda = (event) => {
    setMoneda(event.target.value);
  };

  // ===============================================
  // VARIABLES GLOBALES
  // ===============================================
  var arrayDeDatosFiltrados = [];
  // ===============================================
  // FUNCION PARA UTILIZAR TABS
  // ===============================================
  function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`analytics-tabpanel-${index}`}
        aria-labelledby={`analytics-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `analytics-tab-${index}`,
      "aria-controls": `analytics-tabpanel-${index}`,
    };
  }

  const [tabValue, setTabValue] = useState(0);

  const handleChangeTab = (event, newValue) => {
    setTabValue(newValue);
  };

  let colores = {
    'EBT FREIGHT SA DE CV': "hsl(170, 52%, 59%)",
    'TEDEL SA DE CV': 'hsl(9, 87%, 67%)',
    'ELITE BORDER SERVICIOS INTEGRALES SC.': 'hsl(54, 84%, 65%)',
    'JUAN JOSE FLORES DE LEON': 'hsl(203, 58%, 47%)'
  }
  const handleSubmit = async () => {
    if (selectedDate) {
      obtenerBancos();
      obtenerEmpresas();

      // Aquí puedes utilizar la fecha seleccionada como necesites
      let fecha = selectedDate.$d.toJSON().substring(0, 10);
      buscarSaldo(fecha);
      obtenerSaldoPorEmpresas(fecha, empresa)
      obtenerSaldoPorBancos(fecha)
    } else {
      console.log("Ninguna fecha seleccionada");
      Swal.fire({
        icon: "error",
        title: "Seleccione una fecha!",
      });
    }
  };

  // ===============================================
  // USEEFECT PARA DETECTAR EL CAMBIO DE FECHA
  // ===============================================
  useEffect(() => {
    if (selectedDate != null) {
      handleSubmit();
      setSelectedDate(null);
    }
  }, [bancos]);

  // ===============================================
  // FUNCION QUE SETEA LA FECHA
  // ===============================================
  const handleChange = (date) => {
    setSelectedDate(date);
    setdatosPorBancos([])
  };


  // ===============================================
  // FUNCION QUE OBTIENE LOS DATOS FILTRADO POR FECHA
  // ===============================================

  const buscarDatosAnalytics = async (fecha, idBanco) => {
    const response = await api.get(`get/analytics/${fecha}/${idBanco}`);

    if (
      response.data.success === true &&
      response.data.result !== "Sin resultados"
    ) {
      arrayDeDatosFiltrados.push(response.data.result);
    } else {
      console.log("ERROR");
    }
  };

  // ===============================================
  // QUE OBTIENE LOS BANCOS
  // ===============================================

  const obtenerSaldoPorEmpresas = async (fecha) => {

    const response = await api.get(`/get/analyticsPorEmpresa/${fecha}`);
    if (
      response.data.success === true &&
      response.data.result !== "Sin resultados"
    ) {
      let respuesta = response.data.result

      // Crear un objeto para agrupar por 'razon_social'
      const resultadosPorEmpresas = respuesta.reduce((acumulador, objeto) => {
        const razon_social = objeto.razon_social;
        if (!acumulador[razon_social]) {
          acumulador[razon_social] = [];
        }
        acumulador[razon_social].push(objeto);
        return acumulador;
      }, {});


      setdatosPorEmpresa(resultadosPorEmpresas)

      //setBancos(response.data.result);
    } else {
      console.log("ERROR");

      setdatosPorEmpresa([])
    }
  };

  const obtenerSaldoPorBancos = async (fecha) => {
    const response = await api.get(`/get/analyticsPorBanco/${fecha}`);

    if (response.data.success === true && response.data.result !== "Sin resultados") {
      let respuesta = response.data.result


      // Crear un objeto para agrupar por 'clave_banco'
      const resultadosPorBancos = respuesta.reduce((acumulador, objeto) => {
        const clave_banco = objeto.clave_banco;
        if (!acumulador[clave_banco]) {
          acumulador[clave_banco] = [];
        }
        acumulador[clave_banco].push(objeto);
        return acumulador;
      }, {});


      setdatosPorBancos(resultadosPorBancos)
    } else {
      setdatosPorBancos([])
      console.log("ERROR");
    }

  }

  // ===============================================
  // QUE OBTIENE LOS BANCOS
  // ===============================================

  const obtenerBancos = async () => {
    const response = await api.get("/get/bancos");

    if (
      response.data.success === true &&
      response.data.result !== "Sin resultados"
    ) {
      setBancos(response.data.result);
    } else {
      console.log("ERROR");

      setBancos([]);
    }
  };

  // ===============================================
  // FUNCION QUE OBTIENE LAS EMPRESAS
  // ===============================================

  const obtenerEmpresas = async () => {
    const response = await api.get("/get/empresasConSaldo");

    if (
      response.data.success === true &&
      response.data.result !== "Sin resultados"
    ) {
      setEmpresa(response.data.result);
    } else {
      console.log("ERROR");

      setEmpresa([]);
    }
  };


  // ===============================================
  // SEGMENTO PARA LLENAR LA TABLA
  // ===============================================

  const [informacionTabla, setInformacionTabla] = useState([]);

  const buscarSaldo = async (fecha) => {
    const response = await api.get(`get/analytics/${fecha}`);

    if (
      response.data.success === true &&
      response.data.result !== "Sin resultados"
    ) {
      setInformacionTabla(response.data.result);
    } else {
      console.log("ERROR");
      setInformacionTabla([]);
    }
  };

  const [sumatoriaTotal, setSumatoriaTotal] = useState([0, 0, 0, 0]);
  const obtenerSumatoriaTotal = () => {
    //console.log(informacionTabla);
    if (informacionTabla.length > 1) {
      let contadorInicio = 0;
      let contadorFinal = 0;
      let contadorInicioDlls = 0;
      let contadorFinalDlls = 0;
      let contadorVariacionPesos = 0;
      let contadorVariacionDolares = 0;
      informacionTabla.forEach(element => {
        contadorInicio += element.inicio_pesos;
        contadorFinal += element.fin_pesos
        contadorInicioDlls += element.inicio_dolares
        contadorFinalDlls += element.fin_dolares
        contadorVariacionPesos += element.variacion_pesos
        contadorVariacionDolares += element.variacion_dolares

      });
      return [contadorInicio, contadorFinal, contadorInicioDlls, contadorFinalDlls, contadorVariacionPesos, contadorVariacionDolares];
    } else {
      return [0, 0, 0, 0];
    }
  }

  // ===============================================
  // USEEFECT PARA DETECTAR EL CAMBIO DE Informacion
  // ===============================================
  useEffect(() => {
    let resultado = obtenerSumatoriaTotal()
    setSumatoriaTotal(resultado)
  }, [informacionTabla]);

  const textos = [
    'Total inicial pesos',
    'Total final pesos',
    'Total inicial dólares',
    'Total final dólares',
    'Total variación pesos',
    'Total variación dólares'
  ]
  // ===============================================
  // COLUMNAS DE LAS TABLAS
  // ===============================================
  const columns = [
    { field: "idSaldoBancos", headerName: "ID", width: 90 },
    {
      field: "clave_banco",
      headerName: "Banco",
      width: 150,
    },
    {
      field: "razon_social",
      headerName: "Empresa",
      width: 150,
    },
    {
      field: "inicio_pesos",
      headerName: "Apertura en pesos",
      type: "number",
      width: 110,
    },
    {
      field: "inicio_dolares",
      headerName: "Apertura en dolares",
      type: "number",
      width: 110,
    },
    {
      field: "fin_pesos",
      headerName: "Cierre en pesos",
      type: "number",
      width: 110,
    },
    {
      field: "fin_dolares",
      headerName: "Cierre en dolares",
      type: "number",
      width: 110,
    },
    {
      field: "variacion_pesos",
      headerName: "Variacion en pesos",
      type: "number",
      width: 110,
    },
    {
      field: "variacion_dolares",
      headerName: "Variacion en dolares",
      type: "number",
      width: 110,
    },
  ];

  return (
    <Box m="20px">
      <Header title="Analytics" subtitle="Información de saldo de bancos." />
      <form /* onSubmit={handleSubmit} */>
        <Typography>Seleccione una fecha:</Typography>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            value={selectedDate}
            onChange={handleChange}
            format="DD/MM/YYYY"
          />
        </LocalizationProvider>
        <Button
          type="button"
          color="success"
          onClick={() => handleSubmit()}
          variant="contained"
          startIcon={<ManageSearchIcon />}
          sx={{ color: "white", fontWeight: "bold", marginTop: "20px", marginLeft: "20px", width: "190px" }}
        >
          Buscar
        </Button>
      </form>

      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={tabValue}
            onChange={handleChangeTab}
            aria-label="basic tabs example"
          >
            <Tab
              label="BALANCE POR BANCOS"
              {...a11yProps(0)}
              sx={{
                color: colors.grey[100],
                "&.Mui-selected": {
                  color: "#1890ff",
                  fontWeight: theme.typography.fontWeightMedium,
                },
                "& span .MuiTabs-indicator": { backgroundColor: "red" },
              }}
            />
            <Tab
              label="BALANCE POR EMPRESA"
              {...a11yProps(1)}
              sx={{
                color: colors.grey[100],
                "&.Mui-selected": {
                  color: "#1890ff",
                  fontWeight: theme.typography.fontWeightMedium,
                },
              }}
            />
            <Tab
              label="Tabla"
              {...a11yProps(1)}
              sx={{
                color: colors.grey[100],
                "&.Mui-selected": {
                  color: "#1890ff",
                  fontWeight: theme.typography.fontWeightMedium,
                },
              }}
            />
          </Tabs>
        </Box>
        <CustomTabPanel value={tabValue} index={0}>
          <Box>
            <TarjetaGraficas
              cardTitle="EBT GLOBAL"
              bancoTitle="hola"
              /* datos={datosFiltrados}
              datosCierre={datosFiltradosCierre}
              moneda={moneda} */
              datos={datosPorBancos}
              datosCierre={datosPorBancos}
            />
          </Box>
        </CustomTabPanel>
        <CustomTabPanel value={tabValue} index={1}>
          <Box>
            <TarjetaGraficasPorEmpresa
              datos={datosPorEmpresa}
              datosCierre={datosPorEmpresa}
            />
          </Box>
        </CustomTabPanel>
        <CustomTabPanel value={tabValue} index={2}>
          <Box
            sx={{
              height: 400,
              width: "100%",
              "& .positive": {
                color: "green",
              },
              "& .negative": {
                color: "red",
              },
              "& .MuiDataGrid-root": {
                border: "none",
              },
              "& .MuiDataGrid-cell": {
                borderBottom: "none",
              },
              "& .name-column--cell": {
                color: colors.greenAccent[300],
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: colors.blueAccent[700],
                borderBottom: "none",
              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: colors.primary[400],
              },
              "& .MuiDataGrid-footerContainer": {
                borderTop: "none",
                backgroundColor: colors.blueAccent[700],
              },
              "& .MuiCheckbox-root": {
                color: `${colors.greenAccent[200]} !important`,
              },
              "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                color: `${colors.grey[100]} !important`
              }
            }}
          >
            <DataGrid
              rows={informacionTabla}
              columns={columns}
              components={{ Toolbar: GridToolbar }}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 5,
                  },
                },
              }}
              getRowId={(row) => row.idSaldoBancos}
              getCellClassName={(params) => {
                if (params.field === "idSaldoBancos" || params.value == null || params.field === "clave_banco" || params.field === "razon_social" || params.field === "inicio_pesos" || params.field === "fin_pesos" || params.field === "inicio_dolares" || params.field === "fin_dolares") {
                  return "";
                }
                if (params.value > 0) {
                  return "positive"
                } else if (params.value < 0) {
                  return "negative"
                } else {
                  return "";
                }
              }}
              pageSizeOptions={[5]}
              disableRowSelectionOnClick
            />
          </Box>
          <Grid container spacing={2} sx={{display:'flex', justifyContent:'center', marginTop:'10px'}}>
            {sumatoriaTotal.map((valor, index) => (
              <Grid sx={{backgroundColor: colors.primary[400], margin:'10px',}} item xs={3} key={index}>

                <Box width={'200px'} sx={{flexDirection: 'row', justifyContent: 'space-around', alignItems: 'center' }}>
                  <Box>
                    <p style={{ fontSize: '18px', textAlign:'center'}}>
                      {textos[index]}
                    </p>

                  </Box>
                  <Box>
                    <p style={{ fontSize: '18px', color:colors.greenAccent[300], textAlign:'center' }}>
                      $ {valor.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    </p>
                  </Box>

                </Box>
              </Grid>
            ))}
          </Grid>
        </CustomTabPanel>
      </Box>
    </Box>
  );
}

export default AnalyticsBancos;
