import React, { useState, useEffect } from "react";
import { Box, useTheme, Button } from "@mui/material";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { DataGrid, GridToolbar, esES  } from "@mui/x-data-grid";

//COMPONENTES
import api from "../../config/axios";
import * as Yup from 'yup';

// ICONOS
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

import { useLocation, useNavigate } from 'react-router-dom';
import { format } from 'date-fns';

import img from "../../assets/img/spinner.svg"

import Cookies from 'js-cookie';

//MODALS
import ModalCreateGM from '../../components/modals/ModalCreateGM'

function EstadoCuenta() {
  // ===============================================
  // COLORES PARA ESTILOS
  // ===============================================
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const navigate = useNavigate();

  var Locale = "es-mx";

  // ===============================================
  // USES STATES
  // ===============================================

  const location = useLocation();
  const { info } = location.state || {};

  //const [cliente, setCliente] = useState('');
  const [clientes, setClientes] = useState('');

  const [empresa, setEmpresa] = useState('');
  const [id_empresa, setIDEmpresa] = useState('0');
  const [selectedDate, setSelectedDate] = useState(null);

  useEffect(() => {

    window.scrollTo(0, 0);

    if(info)
    {
        const objeto = JSON.parse(info);
        //setCliente(objeto.cliente);
        setClientes(objeto.clientemostrar);
        setEmpresa(objeto.empresa);
        setIDEmpresa(objeto.id_empresa);
        setSelectedDate(objeto.fecha);
        buscarEstadoCuenta(new Date(objeto.fecha).toJSON().substring(0, 10), objeto.id_cliente, objeto.celda, objeto.id_empresa, objeto.clientes);
    }
    else
        navigate('/carteravencida');
  },[]);


  // ===============================================
  // SEGMENTO PARA LLENAR LA TABLA
  // ===============================================

  const [loading, setLoading] = useState(true);
  const [informacionTabla, setInformacionTabla] = useState([]);

  const buscarEstadoCuenta = async (fecha, cliente, celda, empresa, clientes) => {

    setLoading(true);

    try {
   
    const response = await api.get(`get/estadocuenta/${fecha}/${cliente}/${celda}/${empresa}`, { params: { clientes: clientes } });
    
    if (
      response.data.success === true &&
      response.data.result !== "Sin resultados"
    ) {
      //console.log(response.data.result)
      setInformacionTabla(response.data.result);
    } else {
      console.log("ERROR");
      setInformacionTabla([]);
    }
  } catch(error) {
    console.error("Error en la solicitud:", error);
    setInformacionTabla([]);
  }
  setLoading(false);
  };
  // ===============================================
  // COLUMNAS DE LAS TABLAS
  // ===============================================
  const columns = [
    //{ field: "id", headerName: "No.", width: 90 },
    {
      field: "invoice_age",
      headerName: "Invoice Age",
      flex: 2,
      sortComparator: (a, b, rowA, rowB) => rowA.id === '' ? 0 : a - b 
    },
    {
      field: "invoice_number",
      headerName: "Invoice Number",
      flex: 2,
      sortComparator: (a, b, rowA, rowB) => {
        if (rowA.id === '' || rowB.id === '') {
          return 0; 
        }
        const valueA = a === null ? '' : a.trim().toLowerCase();
        const valueB = b === null ? '' : b.trim().toLowerCase();
        return valueA.localeCompare(valueB);
      },
    },
    {
      field: "invoice_date",
      headerName: "Invoice Date",
      flex: 2,
      valueGetter: (params) => {
                
        if(params.row.invoice_date !== '0000-00-00'){
            const originalDate = params.row.invoice_date;
            const formattedDate = format(new Date(originalDate).setUTCHours(19, 0, 0, 0), 'MM/dd/yyyy');
            return formattedDate;
        }else{
            return ''
        }
      },
      sortComparator: (a, b, rowA, rowB) => {
        if (rowA.id === '' || rowB.id === '') {
          return 0; 
        }
        const valueA = a === null ? '' : a.trim().toLowerCase();
        const valueB = b === null ? '' : b.trim().toLowerCase();
        return valueA.localeCompare(valueB);
      },
    },
    {
      field: "load_number",
      headerName: "Load Number",
      flex: 2,
      sortComparator: (a, b, rowA, rowB) => {
        if (rowA.id === '' || rowB.id === '') {
          return 0; 
        }
        const valueA = a === null ? '' : a.trim().toLowerCase();
        const valueB = b === null ? '' : b.trim().toLowerCase();
        return valueA.localeCompare(valueB);
      },
    },
    {
      field: "customer",
      headerName: "Customer Name",
      flex: 2,
      sortComparator: (a, b, rowA, rowB) => {
        if (rowA.id === '' || rowB.id === '') {
          return 0; 
        }
        const valueA = a === null ? '' : a.trim().toLowerCase();
        const valueB = b === null ? '' : b.trim().toLowerCase();
        return valueA.localeCompare(valueB);
      },
    },
    {
        field: "credit_rating",
        headerName: "Credit Rating",
        flex: 2,
        sortComparator: (a, b, rowA, rowB) => {
          if (rowA.id === '' || rowB.id === '') {
            return 0; 
          }
          const valueA = a === null ? '' : a.trim().toLowerCase();
          const valueB = b === null ? '' : b.trim().toLowerCase();
          return valueA.localeCompare(valueB);
        },
    },
    {
        field: "avg_days_topay",
        headerName: "AVG Days to Pay",
        flex: 2,
        sortComparator: (a, b, rowA, rowB) => rowA.id === '' ? 0 : a - b 
    },
    {
      field: "invoice_amount",
      headerName: "Invoice Amount",
      type: "number",
      flex: 2,
      renderCell: (params) => (
        // Formatear el valor de la celda como moneda con "$" y separador de miles
        `$${params.value.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`
      ),
      sortComparator: (a, b, rowA, rowB) => rowA.id === '' ? 0 : a - b
    },
    /*
    {
      field: "estatus",
      headerName: "Estatus",
      //cellClassName: "name-column--cell",
      flex: 2,
      headerAlign: "center",
      align: "center",
      editable: true,
      renderEditCell: (params) => (
      <Select
          value={params.value || ''}
          
          onChange={(e) => { 
                  const newValue = parseInt(e.target.value);
                  //params.api.setEditCellValue({ id: params.id, field: params.field, value: newValue });
                  //console.log(params.api.setEditCellValue({ id: params.id, field: params.field, value: newValue }))
                  handleEditCellChange(params, newValue);
                  // Actualizar el valor de otra celda (por ejemplo, incrementar el valor de la celda 'otraCelda')
                  //const newValueForOtraCelda = parseInt(e.target.value) + 1;
                  //params.api.setEditCellValue({ id: params.id, field: 'Prioridad', value: newValueForOtraCelda });
                  //handleEditCellChange2(params, newValue);
              }
          }
      >
          <MenuItem value={1}>Pagada</MenuItem>
          <MenuItem value={2}>Aplicada</MenuItem>
      </Select>
      ),
      valueFormatter: (params) => {
          const statusValue = params.value;
          switch (statusValue) {
            case 1:
              return 'Pagada';
            case 2:
              return 'Aplicada';
            default:
              return '';
          }
        },
    },
    */
    {
      field: "estatus",
      headerName: "Estatus",
      flex: 2,
      sortComparator: (a, b, rowA, rowB) => {
        if (rowA.id === '' || rowB.id === '') {
          return 0; 
        }
        const valueA = a === null ? '' : a.trim().toLowerCase();
        const valueB = b === null ? '' : b.trim().toLowerCase();
        return valueA.localeCompare(valueB);
      },
      valueFormatter: (params) => {
        const statusValue = params.value;
        switch (statusValue) {
          case '1':
            return 'Pagada';
          case '2':
            return 'Aplicada';
          default:
            return '';
        }
      },
    },
    {
      field: "fecha_pago",
      headerName: "Fecha de Pago",
      flex: 2,
      valueGetter: (params) => {
             
        if(params.row.fecha_pago !== '0000-00-00' && params.row.fecha_pago !== null){
            const originalDate = params.row.fecha_pago;
            const formattedDate = format(new Date(originalDate).setUTCHours(19, 0, 0, 0), 'MM/dd/yyyy');
            return formattedDate;
        }else{
            return ''
        }
      },
      sortComparator: (a, b, rowA, rowB) => {
        if (rowA.id === '' || rowB.id === '') {
          return 0; 
        }
        const valueA = a === null ? '' : a.trim().toLowerCase();
        const valueB = b === null ? '' : b.trim().toLowerCase();
        return valueA.localeCompare(valueB);
      },
    },
    {
      field: "fecha_apli",
      headerName: "Fecha de Aplicación",
      flex: 2,
      valueGetter: (params) => {
             
        if(params.row.fecha_apli !== '0000-00-00' && params.row.fecha_apli !== null){
            const originalDate = params.row.fecha_apli;
            const formattedDate = format(new Date(originalDate).setUTCHours(19, 0, 0, 0), 'MM/dd/yyyy');
            return formattedDate;
        }else{
            return ''
        }
      },
      sortComparator: (a, b, rowA, rowB) => {
        if (rowA.id === '' || rowB.id === '') {
          return 0; 
        }
        const valueA = a === null ? '' : a.trim().toLowerCase();
        const valueB = b === null ? '' : b.trim().toLowerCase();
        return valueA.localeCompare(valueB);
      },
    },
    {
      field: "comentarios",
      headerName: "Comentarios",
      flex: 2,
      sortComparator: (a, b, rowA, rowB) => {
        if (rowA.id === '' || rowB.id === '') {
          return 0; 
        }
        const valueA = a === null ? '' : a.trim().toLowerCase();
        const valueB = b === null ? '' : b.trim().toLowerCase();
        return valueA.localeCompare(valueB);
      },
    },
  ];

  const sum = informacionTabla.reduce((total, row) => total + row.invoice_amount, 0);

  const sumRow = { 
    id: '',
    invoice_age: '', 
    invoice_number: '', 
    invoice_date: '0000-00-00', 
    load_number: '', 
    customer: '',
    credit_rating: '',
    avg_days_topay: 'TOTAL',
    invoice_amount: sum,
    fecha_pago: '0000-00-00',
    fecha_apli: '0000-00-00',
    comentarios: ''
  }; 

  const handleRegreso = () => {

    let parametros = { fecha: selectedDate, id_empresa: id_empresa, empresa: empresa }

    let info = JSON.stringify(parametros);

    navigate('/carteravencida', { state: { info: info } });

}

    // ===============================================
    // VALORES PARA SUBMIT DEL FORMULARIO
    // ===============================================

    const [initialFormValues, setInicialFormValues] = useState({

      fecha_pago: '',
      fecha_apli: '',
      comentarios: '',
      estatus: '0'

  })

  // ===============================================
  // FUNCION PARA ABRIR O CERRAR EL MODAL CREARA
  // ===============================================

  const [isOpen, setIsOpen] = useState(false);

  const handleOpenModal = () => {
/*
      setInicialFormValues({

          fecha_pago: '',
          fecha_apli: '',
          comentarios: ''

  
      })*/

      setIsOpen(true);
  };
  
  const handleCloseModal = () => {
      setIsOpen(false);
  };

  const estatus = [

    { value: null, text: 'Seleccione' },
    { value: '1', text: 'Pagada' },
    { value: '2', text: 'Aplicada' },

];

  // ===============================================
  // INPUTS PARA ARMAR EL FORMULARIO
  // ===============================================

  const inputForms = [

        {
          componente: "select",
          label: "Seleccione un Estatus",
          name: "estatus",
          options: estatus,
          value: "value",
          text: "text",
          md: 12,
          xs: 12
      },
      {
        componente: "inputDate",
        label: "Fecha Pago:",
        name: "fecha_pago",
        placeholder: "Seleccione la Fecha de Pago",
        type: "text",
        Locale: "es-mx",
        format: "YYYY-MM-DD",
        md: 12,
        xs: 12
      },
      {
        componente: "inputDate",
        label: "Fecha Aplicación:",
        name: "fecha_apli",
        placeholder: "Seleccione la Fecha de Aplicación",
        type: "text",
        Locale: "es-mx",
        format: "YYYY-MM-DD",
        md: 12,
        xs: 12
      },
      {
        componente: "input",
        label: "Comentarios",
        name: "comentarios",
        placeholder: "Ingrese Comentarios",
        type: "textarea",
        multiline: true,
        md: 12,
        xs: 12
    },
  ];

  // ===============================================
  // VALIDACIONES PARA EL FORMULARIO
  // ===============================================

const yupValidation = Yup.object().shape({
  estatus: Yup.string().required('Requerido'),
  comentarios: Yup.string().required('Requerido'),
  fecha_pago: Yup.string().when('estatus', {
    is: (value) => value === '1',
    then: () => Yup.string().test({
      name: 'fechaPagoValida',
      message: 'Formato de fecha no válido.',
      test: (value) => {
        let fecha = value.substring(0, 10);
        return /^[0-9]{4}-[0-9]{2}-[0-9]{2}$/.test(fecha);
      },
    }).required('Requerida'),
    otherwise: () => Yup.string().nullable(),
  }),
  fecha_apli: Yup.string().when('estatus', {
    is: (value) => value === '2',
    then: () => Yup.string().test({
      name: 'fechaApliValida',
      message: 'Formato de fecha no válido.',
      test: (value) => {
        let fecha = value.substring(0, 10);
        //return /^(0[1-9]|[1-2][0-9]|3[0-1])\/(0[1-9]|1[0-2])\/([0-9]{4})$/.test(value);
        //return /^[0-9]{2}\/[0-9]{2}\/[0-9]{4}$/.test(value);
        //return 
        return /^[0-9]{4}-[0-9]{2}-[0-9]{2}$/.test(fecha);
      },
    }).required('Requerida'),
    otherwise: () => Yup.string().nullable(),
  }),  
});

  /*
  .test('at-least-one-date', 'Al menos una fecha debe ser proporcionada', function (values) {
      console.log(values)
      const { fecha_pago, fecha_apli } = values;
      if (!fecha_pago && !fecha_apli) {
          return this.createError({
              path: 'fecha_pago',
              message: 'Al menos una fecha debe ser proporcionada',
          });
      }
  });*/

  // ===============================================
  // FUNCION PARA EDITAR REGISTRO
  // ===============================================

  const handleRowClick = (params) => {

    /*
      setInicialFormValues(params.row);
      handleOpenModalEdit();
      */
      if(params.row.id != '')
      {
        setInicialFormValues({...params.row, usuario: Cookies.get('user')});
        handleOpenModal();
      }

  }

  // ===============================================

  return (
    <div>
    {loading ? (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <img
            alt="Cargando..."
            width="200px"
            height="200px"
            src={img}
            style={{ cursor: "pointer", borderRadius: "50%" }}
          />
      </div>
    ) : (
    <Box m="20px">
      <Header title="Estado de Cuenta" subtitle={
        clientes.split('\n').map((linea, index) => (
          <React.Fragment key={index}>
            {linea}
            <br />
          </React.Fragment>
        ))
        } />
        <Button
            type="button"
            color="inherit"
            onClick={() => handleRegreso()}
            variant="contained"
            startIcon={<ChevronLeftIcon />}
            sx={{ color: "primary", fontWeight: "bold", marginTop: "20px", width: "190px" }}
          >
            Regresar
        </Button>
        <Box
          sx={{
            height: 400,
            width: "100%",
            "& .positive": {
              color: "green",
            },
            "& .negative": {
              color: "red",
            },
            "& .MuiDataGrid-root": {
              border: "none",
            },
            "& .MuiDataGrid-cell": {
              borderBottom: "none",
            },
            "& .name-column--cell": {
              color: colors.greenAccent[300],
            },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: colors.blueAccent[700],
              borderBottom: "none",
            },
            "& .MuiDataGrid-virtualScroller": {
              backgroundColor: colors.primary[400],
            },
            "& .MuiDataGrid-footerContainer": {
              borderTop: "none",
              backgroundColor: colors.blueAccent[700],
            },
            "& .MuiCheckbox-root": {
              color: `${colors.greenAccent[200]} !important`,
            },
            "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
              color: `${colors.grey[100]} !important`
            }
          }}
        >
          <DataGrid
            loading={loading}
            //rows={informacionTabla}
            rows={informacionTabla.concat(sumRow)}
            columns={columns}
            components={{ Toolbar: GridToolbar }}
            componentsProps={{
              toolbar: {
                  csvOptions : { utf8WithBom: true }
              },
            }}
            getRowId={(row) => row.id}
            disableRowSelectionOnClick
            localeText={esES.components.MuiDataGrid.defaultProps.localeText}
            //onRowClick={handleOpenModal}
            onRowClick={handleRowClick}
          />
        </Box>

        <ModalCreateGM
            modalTitle="Actualizar y comentarios"
            swalTitle="¡Se ha actualizado la factura!"
            url="create/fechapago"
            open={isOpen} 
            onClose={handleCloseModal} 
            size='25'
            initialValues={initialFormValues}
            inputForms={inputForms}
            yupValidation={yupValidation}
            refresh={() => {

              window.scrollTo(0, 0);
          
              if(info)
              {
                  const objeto = JSON.parse(info);
                  //setCliente(objeto.cliente);
                  setClientes(objeto.clientemostrar);
                  setEmpresa(objeto.empresa);
                  setIDEmpresa(objeto.id_empresa);
                  setSelectedDate(objeto.fecha);
                  buscarEstadoCuenta(new Date(objeto.fecha).toJSON().substring(0, 10), objeto.id_cliente, objeto.celda, objeto.id_empresa, objeto.clientes);
              }
              else
                  navigate('/carteravencida');
            }}
        />

  </Box>
  )}
  </div>
  );
}

export default EstadoCuenta;
