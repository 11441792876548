import React from 'react';
import { useField } from 'formik';
import { Select } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';

const MySelect = ({ label, ...props }) => {

    const [field, meta] = useField(props);

    //console.log(field)

    return (

        <FormControl fullWidth>

            <InputLabel >{label}</InputLabel>

            <Select 
                {...field} 
                {...props}  
                sx={{width: "100%"}}
            />
            {
                meta.touched && meta.error ? (
                    <div className="error" style={{color: 'red', fontSize: '0.6428571428571428rem'}}>{meta.error}</div>
                ) : null
            }

        </FormControl>
    
    )

}

export default MySelect