import { Box, Typography, useTheme } from "@mui/material";
import React, { useState } from "react";
import { tokens } from "../../theme";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import Header from "../../components/Header";
import Chip from '@mui/material/Chip';

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import Carousel from "react-material-ui-carousel";

import StatBoxDashboard from "../../components/Dashboard/StatBoxDashboard";

// ICONOS
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import GppMaybeIcon from '@mui/icons-material/GppMaybe';
//COMPONENTES
import api from "../../config/axios";

function DashboardBancos() {
    const [selectedDateDashBoard, setSelectedDate] = useState(null);
    const [empresa, setEmpresa] = useState([]);
    const [datosAp, setdatosAp] = useState([])
    const [datosClo, setdatosClo] = useState([])
    const [isFilled, setIsFilled] = useState(false);
  
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
  
    // ===============================================
    // FUNCION QUE OBTIENE LAS EMPRESAS
    // ===============================================
  
    const obtenerEmpresas = async () => {
      const response = await api.get("/get/empresasConSaldo");
  
      if (
        response.data.success === true &&
        response.data.result !== "Sin resultados"
      ) {
        setEmpresa(response.data.result);
      } else {
        setIsFilled(false)
        console.log("ERROR");
  
        setEmpresa([]);
      }
    };
  
  
    const handleSubmit = async (date) => {
  
      /* if (selectedDateDashBoard) { */
  
      // Aquí puedes utilizar la fecha seleccionada como necesites
      let fecha = date.$d.toJSON().substring(0, 10)
  
      let resultado = await obtenerDatosDashboard(fecha)
  
  
      if (resultado !== undefined) {
        let respuesta = transformarDatosApertura(resultado)
        let respuesta2 = transformarDatosCierre(resultado)
        setdatosAp(respuesta)
        setdatosClo(respuesta2)
        setIsFilled(true)
      } else {
        setIsFilled(false)
      }
  
    };
  
    const obtenerDatosDashboard = async (fecha) => {
      const response = await api.get(`/get/analyticsPorBanco/${fecha}`);
  
      if (
        response.data.success === true &&
        response.data.result !== "Sin resultados"
      ) {
        let respuesta = response.data.result
  
        // Crear un objeto para agrupar por 'razon_social'
        const resultadosDashboard = respuesta.reduce((acumulador, objeto) => {
          const clave_banco = objeto.clave_banco;
          if (!acumulador[clave_banco]) {
            acumulador[clave_banco] = [];
          }
          acumulador[clave_banco].push(objeto);
          return acumulador;
        }, {});
        return resultadosDashboard
        //setDatosFiltrados(resultadosDashboard)
      } else {
        console.log("ERROR");
      }
    };
  
    function transformarDatosApertura(datos) {
      const nuevoObjeto = {};
  
      for (const clave_banco in datos) {
        const bancos = datos[clave_banco];
        for (const dato of bancos) {
          const id = dato.idSaldoBancos;
          const empresa = dato.razon_social;
          const cantidadPesos = dato.suma_inicio_pesos;
          const cantidadDolares = dato.suma_inicio_dolares;
          const banco = dato.clave_banco;
  
          nuevoObjeto[id] = {
            empresa,
            cantidadPesos,
            cantidadDolares,
            banco
          };
        }
      }
  
      const gruposPorLabel = {};
  
      for (const id in nuevoObjeto) {
        const { empresa, cantidadPesos, cantidadDolares, banco } = nuevoObjeto[id];
  
        if (!gruposPorLabel[empresa]) {
          gruposPorLabel[empresa] = [];
        }
  
        gruposPorLabel[empresa].push({ id, empresa, cantidadPesos, cantidadDolares, banco });
      }
  
      const arregloTransformado = [];
      for (const empresa in gruposPorLabel) {
        const elementos = gruposPorLabel[empresa];
        for (const elemento of elementos) {
          //console.log(elemento);
          arregloTransformado.push({
            empresa: elemento.empresa,
            id: elemento.id,
            cantidadPesos: elemento.cantidadPesos,
            cantidadDolares: elemento.cantidadDolares,
            banco: elemento.banco
          });
        }
      }
      obtenerEmpresas()
      return arregloTransformado;
    }
  
    function transformarDatosCierre(datos) {
      const nuevoObjeto = {};
  
      for (const clave_banco in datos) {
        const bancos = datos[clave_banco];
        for (const dato of bancos) {
          const id = dato.idSaldoBancos;
          const empresa = dato.razon_social;
          const cantidadPesos = dato.suma_fin_pesos;
          const cantidadDolares = dato.suma_fin_dolares;
          const variacionmx = dato.variacion_mx
          const variaciondlss = dato.variacion_dlls
          const banco = dato.clave_banco;
  
          nuevoObjeto[id] = {
            empresa,
            cantidadPesos,
            cantidadDolares,
            variacionmx,
            variaciondlss,
            banco
          };
        }
      }
  
      const gruposPorLabel = {};
  
      for (const id in nuevoObjeto) {
        const { empresa, cantidadPesos, cantidadDolares, variacionmx, variaciondlss, banco } = nuevoObjeto[id];
  
        if (!gruposPorLabel[empresa]) {
          gruposPorLabel[empresa] = [];
        }
  
        gruposPorLabel[empresa].push({ id, empresa, cantidadPesos, cantidadDolares, variacionmx, variaciondlss, banco });
      }
  
      const arregloTransformado = [];
      for (const empresa in gruposPorLabel) {
        const elementos = gruposPorLabel[empresa];
        for (const elemento of elementos) {
          arregloTransformado.push({
            empresa: elemento.empresa,
            id: elemento.id,
            cantidadPesos: elemento.cantidadPesos,
            cantidadDolares: elemento.cantidadDolares,
            variacionMx: elemento.variacionmx,
            variacionUsd: elemento.variaciondlss,
            banco: elemento.banco
          });
        }
      }
      obtenerEmpresas()
      return arregloTransformado;
    }
  
  
  
    const handleChange = (date) => {
      setSelectedDate(date);
      handleSubmit(date);
    };
    // ===============================================
    // VARIABLES GLOBALES
    // ===============================================
  
    function Item(props) {
      return (
        <>
          <Typography
            variant="h2"
            fontStyle="italic"
            sx={{ color: colors.greenAccent[600] }}
            align="center"
          >
            APERTURA - {props.item[0].empresa}
          </Typography>
          <Box
            display="grid"
            gridTemplateColumns="repeat(12, 1fr)"
            gridAutoRows="140px"
            gap="20px"
          >
            {props.item.map((val, i) => {
              return (
                <Box
                  gridColumn="span 3"
                  backgroundColor={colors.primary[400]}
                  display="flex"
                  marginRight="20px"
                  alignItems="center"
                  justifyContent="space-between"
                  key={i}
                >
                  <StatBoxDashboard
                    empresa={val.banco}
                    cantidadDolares={val.cantidadDolares.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    cantidadPesos={val.cantidadPesos.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    icon={
                      <AttachMoneyIcon
                        sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
                      />
                    }
                    icon2={<AccountBalanceIcon sx={{ color: colors.greenAccent[600], fontSize: "26px" }} />}
                  />
                </Box>
              );
            })}
          </Box>
          <br></br>
          <hr></hr>
          <Typography
            variant="h2"
            fontStyle="italic"
            sx={{ color: colors.greenAccent[600] }}
            align="center"
          >
            CIERRE - {props.item[0].empresa}
          </Typography>
          <Box
            display="grid"
            gridTemplateColumns="repeat(12, 1fr)"
            gridAutoRows="140px"
            gap="20px"
          >
            {props.datos.map((val, i) => {
              return (
                <Box
                  gridColumn="span 3"
                  backgroundColor={colors.primary[400]}
                  display="flex"
                  marginRight="20px"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <StatBoxDashboard
                    empresa={val.banco}
                    variacionMx={val.variacionMx.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    variacionUsd={val.variacionUsd.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    cantidadDolares={val.cantidadDolares.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    cantidadPesos={val.cantidadPesos.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    icon={
                      <AttachMoneyIcon
                        sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
                      />
                    }
                    icon2={<AccountBalanceIcon sx={{ color: colors.greenAccent[600], fontSize: "26px" }} />}
                  />
                </Box>
              );
            })}
          </Box>
        </>
      );
    }
  
    return (
      <Box m="20px">
        {/* HEADER */}
        <Box display="flex" justifyContent="space-around" alignItems="center">
          <Header title="DASHBOARD" subtitle="Bienvenido a SIIF" />
        </Box>
  
        <Box
          display="grid"
          gridTemplateColumns="repeat(12, 1fr)"
          gridAutoRows="140px"
          gap="20px"
        >
          {/* ROW 2 */}
          <Box gridColumn="span 12" gridRow="span 2">
            <Box
              mt="25px"
              p="0 30px"
              display="flex "
              justifyContent="space-between"
              alignItems="center"
            >
              <Box sx={{ marginBottom: "20px" }}>
                <Typography>Seleccione una fecha:</Typography>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    value={selectedDateDashBoard}
                    onChange={handleChange}
                    format="DD/MM/YYYY"
                  />
                </LocalizationProvider>
  
              </Box>
            </Box>
            {isFilled && (
              <Carousel
                navButtonsWrapperProps={{
                  // Move the buttons to the bottom. Unsetting top here to override default style.
                  style: {
                    bottom: "0",
                    top: "unset",
                  },
                }}
              >
  
                {empresa.map((value, i) => {
                  if (datosAp != undefined) {
                    const r = datosAp.filter(el => el.empresa == value.razon_social)
                    const r2 = datosClo.filter(el => el.empresa == value.razon_social)
                    return (<Item key={i} item={r} datos={r2} />)
  
                  }
                }
                )}
              </Carousel>
            )}
            {!isFilled && (
              <Box
                height="100px"
                m="-20px 0 0 0"
                backgroundColor={colors.primary[400]}
                sx={{ marginTop: "20px", paddingTop: "20px" }}
              >
                <center>
                  <Chip
                    sx={{ width: '300px', fontSize: '20px' }}
                    icon={<GppMaybeIcon sx={{ fontSize: '3s0px' }}></GppMaybeIcon>}
                    label={"Sin información"}
                  />
                </center>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    );
}

export default DashboardBancos