import React, { useState, useEffect } from "react";
import { Box, useTheme, Button,Menu } from "@mui/material";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { DataGrid, GridToolbar, esES } from "@mui/x-data-grid";

// ICONOS
import FileUploadIcon from '@mui/icons-material/FileUpload';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import GridOnIcon from '@mui/icons-material/GridOn';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import MenuIcon from '@mui/icons-material/Menu';
import UploadFileIcon from '@mui/icons-material/UploadFile';

//COMPONENTES
import api from "../../config/axios";
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

import 'dayjs/locale/es-mx';
import { useLocation, useNavigate } from 'react-router-dom';

import { Dialog, DialogContent } from '@mui/material';

import img from "../../assets/img/spinner.svg";
import * as XLSX from 'xlsx';

function CarteraVencida() {

  // ===============================================
  // COLORES PARA ESTILOS
  // ===============================================
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const navigate = useNavigate();

  var Locale = "es-mx";

  const location = useLocation();
  const { info } = location.state || {};

  // ===============================================
  // USES STATES
  // ===============================================

  const [empresa, setEmpresa] = useState('');
  const [id_empresa, setIDEmpresa] = useState('0');
  const [selectedDate, setSelectedDate] = useState(null);

  const [tipo, setTipo] = useState('1');

  const handleChangeSelect = (event) => {
    setTipo(event.target.value);
  };

  useEffect(() => {

    window.scrollTo(0, 0);

    if(info)
    {
        const objeto = JSON.parse(info);
        setEmpresa(objeto.empresa);
        setIDEmpresa(objeto.id_empresa);
        setSelectedDate(objeto.fecha);
        buscarCartera(new Date(objeto.fecha).toJSON().substring(0, 10), objeto.id_empresa);
    }
    else
        navigate('/comparativa');
  },[]);

  // ===============================================
  // SEGMENTO PARA LLENAR LA TABLA
  // ===============================================

  const [loading, setLoading] = useState(true);
  const [informacionTabla, setInformacionTabla] = useState([]);

  const buscarCartera = async (fecha, empresa) => {

    setLoading(true);

    try {
    const response = await api.get(`get/carteravencida/${fecha}/${empresa}`);

    if (
      response.data.success === true &&
      response.data.result !== "Sin resultados"
    ) {
        //console.log(response.data.result)
      setInformacionTabla(response.data.result);
    } else {
      console.log("ERROR");
      setInformacionTabla([]);
    }
  } catch(error) {
    console.error("Error en la solicitud:", error);
    setInformacionTabla([]);
  }

  setLoading(false);

  };
  // ===============================================
  // COLUMNAS DE LAS TABLAS
  // ===============================================
  const columns = [
    { field: "id", headerName: "No.", width: 90, sortComparator: (a, b, rowA, rowB) => rowA.id === '' ? 0 : a - b  },
    {
      field: "customer",
      headerName: "Customer Name",
      width: 160,
      sortComparator: (a, b, rowA, rowB) => {
        if (rowA.id === '' || rowB.id === '') {
          return 0; 
        }
        const valueA = a === null ? '' : a.trim().toLowerCase();
        const valueB = b === null ? '' : b.trim().toLowerCase();
        return valueA.localeCompare(valueB);
      },
    },
    {
      field: "credit_rating",
      headerName: "Credit Rating",
      width: 80,
      sortComparator: (a, b, rowA, rowB) => {
        if (rowA.id === '' || rowB.id === '') {
          return 0; 
        }
        const valueA = a === null ? '' : a.trim().toLowerCase();
        const valueB = b === null ? '' : b.trim().toLowerCase();
        return valueA.localeCompare(valueB);
      },
    },
    {
      field: "ventas",
      headerName: "Ventas",
      width: 110,
      sortComparator: (a, b, rowA, rowB) => {
        if (rowA.id === '' || rowB.id === '') {
          return 0; 
        }
        const valueA = a === null ? '' : a.trim().toLowerCase();
        const valueB = b === null ? '' : b.trim().toLowerCase();
        return valueA.localeCompare(valueB);
      },
      
    },
    {
      field: "dias_credito",
      headerName: "Días de crédito",
      type: "number",
      width: 110,
      sortComparator: (a, b, rowA, rowB) => rowA.id === '' ? 0 : a - b 
    },
    /*
    {
      field: "",
      headerName: "Reservas por recuperar",
      type: "number",
      width: 110,
    },
    */
    {
      field: "1",
      headerName: "1-30 días",
      type: "number",
      width: 110,
      renderCell: (params) => (
        // Formatear el valor de la celda como moneda con "$" y separador de miles
        `$${params.value.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`
      ),
      sortComparator: (a, b, rowA, rowB) => rowA.id === '' ? 0 : a - b
    },
    {
      field: "31",
      headerName: "31-45",
      type: "number",
      width: 110,
      renderCell: (params) => (
        // Formatear el valor de la celda como moneda con "$" y separador de miles
        `$${params.value.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`
      ),
      sortComparator: (a, b, rowA, rowB) => rowA.id === '' ? 0 : a - b
    },
    {
        field: "46",
        headerName: "46-60",
        type: "number",
        width: 110,
        renderCell: (params) => (
          // Formatear el valor de la celda como moneda con "$" y separador de miles
          `$${params.value.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`
        ),
        sortComparator: (a, b, rowA, rowB) => rowA.id === '' ? 0 : a - b
    },
    {
        field: "61",
        headerName: "61-90",
        type: "number",
        width: 110,
        renderCell: (params) => (
          // Formatear el valor de la celda como moneda con "$" y separador de miles
          `$${params.value.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`
        ),
        sortComparator: (a, b, rowA, rowB) => rowA.id === '' ? 0 : a - b
    },
    {
        field: "91",
        headerName: "91-120",
        type: "number",
        width: 110,
        renderCell: (params) => (
          // Formatear el valor de la celda como moneda con "$" y separador de miles
          `$${params.value.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`
        ),
        sortComparator: (a, b, rowA, rowB) => rowA.id === '' ? 0 : a - b
    },
    {
      field: "121",
      headerName: "121+",
      type: "number",
      width: 110,
      renderCell: (params) => (
        // Formatear el valor de la celda como moneda con "$" y separador de miles
        `$${params.value.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`
      ),
      sortComparator: (a, b, rowA, rowB) => rowA.id === '' ? 0 : a - b
  },
    {
        field: "importe_vencido",
        headerName: "Importe vencido",
        type: "number",
        width: 110,
        renderCell: (params) => (
          // Formatear el valor de la celda como moneda con "$" y separador de miles
          `$${params.value.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`
        ),
        sortComparator: (a, b, rowA, rowB) => rowA.id === '' ? 0 : a - b
    },
    /*
    {
        field: "",
        headerName: "Saldo por aplicar",
        type: "number",
        width: 110,
    },
    {
        field: "",
        headerName: "Total Balance",
        type: "number",
        width: 110,
    },
    {
        field: "",
        headerName: "Comentarios",
        type: "number",
        width: 110,
    },*/
  ];

  const [selectedRows, setSelectedRows] = useState([]);

  const [sum1, setSum1] = useState(0);
  const [sum31, setSum31] = useState(0);
  const [sum46, setSum46] = useState(0);
  const [sum61, setSum61] = useState(0);
  const [sum91, setSum91] = useState(0);
  const [sum121, setSum121] = useState(0);
  const [sumiv, setSumIV] = useState(0);

  useEffect(() => {

    const newSum1 = informacionTabla.reduce((total, row) => total + row['1'], 0);
    const newSum31 = informacionTabla.reduce((total, row) => total + row['31'], 0);
    const newSum46 = informacionTabla.reduce((total, row) => total + row['46'], 0);
    const newSum61 = informacionTabla.reduce((total, row) => total + row['61'], 0);
    const newSum91 = informacionTabla.reduce((total, row) => total + row['91'], 0);
    const newSum121 = informacionTabla.reduce((total, row) => total + row['121'], 0);
    const newSumIV = informacionTabla.reduce((total, row) => total + row.importe_vencido, 0);
  
    setSum1(newSum1);
    setSum31(newSum31);
    setSum46(newSum46);
    setSum61(newSum61);
    setSum91(newSum91);
    setSum121(newSum121);
    setSumIV(newSumIV);

  }, [informacionTabla]);

  /*
  const sum1 = informacionTabla.reduce((total, row) => total + row['1'], 0);
  const sum31 = informacionTabla.reduce((total, row) => total + row['31'], 0);
  const sum46 = informacionTabla.reduce((total, row) => total + row['46'], 0);
  const sum61 = informacionTabla.reduce((total, row) => total + row['61'], 0);
  const sum91 = informacionTabla.reduce((total, row) => total + row['91'], 0);
  const sum121 = informacionTabla.reduce((total, row) => total + row['121'], 0);
  const sumiv = informacionTabla.reduce((total, row) => total + row.importe_vencido, 0);
  */

  const sumRow = { 
    id: '', 
    customer: '', 
    credit_rating: '', 
    ventas: '', 
    dias_credito: 'TOTAL',
    1: sum1,
    31: sum31,
    46: sum46,
    61: sum61,
    91: sum91,
    121: sum121,
    importe_vencido: sumiv
  }; 

  const handleCellClick = (params) => {

    if(params.row.id != '' && params.field != '__check__' && (params.value > 0 || params.field === 'customer'))
    {
        if(selectedRows.length>0)
        {
          if(selectedRows.map(cliente => cliente.customer).includes(params.row.customer))
          {
            var clientes = selectedRows.map(cliente => `'${cliente.customer}'`).join(', ');
            var clientemostrar = selectedRows.map(cliente => `${cliente.customer}`).join('\n');
          }
          else
          {
            var clientes = "'" + params.row.customer + "'";
            var clientemostrar = params.row.customer;
          }
        }
        else
        {
          var clientes = "'" + params.row.customer + "'";
          var clientemostrar = params.row.customer;
        }

          //let parametros = { fecha: selectedDate, id_cliente: params.row.id, cliente: params.row.customer, celda: params.field, id_empresa: id_empresa, empresa: empresa }
        var parametros = { fecha: selectedDate, id_cliente: params.row.id, clientes: clientes, celda: params.field, id_empresa: id_empresa, empresa: empresa, clientemostrar: clientemostrar }

        let info = JSON.stringify(parametros);

        navigate('/estadocuenta', { state: { info: info } });
    }

}
  const handleRegreso = () => {

    let parametros = { fecha: selectedDate }

    let info = JSON.stringify(parametros);

    navigate('/comparativa', { state: { info: info } });

}

  const [open, setOpen] = useState(false);

  const [url, setUrl] = useState('');

  const handleCloseDialog = () => {
    setOpen(false);
  };

  const handleOpenDialog = (url) => {
    setUrl(url);
    setOpen(true);
  };

  // Manejar el mensaje enviado desde el iframe
  const handleMessageFromIframe = (event) => {

    if (event.data === 'cerrarDialogo') {
      handleCloseDialog();
    }
  };

  window.addEventListener('message', handleMessageFromIframe);

  /*
  const excelPlantilla = async () => {

    const plantilla = [{

      INVOICE_AGE: '',
      INVOICE_NUMBER: '',
      LOAD_NUMBER: '',
      CUSTOMER_NAME: '',
      CREDIT_RATING: '',
      AVG_DAYS_TOPAY: '',
      INVOICE_AMOUNT: ''
  
    }]

    const filename = 'PlantillaRTS.xlsx';

    const worksheet = XLSX.utils.json_to_sheet(plantilla);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Plantilla');
    XLSX.writeFile(workbook, filename);

}
*/

const excelPlantillaRTS = async () => {

  const plantilla = [{

    INVOICE_AGE: '',
    INVOICE_NUMBER: '',
    LOAD_NUMBER: '',
    CUSTOMER_NAME: '',
    CREDIT_RATING: '',
    AVG_DAYS_TOPAY: '',
    INVOICE_AMOUNT: ''

  }]

  const filename = 'PlantillaRTS.xlsx';

  const worksheet = XLSX.utils.json_to_sheet(plantilla);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Plantilla');
  XLSX.writeFile(workbook, filename);

}

const excelPlantillaMicro = async () => {

  const plantilla = [{

    CLIENTE: '',
    FOLIO: '',
    REFERENCIA: '',
    VENCE: '',
    IMPORTE: ''

  }]

  const filename = 'PlantillaMicrosip.xlsx';

  const worksheet = XLSX.utils.json_to_sheet(plantilla);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Plantilla');
  XLSX.writeFile(workbook, filename);

}

const [anchorEl, setAnchorEl] = useState(null);

const handleClick = (event) => {
  setAnchorEl(event.target);
};

const handleClose = () => {
  setAnchorEl(null);
};

const [anchorEl2, setAnchorEl2] = useState(null);

const handleClick2 = (event) => {
  setAnchorEl2(event.target);
};

const handleClose2 = () => {
  setAnchorEl2(null);
};

return (
<div>
    {loading ? (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <img
            alt="Cargando..."
            width="200px"
            height="200px"
            src={img}
            style={{ cursor: "pointer", borderRadius: "50%" }}
          />
      </div>
    ) : (
    <Box m="20px">
      <Header title="Cartera Vencida" subtitle={empresa} />
          <Button
            type="button"
            color="inherit"
            onClick={() => handleRegreso()}
            variant="contained"
            startIcon={<ChevronLeftIcon />}
            sx={{ color: "primary", fontWeight: "bold", marginTop: "20px", width: "190px" }}
          >
            Regresar
          </Button>
          {/*
          <Button
            type="button"
            color="inherit"
            onClick={() => excelPlantilla()}
            variant="contained"
            startIcon={<GridOnIcon />}
            sx={{ color: "primary", fontWeight: "bold", marginTop: "20px", width: "190px" }}
          >
            Plantilla
          </Button>
          */}
          <Button
                  type="button"
                  variant="contained"
                  color="inherit"
                  onClick={handleClick2}
                  startIcon={<GridOnIcon />}
                  endIcon={<ArrowDropDownIcon />}
                  sx={{ color: "primary", fontWeight: "bold", marginTop: "20px", width: "190px" }}
                >Plantilla de:
          </Button>
          <Menu
            anchorEl={anchorEl2}
            open={Boolean(anchorEl2)}
            onClose={handleClose2}
          >
            <MenuItem onClick={() => excelPlantillaRTS()}>RTS</MenuItem>
            <MenuItem onClick={() => excelPlantillaMicro()}>MICROSIP</MenuItem>
          </Menu>
          {/*
          <Button
            type="button"
            color="inherit"
            onClick={() => handleOpenDialog("/cargarrts")}
            variant="contained"
            startIcon={<FileUploadIcon />}
            sx={{ color: "primary", fontWeight: "bold", marginTop: "20px", width: "190px" }}
          >
            Subir Info RTS
          </Button>
          */}
          <Button
                  type="button"
                  variant="contained"
                  color="inherit"
                  onClick={handleClick}
                  startIcon={<FileUploadIcon />}
                  endIcon={<ArrowDropDownIcon />}
                  sx={{ color: "primary", fontWeight: "bold", marginTop: "20px", width: "190px" }}
                >Subir Info de:
          </Button>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem onClick={() => handleOpenDialog("/cargarrts")}>RTS</MenuItem>
            <MenuItem onClick={() => handleOpenDialog("/cargarmic")}>MICROSIP</MenuItem>
          </Menu>
          <Dialog open={open} onClose={handleCloseDialog} maxWidth="md" fullWidth sx={{
                "& .MuiDialogContent-root": {
                  padding: 0
              }
              }}>
            <DialogContent>
              <iframe
                  title="Página Carga Info RTS"
                  //src={url}
                  src={`${url}?modo=${theme.palette.mode}&e=${id_empresa}`}
                  style={{
                    width: '100%',
                    height: '500px',
                    border: 'none',
                  }}
              />
            </DialogContent>
          </Dialog>
          <Box
            sx={{
              height: 400,
              width: "100%",
              "& .positive": {
                color: colors.blueAccent[700] === "#a4a9fc" ? "green" : "#66BB6A",
              },
              "& .negative": {
                color: colors.blueAccent[700] === "#a4a9fc" ? "red" : "#E86258",
              },
              "& .MuiDataGrid-root": {
                border: "none",
              },
              "& .MuiDataGrid-cell": {
                //backgroundColor: "#66BB6A",
                borderBottom: "none",
              },
              "& .name-column--cell": {
                color: colors.greenAccent[300],
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: colors.blueAccent[700],
                borderBottom: "none",
              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: colors.primary[400],
              },
              "& .MuiDataGrid-footerContainer": {
                borderTop: "none",
                backgroundColor: colors.blueAccent[700],
              },
              "& .MuiCheckbox-root": {
                color: `${colors.greenAccent[200]} !important`,
              },
              "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                color: `${colors.grey[100]} !important`
              }
            }}
          >
            <DataGrid
              loading={loading}
              //rows={informacionTabla}
              rows={informacionTabla.concat(sumRow)}
              columns={columns}
              components={{ Toolbar: GridToolbar }}
              componentsProps={{
                toolbar: {
                    csvOptions : { utf8WithBom: true }
                },
                panel: {
                  sx: {
                    [`& .MuiDataGrid-columnsPanel > div:first-child`]: {
                      display: "none"
                    }
                  }
                }
            }}
            /*
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 5,
                  },
                },
              }}*/
              getRowId={(row) => row.id}
              getCellClassName={(params) => {
                //console.log(params)
                if(params.row.id != '')
                {
                  if (params.field === "1" || params.field === "31" || params.field === "46" || params.field === "61" || params.field === "91" || params.field === "121") 
                  {
                      if (params.field > params.row.dias_credito && params.value > 0) {
                          return "negative"
                      } else if (params.value > 0) {
                          return "positive"
                      } else {
                        return "";
                      }
                  } else if (params.field === "importe_vencido") 
                  {
                      if (params.value > 0) {
                          return "negative"
                      } else {
                          return "positive"
                      }
                  } else {
                    return "";
                  }
                }}}
              //pageSizeOptions={[5]}
              //disableRowSelectionOnClick
              //onRowClick={(params) => {handleRowClick(params)}}
              onCellClick={handleCellClick}
              localeText={esES.components.MuiDataGrid.defaultProps.localeText}
              checkboxSelection
              disableRowSelectionOnClick 
              disableSelectionOnClick
              isRowSelectable={(params) => params.row.dias_credito != "TOTAL"}
              onRowSelectionModelChange={(ids) => {
                const selectedIDs = new Set(ids);
                const selectedRows = informacionTabla.filter((row) =>
                  selectedIDs.has(row.id)
                );
                setSelectedRows(selectedRows);

                var datos = [];
                if(selectedRows.length>0)
                  datos = selectedRows;
                else
                  datos = informacionTabla;

                const newSum1 = datos.reduce((total, row) => total + row['1'], 0);
                const newSum31 = datos.reduce((total, row) => total + row['31'], 0);
                const newSum46 = datos.reduce((total, row) => total + row['46'], 0);
                const newSum61 = datos.reduce((total, row) => total + row['61'], 0);
                const newSum91 = datos.reduce((total, row) => total + row['91'], 0);
                const newSum121 = datos.reduce((total, row) => total + row['121'], 0);
                const newSumIV = datos.reduce((total, row) => total + row.importe_vencido, 0);

                setSum1(newSum1);
                setSum31(newSum31);
                setSum46(newSum46);
                setSum61(newSum61);
                setSum91(newSum91);
                setSum121(newSum121);
                setSumIV(newSumIV);
               
              }}
            />
          </Box>
    </Box>
    )}
    </div>
  );
  
}

export default CarteraVencida;
