import React , { useEffect, useState } from 'react'
import { DataGrid, GridToolbar, esES  } from "@mui/x-data-grid";
import { Box, useTheme,  Button} from "@mui/material";
import { tokens } from "../../theme";
import Header from '../../components/Header'
import api from '../../config/axios';
import * as Yup from 'yup';

// ICONOS
import AddCircleIcon from '@mui/icons-material/AddCircle';

import ModalCreateGM from '../../components/modals/ModalCreateGM'
import ModalEditGM from '../../components/modals/ModalEditGM'

// ===============================================

const RelacionEjecutivoCliente = () => {

    // ===============================================
    // COLORES PARA ESTILOS
    // ===============================================

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    // ===============================================
    // SEGMENTO PARA BUSCAR EMPRESAS
    // ===============================================

    const [data, setData] = useState([]);

    
    const [clientes, setClientes] = useState([]);
    const [ejecutivos, setEjecutivos] = useState([]);

    useEffect(() => {

        window.scrollTo(0, 0);

        obtenerClientes();
        obtenerEjecutivos();
        buscarRelaciones();

    },[])

    const buscarRelaciones = async () => {

        const response = await api.get('get/relaciones');

        if(response.data.success === true && response.data.result !== "Sin resultados"){

            setData(response.data.result);

        }else{

            console.log("ERROR")
            setData([]);

        }

    }

      // ===============================================
  // QUE OBTIENE LOS CLIENTES
  // ===============================================

  const obtenerClientes = async () => {
    const response = await api.get("/get/clientes");

    if (
      response.data.success === true &&
      response.data.result !== "Sin resultados"
    ) {
      setClientes(response.data.result);
    } else {
      console.log("ERROR");

      setClientes([]);
    }
  };

  // ===============================================
  // FUNCION QUE OBTIENE LAS EJECUTIVOS
  // ===============================================

  const obtenerEjecutivos = async () => {
    const response = await api.get("/get/ejecutivos");

    if (
      response.data.success === true &&
      response.data.result !== "Sin resultados"
    ) {
      setEjecutivos(response.data.result);
    } else {
      console.log("ERROR");

      setEjecutivos([]);
    }
  };

    // ===============================================
    // COLUMNAS PARA LA TABLA
    // ===============================================

    const columns = [

        { field: "id", headerName: "No.", flex: 2},
        {
            field: "cliente",
            headerName: "Cliente",
            flex: 2,
            cellClassName: "name-column--cell",
            headerAlign: "center",
            align: "center",
        },
        {
            field: "ejecutivo",
            headerName: "Ejecutivo",
            flex: 2,
            headerAlign: "center",
            align: "center",
        },

    ];

    // ===============================================
    // VALORES PARA SUBMIT DEL FORMULARIO
    // ===============================================

    const [initialFormValues, setInicialFormValues] = useState({

        rel_cliente: '',
        rel_ejecutivo: ''

    })

    // ===============================================
    // FUNCION PARA ABRIR O CERRAR EL MODAL CREARA
    // ===============================================

    const [isOpen, setIsOpen] = useState(false);

    const handleOpenModal = () => {

        setInicialFormValues({

            rel_cliente: '',
            rel_ejecutivo: ''
    
        })

        setIsOpen(true);
    };
    
    const handleCloseModal = () => {
        setIsOpen(false);
    };

    // ===============================================
    // FUNCION PARA ABRIR O CERRAR EL MODAL EDITAR
    // ===============================================

    const [isOpenEdit, setIsOpenEdit] = useState(false);

    const handleOpenModalEdit = () => {
        setIsOpenEdit(true);
    };
    
    const handleCloseModalEdit = () => {
        setIsOpenEdit(false);
    };

    // ===============================================
    // INPUTS PARA ARMAR EL FORMULARIO
    // ===============================================

    const inputForms = [

        {
            componente: "select",
            label: "Cliente",
            name: "rel_cliente",
            options: clientes,
            value: 'id',
            text: 'nombre',
            placeholder: "Seleccione el nombre de cliente",
            md: 6,
            xs: 12
        },
        {
            componente: "select",
            label: "Ejecutivo",
            name: "rel_ejecutivo",
            options: ejecutivos,
            value: 'id',
            text: 'nombre',
            placeholder: "Seleccione el nombre de ejecutivo",
            md: 6,
            xs: 12
        },
    ];

    // ===============================================
    // SECCION VALIDAR SI YA ESTA ASIGNADO EL TRACTOR
    // ===============================================

    const validarCliente = async (id) => {

        const response = await api.get(`get/validar/cliente/asignado/${id}`);

        if(response.data.success === true && response.data.result !== "Sin resultados"){

            if(id === response.data.result[0]['rel_cliente'] || id === "0"){

                return true;

            }else{

                return false;

            }

        }else{

            return true;

        }

    }

    // ===============================================
    // VALIDACIONES PARA EL FORMULARIO
    // ===============================================

    const yupValidation = Yup.object().shape({

        rel_ejecutivo: Yup.string().required('Requerido'),
        rel_cliente: Yup.string().required('Requerido').test('validar-cliente', 'Este cliente ya esta asignado.', async (value) => {
            const isValid = await validarCliente(value)
            return isValid;
        }),
    })

    // ===============================================
    // FUNCION PARA EDITAR REGISTRO
    // ===============================================

    const handleRowClick = (params) => {

        //console.log(params.row)

        setInicialFormValues(params.row);
        handleOpenModalEdit();

    }

    // ===============================================

    return (

        <Box m="20px">

            <Header title="Relación Ejecutivo - Cliente" subtitle="Ejecutivos por Clientes en SIIF" />

            {/* BOTON PARA ABRIR MODAL */}

            <Button variant="contained" 
                sx={{
                    fontWeight:"bold",
                    backgroundColor:colors.blueAccent[700]
                }} 
                startIcon={<AddCircleIcon />}
                onClick={handleOpenModal}
            >
                Agregar Relación
            </Button>

            {/* TABLA  */}

            <Box
                m="40px 0 0 0"
                height="75vh"
                width="100%"
                sx={{
                    "& .MuiDataGrid-root": {
                        border: "none",
                    },
                    "& .MuiDataGrid-cell": {
                        borderBottom: "none",
                    },
                    "& .name-column--cell": {
                        color: colors.greenAccent[300],
                    },
                    "& .MuiDataGrid-columnHeaders": {
                        backgroundColor: colors.blueAccent[700],
                        borderBottom: "none",
                    },
                    "& .MuiDataGrid-virtualScroller": {
                        backgroundColor: colors.primary[400],
                    },
                    "& .MuiDataGrid-footerContainer": {
                        borderTop: "none",
                        backgroundColor: colors.blueAccent[700],
                    },
                    "& .MuiCheckbox-root": {
                        color: `${colors.greenAccent[200]} !important`,
                    },
                    "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                        color: `${colors.grey[100]} !important`
                    }
                    }}>
                <DataGrid 
                    
                    rows={data} 
                    columns={columns} 
                    components={{ Toolbar: GridToolbar}}
                    onRowClick={handleRowClick}
                    componentsProps={{
                    toolbar: {
                        csvOptions : { utf8WithBom: true }
                    },
                    }}
                    getRowId={(row) => row.id}
                    disableRowSelectionOnClick
                    localeText={esES.components.MuiDataGrid.defaultProps.localeText}



                />
            </Box>

            {/* MODAL PARA CREAR EMPRESAS */}

            <ModalCreateGM
                modalTitle="Crear Relación"
                swalTitle="¡Se ha guardado la relación!"
                url="create/relacion"
                open={isOpen} 
                onClose={handleCloseModal} 
                size='70'
                initialValues={initialFormValues}
                inputForms={inputForms}
                yupValidation={yupValidation}
                refresh={buscarRelaciones}
            />

            {/* MODAL PARA EDITAR EMPRESAS */}

            <ModalEditGM
                modalTitle="Editar Relación"
                swalTitle="¡Se ha editado el relación!"
                url="update/relacion"
                urlDelete="delete/relacion/"
                open={isOpenEdit} 
                onClose={handleCloseModalEdit} 
                openModal={handleOpenModalEdit}
                size='70'
                initialValues={initialFormValues}
                inputForms={inputForms}
                yupValidation={yupValidation}
                refresh={buscarRelaciones}
            />

        </Box>

    )

}

export default RelacionEjecutivoCliente