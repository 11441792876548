import React , { useEffect, useState } from 'react'
import { DataGrid, GridToolbar, esES  } from "@mui/x-data-grid";
import { Box, useTheme,  Button} from "@mui/material";
import { tokens } from "../../theme";
import Header from '../../components/Header'
import api from '../../config/axios';
import * as Yup from 'yup';

// ICONOS
import AddCircleIcon from '@mui/icons-material/AddCircle';

import ModalCreateGM from '../../components/modals/ModalCreateGM'
import ModalEditGM from '../../components/modals/ModalEditGM'

// ===============================================

const Clientes = () => {

    // ===============================================
    // COLORES PARA ESTILOS
    // ===============================================

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    // ===============================================
    // SEGMENTO PARA BUSCAR EMPRESAS
    // ===============================================

    const [data, setData] = useState([]);

    useEffect(() => {

        window.scrollTo(0, 0);

        buscarClientes();

    },[])

    const buscarClientes = async () => {

        const response = await api.get('get/clientes');

        if(response.data.success === true && response.data.result !== "Sin resultados"){

            setData(response.data.result);

        }else{

            console.log("ERROR")
            setData([]);

        }

    }

    // ===============================================
    // COLUMNAS PARA LA TABLA
    // ===============================================

    const columns = [

        { field: "id", headerName: "No.", flex: 2},
        {
            field: "nombre",
            headerName: "Cliente",
            flex: 2,
            cellClassName: "name-column--cell",
            headerAlign: "center",
            align: "center",
        },
        {
            field: "dias_credito",
            headerName: "Días de Crédito",
            flex: 2,
            type: "number",
            headerAlign: "center",
            align: "center",
        },
        /*
        {
            field: "rfc",
            headerName: "RFC",
            flex: 2,
            headerAlign: "center",
            align: "center",
        },
        {
            field: "activo",
            headerName: "Estado",
            flex: 2,
            headerAlign: "center",
            align: "center",
            renderCell: (params) => 
                params.value === 1 
                ?
                <Button variant="contained" color="success" sx={{color: "white"}}><b>Activo</b></Button>
                :
                <Button variant="contained" color="error"><b>Inactivo</b></Button>
        },
        */

    ];

    // ===============================================
    // VALORES PARA SUBMIT DEL FORMULARIO
    // ===============================================

    const [initialFormValues, setInicialFormValues] = useState({

        nombre: '',
        dias_credito: ''

    })

    // ===============================================
    // FUNCION PARA ABRIR O CERRAR EL MODAL CREARA
    // ===============================================

    const [isOpen, setIsOpen] = useState(false);

    const handleOpenModal = () => {

        setInicialFormValues({

            nombre: '',
            dias_credito: ''
    
        })

        setIsOpen(true);
    };
    
    const handleCloseModal = () => {
        setIsOpen(false);
    };

    // ===============================================
    // FUNCION PARA ABRIR O CERRAR EL MODAL EDITAR
    // ===============================================

    const [isOpenEdit, setIsOpenEdit] = useState(false);

    const handleOpenModalEdit = () => {
        setIsOpenEdit(true);
    };
    
    const handleCloseModalEdit = () => {
        setIsOpenEdit(false);
    };

    // ===============================================
    // INPUTS PARA ARMAR EL FORMULARIO
    // ===============================================

    const inputForms = [

        {
            componente: "input",
            label: "Nombre",
            name: "nombre",
            placeholder: "Ingrese el nombre de cliente",
            md: 12,
            xs: 12
        },
        {
            componente: "number",
            label: "Días de Crédito",
            name: "dias_credito",
            placeholder: "Ingrese los días de crédito",
            md: 12,
            xs: 12
        },
    ];

    // ===============================================
    // VALIDACIONES PARA EL FORMULARIO
    // ===============================================

    const yupValidation = Yup.object().shape({

        nombre: Yup.string().required('Requerido'),
        dias_credito: Yup.number().required('Requerido').min(0, '¡Los días no pueden ser negativos!')
    })

    // ===============================================
    // FUNCION PARA EDITAR REGISTRO
    // ===============================================

    const handleRowClick = (params) => {

        setInicialFormValues(params.row);
        handleOpenModalEdit();

    }

    // ===============================================

    return (

        <Box m="20px">

            <Header title="Catálogo de Clientes" subtitle="Clientes Cargados en SIIF" />

            {/* BOTON PARA ABRIR MODAL */}

            <Button variant="contained" 
                sx={{
                    fontWeight:"bold",
                    backgroundColor:colors.blueAccent[700]
                }} 
                startIcon={<AddCircleIcon />}
                onClick={handleOpenModal}
            >
                Agregar Cliente
            </Button>

            {/* TABLA  */}

            <Box
                m="40px 0 0 0"
                height="75vh"
                width="100%"
                sx={{
                    "& .MuiDataGrid-root": {
                        border: "none",
                    },
                    "& .MuiDataGrid-cell": {
                        borderBottom: "none",
                    },
                    "& .name-column--cell": {
                        color: colors.greenAccent[300],
                    },
                    "& .MuiDataGrid-columnHeaders": {
                        backgroundColor: colors.blueAccent[700],
                        borderBottom: "none",
                    },
                    "& .MuiDataGrid-virtualScroller": {
                        backgroundColor: colors.primary[400],
                    },
                    "& .MuiDataGrid-footerContainer": {
                        borderTop: "none",
                        backgroundColor: colors.blueAccent[700],
                    },
                    "& .MuiCheckbox-root": {
                        color: `${colors.greenAccent[200]} !important`,
                    },
                    "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                        color: `${colors.grey[100]} !important`
                    }
                    }}>
                <DataGrid 
                    
                    rows={data} 
                    columns={columns} 
                    components={{ Toolbar: GridToolbar}}
                    onRowClick={handleRowClick}
                    componentsProps={{
                    toolbar: {
                        csvOptions : { utf8WithBom: true }
                    },
                    }}
                    getRowId={(row) => row.id}
                    disableRowSelectionOnClick
                    localeText={esES.components.MuiDataGrid.defaultProps.localeText}



                />
            </Box>

            {/* MODAL PARA CREAR EMPRESAS */}

            <ModalCreateGM
                modalTitle="Crear Cliente"
                swalTitle="¡Se ha guardado el cliente!"
                url="create/cliente"
                open={isOpen} 
                onClose={handleCloseModal} 
                size='70'
                initialValues={initialFormValues}
                inputForms={inputForms}
                yupValidation={yupValidation}
                refresh={buscarClientes}
            />

            {/* MODAL PARA EDITAR EMPRESAS */}

            <ModalEditGM
                modalTitle="Editar Cliente"
                swalTitle="¡Se ha editado el cliente!"
                url="update/cliente"
                urlDelete="delete/cliente/"
                open={isOpenEdit} 
                onClose={handleCloseModalEdit} 
                openModal={handleOpenModalEdit}
                size='70'
                initialValues={initialFormValues}
                inputForms={inputForms}
                yupValidation={yupValidation}
                refresh={buscarClientes}
            />

        </Box>

    )

}

export default Clientes