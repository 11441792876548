import React, { useEffect, useState } from 'react';
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { Box, useTheme, Button } from "@mui/material";
import { tokens } from "../../theme";
import Header from '../../components/Header';
import ModalCrearEmpresa from './ModalCrearEmpresa';
import api from '../../config/axios';
import * as Yup from 'yup';

import ModalCreateGM from '../../components/modals/ModalCreateGM';
import ModalEditGM from '../../components/modals/ModalEditGM';

// ICONOS
import AddCircleIcon from '@mui/icons-material/AddCircle';

const Empresas = () => {

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    // ===============================================
    // BUSCAR EMPRESAS
    // ===============================================

    const [empresas, setEmpresas] = useState([]);

    useEffect(() => {

        buscarEmpresas();

    },[])

    const buscarEmpresas = async () => {

        const response = await api.get('get/empresas');

        if(response.data.success === true && response.data.result !== "Sin resultados"){

            console.log(response.data.result);
            setEmpresas(response.data.result);

        }else{

            console.log("ERROR")
            setEmpresas([]);

        }

    }

    const columns = [
        { field: "id", headerName: "ID", flex: 0.5 },
        {
          field: "nombre_comercial",
          headerName: "Nombre Comercial",
          flex: 2,
          cellClassName: "name-column--cell",
        },
        {
          field: "razon_social",
          headerName: "Razon Social",
          flex: 2,
          headerAlign: "left",
          align: "left",
        },
    ];

    const handleRowClick = (params) => {

        console.log(params.row);
        setInicialFormValues(params.row);
        handleOpenModalEdit();

    }

    // ===============================================
    // VALORES PARA SUBMIT DEL FORMULARIO
    // ===============================================

    const [initialFormValues, setInicialFormValues] = useState({

        id: '',
        nombreEmpresa: '',
        razonSocial: '',
        tieneSaldo: ''

    })

    // ===============================================
    // FUNCION PARA ABRIR O CERRAR EL MODAL CREARA
    // ===============================================

    const [isOpen, setIsOpen] = useState(false);

    const handleOpenModal = () => {

        setInicialFormValues({

            id: '',
            nombreEmpresa: '',
            razonSocial: '',
            tieneSaldo: ''
    
        })

        setIsOpen(true);
    };
    
    const handleCloseModal = () => {
        setIsOpen(false);
    };

    // ===============================================
    // FUNCION PARA ABRIR O CERRAR EL MODAL EDITAR
    // ===============================================

    const [isOpenEdit, setIsOpenEdit] = useState(false);

    const handleOpenModalEdit = () => {
        setIsOpenEdit(true);
    };
    
    const handleCloseModalEdit = () => {
        setIsOpenEdit(false);
    };

    // ===============================================
    // ARREGLO QUE DEFINE LOS INPUTS DEL FORM
    // ===============================================

    const arrayTieneSaldo = [
        {
            value: 1,
            text: "Si"
        },
        {
            value: 0,
            text: "No"
        }
        
    ];
    const inputForms = [

        {
            componente: "input",
            label: "Nombre de la empresa",
            name: "nombreEmpresa",
            placeholder: "Ingrese el nombre de la empresa",
            md: 12,
            xs: 12
        },
        {
            componente: "input",
            label: "Razon Cocial",
            name: "razonSocial",
            placeholder: "Ingrese la razón social",
            md: 12,
            xs: 12
        },
        {
            componente: "select",
            label: "Tiene saldo?",
            name: "tieneSaldo",
            options: arrayTieneSaldo,
            value: 'value',
            text: 'text',
            md: 12,
            xs: 12
        },

    ];

    // ===============================================
    // VALIDACIONES PARA EL FORMULARIO
    // ===============================================

    const yupValidation = Yup.object().shape({

        nombreEmpresa: Yup.string().required('Requerido'),
        razonSocial: Yup.string().required('Requerido'),
        tieneSaldo: Yup.string().required('Requerido')

    })

    return (

        <Box m="20px">

            <Header title="Empresas" subtitle="Empresas EBTSuite" />

            <Button variant="contained" 
                sx={{
                        fontWeight:"bold",
                        backgroundColor:colors.blueAccent[700]
                }} 
                startIcon={<AddCircleIcon />}
                onClick={handleOpenModal}
            >
                Agregar Empresa
            </Button>

            <Box
                m="40px 0 0 0"
                height="75vh"
                width="100%"
                sx={{
                "& .MuiDataGrid-root": {
                    border: "none",
                },
                "& .MuiDataGrid-cell": {
                    borderBottom: "none",
                },
                "& .name-column--cell": {
                    color: colors.greenAccent[300],
                },
                "& .MuiDataGrid-columnHeaders": {
                    backgroundColor: colors.blueAccent[700],
                    borderBottom: "none",
                },
                "& .MuiDataGrid-virtualScroller": {
                    backgroundColor: colors.primary[400],
                },
                "& .MuiDataGrid-footerContainer": {
                    borderTop: "none",
                    backgroundColor: colors.blueAccent[700],
                },
                "& .MuiCheckbox-root": {
                    color: `${colors.greenAccent[200]} !important`,
                },
                "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                    color: `${colors.grey[100]} !important`
                }
                }}
            >
                <DataGrid 
                    
                    rows={empresas} 
                    columns={columns} 
                    components={{ Toolbar: GridToolbar}}
                    onRowClick={handleRowClick}
                />
            </Box>

            {/* MODAL PARA CREAR EMPRESAS */}

            <ModalCreateGM
                modalTitle="Crear Empresa"
                swalTitle="¡Se ha guardado la empresa!"
                url="create/empresa"
                open={isOpen} 
                onClose={handleCloseModal} 
                size='70'
                initialValues={initialFormValues}
                inputForms={inputForms}
                yupValidation={yupValidation}
                refresh={buscarEmpresas}
            />

            {/* MODAL PARA EDITAR EMPRESAS */}

            <ModalEditGM
                modalTitle="Editar Cliente"
                swalTitle="¡Se ha editado el cliente!"
                url="update/empresa"
                urlDelete="delete/empresa/"
                open={isOpenEdit} 
                onClose={handleCloseModalEdit} 
                openModal={handleOpenModalEdit}
                size='70'
                initialValues={initialFormValues}
                inputForms={inputForms}
                yupValidation={yupValidation}
                refresh={buscarEmpresas}
            />

        </Box>
    )

}

export default Empresas