import { baseURL } from "./url";
import axios from "axios";
import Cookies from "js-cookie";

import { logOutFunction } from "./functions";

const api = axios.create({
    baseURL: baseURL+"api/",
});

api.interceptors.request.use(
    (config) => {
        const token = Cookies.get('token');
        if(token){
            config.headers['access-token'] = token;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
)


api.interceptors.response.use(
    (response) => {
        // Aquí puedes acceder al objeto de respuesta y verificar el valor de 'success'
        if (response.data && response.data.success === false) {
            // Realiza las acciones necesarias en caso de que el token no sea válido
            logOutFunction();
        }
        return response;
    },
    (error) => {
        return Promise.reject(error);
    }
);

export default api;
