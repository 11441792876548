import { useTheme } from "@emotion/react";
import { Button, Grid, Modal, Paper, TextField, Typography } from "@mui/material";
import { useState } from "react";
import { tokens } from "../../../theme";
import { useEffect } from "react";
// import MySelect from "../../../../components/inputs/MySelect";
import { DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// ICONOS
import CloseIcon from '@mui/icons-material/Close';
import { DoneAll } from "@mui/icons-material";

import dayjs from 'dayjs';
import api from "../../../config/axios";
import Swal from "sweetalert2";
import { endOfDay } from "date-fns";
import Cookies from "js-cookie";


const ModalConciliacion = (props) => {
	const {open, onClose, data, refresh } = props;

	const theme  = useTheme();
    const colors = tokens(theme.palette.mode);
    
    const [display, setDisplay] = useState('flex');
    // const [periodos, setPeriodos] = useState([
	// 	{id: 1, descripcion: 'Diario'},
	// 	{id: 2, descripcion: 'Personalizado'},
	// ]);
	const [inicioMes, setInicioMes] = useState(dayjs().startOf('month'));
	const [finMes, setFinMes] = useState(dayjs().endOf('month'));

	// const [fechaInicio, setFechaInicio] = useState(dayjs().subtract(1, 'day').format('YYYY/MM/DD'));
	// const [fechaFin, setFechaFin] = useState(dayjs().format('YYYY/MM/DD'));
	const [fechaInicio, setFechaInicio] = useState(dayjs().startOf('month').format('YYYY/MM/DD'));
	const [fechaFin, setFechaFin] =useState(dayjs().endOf('month').format('YYYY/MM/DD'));


	const [showDatePicker, setShowDatePicker] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0);
    },[]);

	const conciliar = async () => {
		// setLoading(true);
        const startDate = new Date(fechaInicio);
        const endDate = new Date(fechaFin);
        if (startDate.getFullYear() !== endDate.getFullYear() || startDate.getMonth() !== endDate.getMonth()) {
            // Lanza un error o maneja la situación como prefieras
            setDisplay('none');
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Las fechas deben ser del mismo mes y mismo año'
            }).then((result) => {
                if (result.isConfirmed) {
					onClose();
					setDisplay('block');
				}
            });
        }
        const params = {
            fechaInicio: fechaInicio,
            fechaFin: fechaFin,
            user: Cookies.get('user_id'),
            data: [...data.data]
        }
		const response = await api.post("/comparativa/conciliar", {...params});
        if (response.data.success === true) {
            onClose();
            Swal.fire({
                icon: response.data.icon,
                title: response.data.text
            });
            refresh();
			// setData(response.data.result.data);
            // setShowButton(response.data.result.needsAuth)
		}
		// setLoading(false);
	};
	return(
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
			style={{
				display: display,
				alignItems: "center",
				justifyContent: "center",
			}}
        >
            <Paper sx={{
				width: "40%",
				p: 3,
				bgcolor: colors.blueAccent[900],
				borderRadius: "10px",
			}}
			>
                <Typography id="modal-modal-title" variant="h3" align="center">Conciliar por fechas</Typography>				
                <Grid container spacing={2} paddingBottom={"0.1rem"} sx={{mt:'10px'}}>
                    {/* TIPO */}				
                    <Grid item md={6} xs={6} xl={6}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                defaultValue={inicioMes}
                                sx={{width: '100%', backgroundColor: colors.greenAccent[700]}}
                                views={['year', 'month', 'day']}
                                renderInput={(props) => <TextField {...props} fullWidth label="Fecha inicial" />}
                                onChange={(value) => setFechaInicio(dayjs(value).format('YYYY/MM/DD'))}
                            />
                        </LocalizationProvider>
                    </Grid>
                    <Grid item md={6} xs={6} xl={6}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                sx={{width: '100%', backgroundColor: colors.redAccent[700]}}
                                defaultValue={finMes}
                                views={['year', 'month', 'day']}
                                renderInput={(props) => <TextField {...props} fullWidth label="Fecha final" />}
                                onChange={(value) => setFechaFin(dayjs(value).format('YYYY/MM/DD'))}
                            />
                        </LocalizationProvider>
                    </Grid>
                    {/* SUBMIT */}
                    <Grid item md={6} xs={6}>
                        <Button variant='contained' sx={{color: "white",width: "100%",borderRadius: "8px" ,marginRight: '10px'}} onClick={onClose} startIcon={<CloseIcon />}>
                            Cancelar
                        </Button>
                    </Grid>
                    <Grid item md={6} xs={6}>
                        <Button variant='contained' color='success' sx={{color: "white",width: "100%",borderRadius: "8px" ,marginRight: '10px'}} onClick={conciliar} startIcon={<DoneAll />}>
                            Conciliar
                        </Button>
                    </Grid>
                </Grid>
            </Paper>
        </Modal>
    )
};
export default ModalConciliacion;