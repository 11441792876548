import React, { useState, useEffect } from "react";
import { Box, Button, Typography, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import Swal from "sweetalert2";
import Header from "../../components/Header";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider, dateCalendarClasses } from "@mui/x-date-pickers";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DataGrid, GridToolbar, esES  } from "@mui/x-data-grid";

// ICONOS
import ManageSearchIcon from '@mui/icons-material/ManageSearch';

//COMPONENTES
import api from "../../config/axios";
import { InputLabel } from '@mui/material';

import 'dayjs/locale/es-mx';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';

import GraficaPie from "../../components/charts/GraficaBarra";

function GraficaComparativa() {
  // ===============================================
  // COLORES PARA ESTILOS
  // ===============================================
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const navigate = useNavigate();

  var Locale = "es-mx";

  const location = useLocation();
  const { info } = location.state || {};

  const { empresa } = useParams();



  // ===============================================
  // USES STATES
  // ===============================================

  const [selectedDate, setSelectedDate] = useState(null);
  const [fecha, setFecha] = useState(null);

  const handleSubmit = async () => {
    if (selectedDate && selectedDate.$d!='Invalid Date') {
      // Aquí puedes utilizar la fecha seleccionada como necesites
      let fecha = selectedDate.$d.toJSON().substring(0, 10);
      
      buscarComparativa(fecha, empresa);
      /////////////
        fecha = selectedDate.$d;
        let mes = (fecha.getMonth() + 1).toString().padStart(2, '0'); 
        let dia = fecha.getDate().toString().padStart(2, '0');
        let anio = fecha.getFullYear();

        let fechaFormateada = `${mes}/${dia}/${anio}`;

        console.log(fechaFormateada);

        setFecha(fechaFormateada);
      /////////////
    } else {
      console.log("Ninguna fecha seleccionada");
      Swal.fire({
        icon: "error",
        title: "¡Seleccione una fecha, por favor!",
      });
    }
  };

  // ===============================================
  // USEEFECT PARA DETECTAR EL CAMBIO DE FECHA
  // ===============================================
  useEffect(() => {

    window.scrollTo(0, 0);

    /*
    if(info)
    {
        const objeto = JSON.parse(info);
        let fecha = objeto.fecha.substring(0, 10);
    
        let fecharecibida = dayjs(fecha);
        setSelectedDate(fecharecibida);

        let fecha2 = fecharecibida.$d.toJSON().substring(0, 10);
        buscarComparativa(fecha2);
    }  
    */  

  }, []);

  // ===============================================
  // FUNCION QUE SETEA LA FECHA
  // ===============================================
  const handleChange = (date) => {
    setSelectedDate(date);
    setInformacionTabla([]);
  };

  // ===============================================
  // SEGMENTO PARA LLENAR LA TABLA
  // ===============================================

  const [informacionTabla, setInformacionTabla] = useState([]);

  const buscarComparativa = async (fecha, empresa) => {
    const response = await api.get(`get/graficacomparativa/${fecha}/${empresa}`);

    if (
      response.data.success === true &&
      response.data.result !== "Sin resultados"
    ) {
        //console.log(response.data.result)
      setInformacionTabla(response.data.result);
    } else {
      console.log("ERROR");
      setInformacionTabla([]);
    }
  };

  
  return (
    <Box m="20px">
      <Header title="Gráfica Comparativa" subtitle="Información de estado de cuenta de los clientes por empresa." />
      <form /* onSubmit={handleSubmit} */>
            <InputLabel id="demo-simple-select-label">Seleccione una fecha:</InputLabel>
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={Locale}>
              <DatePicker
                value={selectedDate}
                onChange={handleChange}
                format="DD/MM/YYYY"
              />
            </LocalizationProvider>
            <Button
              type="button"
              color="inherit"
              onClick={() => handleSubmit()}
              variant="contained"
              startIcon={<ManageSearchIcon />}
              sx={{ color: "primary", fontWeight: "bold", marginTop: "20px", marginLeft: "20px", width: "190px" }}
            >
              Buscar
            </Button>
      </form>
          <Box
          gridColumn="span 4"
          gridRow="span 2"
          backgroundColor={colors.primary[400]}
        >
          <Typography
            variant="h5"
            fontWeight="600"
            sx={{ padding: "30px 30px 0 30px" }}
          >
            RTS al {fecha}
          </Typography>
          <Box height="250px" mt="-20px">
            <GraficaPie data={informacionTabla} />
          </Box>
        </Box>
    </Box>
  );
}

export default GraficaComparativa;
