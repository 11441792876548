import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useEffect, useRef, useState } from 'react';
import { Box, Button, Grid, TextField, Typography } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import MyNumberInput from '../../../components/inputs/MyNumberInput';
import { useTheme } from '@emotion/react';
import { tokens } from '../../../theme';



const Consolidado = () => {
	
	const theme = useTheme();
	const colors = tokens(theme.palette.mode);

	const __getLocalStorage = (key) => {
        return JSON.parse(localStorage.getItem(`${key}_pnl`));
    }
	const [pnl_ebt, setPnlEbt] = useState(__getLocalStorage('123145830174682'));
	const [pnl_ebl, setPnlEbl] = useState(__getLocalStorage('1355379100'));
	const [pnl_ebf, setPnlEbf] = useState(__getLocalStorage('9130349411431146'));

	const [depreciacion, setDepreciacion] = useState(localStorage.getItem('depreciacion') || 0);
	const [principal, setPrincipal] = useState(localStorage.getItem('principal') || 0);

	const [rows, setRows] = useState([]);
	const [loaded, setLoaded] = useState(false);

	useEffect(() => {
		if (!loaded) {
			setPnlEbt(__getLocalStorage('123145830174682')); // 123145830174682
			setPnlEbl(__getLocalStorage('1355379100')); //9130349411431146
			setPnlEbf(__getLocalStorage('9130349411431146')); //1355379100
			// setLoaded(true);
		}
	}, []);

	useEffect(() => {
		if(!loaded && pnl_ebt.length > 0 && pnl_ebl.length > 0 && pnl_ebf.length > 0) crearConsolidado();
	}, [pnl_ebt, pnl_ebl, pnl_ebf, principal, depreciacion]);
	const __buscarClave = (item, name) => {
		if(item.name === name) return item.amount_number;
		if(item.children){
			for(let i = 0; i < item.children.length; i++){
				const result = __buscarClave(item.children[i], name);
				if(result !== null) return result;
			}
		}
		return null;
	}
	
	const buscarClave = (keys, array) => {
		let results = {};
		keys.forEach((key) => {
			const result = array.find((item) => __buscarClave(item, key) !== null);
			if (result) {
				results[key] = __buscarClave(result, key);
			}
		});
		return results;
	}
	const crearConsolidado = () => {
		const income_ebt 	= pnl_ebt.filter((item) => item.name === 'Income');
		const income_ebl 	= pnl_ebl.filter((item) => item.name === 'Income');
		const income_ebf 	= pnl_ebf.filter((item) => item.name === 'Income');

		const cogs_ebt 		= pnl_ebt.filter((item) => item.name === 'Cost of Goods Sold');
		const cogs_ebf 		= pnl_ebf.filter((item) => item.name === 'Cost of Goods Sold');

		const expenses_ebt 	= pnl_ebt.filter((item) => item.name === 'Expenses');
		const expenses_ebl 	= pnl_ebl.filter((item) => item.name === 'Expenses');
		const expenses_ebf 	= pnl_ebf.filter((item) => item.name === 'Expenses');
		// INCOME
		const keys_inc_ebt = ['Income','4850-00 QUICK PAY SERVICE FEE', '4000-60 Lumper fee', '4000-40 Brokered US'];
		const keys_inc_ebf = ['4001 Income', '4003 Quick Pay Fee'];
		const keys_inc_ebl = ['SERVICES'];
		const results_inc_ebt = buscarClave(keys_inc_ebt, income_ebt);
		const results_inc_ebf = buscarClave(keys_inc_ebf, income_ebf);
		const results_inc_ebl = buscarClave(keys_inc_ebl, income_ebl);
		// COGS
		const keys_cogs_ebt = ['Cost of Goods Sold','5000-00 US CARRIER EXPENSE'];
		const results_cogs_ebt = buscarClave(keys_cogs_ebt, cogs_ebt);
		// EXPENSES
		const keys_expenses_ebt = ['Expenses','6030-10 EBL Truck Lease'];
		const results_expenses_ebt = buscarClave(keys_expenses_ebt, expenses_ebt);
		const keys_expenses_ebf = ['Expenses', '5000-02 Rent & Lease'];
		const results_expenses_ebf = buscarClave(keys_expenses_ebf, expenses_ebf);
		const keys_expenses_ebl = ['Expenses', 'Interest Paid'];
		const results_expenses_ebl = buscarClave(keys_expenses_ebl, expenses_ebl);
		// console.log(results_inc_ebt, results_inc_ebf, results_inc_ebl);
		// Total es : TotalIncome - 4850 Q PAY FEE - 4000-60 LUMPER - 4000-40 BROKERED US
		const total_income_ebt = results_inc_ebt['Income'] - results_inc_ebt['4850-00 QUICK PAY SERVICE FEE'] - results_inc_ebt['4000-60 Lumper fee'] - results_inc_ebt['4000-40 Brokered US'];
		// const total_income_ebt  = income_ebt.amount_number - income_ebt[1].children[0].children[0].amount_number - income_ebt[1].children[0].children[2].amount_number - income_ebt[1].children[1].amount_number;
		// Total es : 4001 Income + 4003 Quickpay
		const total_income_ebf  = results_inc_ebf['4001 Income'] + results_inc_ebf['4003 Quick Pay Fee'];
		// Total es : SERVICES - 90000 
		const total_income_ebl  = results_inc_ebl['SERVICES'] - 90000;

		// Suma de los totales
		const total_income = parseFloat(total_income_ebt + total_income_ebl + total_income_ebf);
		// Total es : TCOGS - 5000-00 US CARRIER EXPENSE
		const total_cogs_ebt = parseFloat(results_cogs_ebt['Cost of Goods Sold'] - results_cogs_ebt['5000-00 US CARRIER EXPENSE']);
		const total_cogs     = parseFloat(total_cogs_ebt + cogs_ebf[0].amount_number);

		const total_expenses_ebt = parseFloat(results_expenses_ebt['Expenses'] - results_expenses_ebt['6030-10 EBL Truck Lease']);
		const total_expenses_ebf = parseFloat(results_expenses_ebf['Expenses'] - results_expenses_ebf['5000-02 Rent & Lease']);
		const total_expenses_ebl = parseFloat(results_expenses_ebl['Expenses']);

		const total_expenses = parseFloat(total_expenses_ebt + total_expenses_ebf + total_expenses_ebl);
		// const total_expenses_ebt = expenses_ebt[3].amount_number - expenses_ebt[2].children[6].amount_number;
		// const total_expenses_ebf = expenses_ebf[3].amount_number - ;
		// const total_income = parseFloat(pnl_ebt[0].amount) + parseFloat(pnl_ebl[0].amount) + parseFloat(pnl_ebf[0].amount);
		const gross_profit = parseFloat(total_income - total_cogs);
		setRows([
			{ name: 'Income', amount: total_income },
			{ name: 'Cost of Goods Sold', amount: total_cogs },
			{ name: 'Gross Profit', amount: gross_profit },
			{ name: 'Expenses', amount: total_expenses },
			{ name: 'Net Income', amount: parseFloat(gross_profit - total_expenses) },
			{ name: 'Principal', amount: (localStorage.getItem('principal') || 0 ) - parseFloat(results_expenses_ebl['Interest Paid'])},
			{ name: 'Depreciacion', amount: localStorage.getItem('depreciacion') || 0 },
			{ name: 'Cash flow', amount: parseFloat((gross_profit - total_expenses) - (localStorage.getItem('principal') || 0) - (localStorage.getItem('depreciacion') || 0))}

			// { name: 'Net Income %', amount: parseFloat(((gross_profit - total_expenses) / total_income) * 100)}
		]);
		setLoaded(true);
	}
	const formatCurrency = (value) => {
		const formatter = new Intl.NumberFormat("en-US", {
			style: "currency",
			currency: "USD",
			minimumFractionDigits: 2,
			maximumFractionDigits: 2,
		});
		return formatter.format(value);
	};
	const Row = ({ name, amount }) => {
		return (
			<TableRow>
				<TableCell component="th" scope="row" sx={{ fontWeight: 'bold', fontSize:'20px' }}>{`${name}:`}</TableCell>
				<TableCell align="left" sx={{fontSize:'20px'}}>{formatCurrency(parseFloat(amount))}</TableCell>
			</TableRow>
		);
	}
	const setValorFijo = (e) => {
		const key = e.target.id;
		const value = e.target.value;
		localStorage.setItem(key, value);
	}
	const ComponenteValoresFijos = ({id, label}) => {
		return (
			<>
				<Typography variant="h5" gutterBottom component="div" align="left">{label}</Typography>
				<TextField
				    id={id}
					type='number'
					hiddenLabel
					defaultValue={localStorage.getItem(id) || 0}
					sx={{ marginBottom: '20px' }}
					inputProps={{ style: { fontSize: '20px' } }} // Aumenta el tamaño de la fuente aquí
					onChange={setValorFijo}
					InputProps={{
						startAdornment: <InputAdornment position="start">$</InputAdornment>,
					}}
				/>
			</>
		);
	}
	return (
		<Box m="20px">
			<Grid container spacing={2}>
				<Grid item xs={12} md={6}>
					<TableContainer>
						<Table sx={{ minWidth: 650, width: '30%' }} aria-label="simple table">
							<TableBody>
								{
									rows && rows.map((row, index) => (
										<Row key={index} name={row.name} amount={row.amount} />
									))
								}
							</TableBody>
						</Table>
					</TableContainer>
				</Grid>
				<Grid item xs={12} md={6}>
					<Box sx={{ width: '100%', height: 300 }}>
						<ComponenteValoresFijos id="depreciacion" label="Depreciacion" />
						<ComponenteValoresFijos id="principal" label="Principal" />
						<Box >
							<Button variant="contained" sx={{backgroundColor:colors.blueAccent[600]}} onClick={crearConsolidado}>Calcular</Button>
						</Box>
					</Box>
				</Grid>
			</Grid>
		</Box>
	);
}
export default Consolidado;