// import React, { useEffect, useState } from "react";
// import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
// import ChevronRightIcon from "@mui/icons-material/ChevronRight";
// import { TreeView } from "@mui/x-tree-view/TreeView";
// import { TreeItem } from "@mui/x-tree-view/TreeItem";
// import { Box, Typography } from "@mui/material";


// const renderTree = (nodes,i, expanded, handleChange) => (
// 	<TreeItem
// 		key={(nodes.id || nodes[i]?.id)}
// 		nodeId={(nodes.id || nodes[i]?.id)}
// 		label={
// 			<Box display="flex" justifyContent="space-between" width="100%">
// 				<Typography variant="h4">{(nodes.name || nodes[i]?.name)}</Typography>
// 				{!expanded.includes(nodes.id) && (
//                     <Typography variant="h4" fontWeight="bold">{nodes.amount}</Typography>
//                 )}
// 			</Box>
// 		}
// 		onLabelClick={handleChange}
// 	>
// 		{Array.isArray(nodes.children) ? nodes.children.map((node) => renderTree(node)) : null}
// 	</TreeItem>
// );

// function MyTreeview(props) {
// 	const { data } = props;
// 	const [expanded, setExpanded] = useState([]);
// 	const handleChange = (event, nodes) => {
// 		setExpanded(nodes);
// 	};
// 	return (
// 		<TreeView
// 			defaultCollapseIcon={<ExpandMoreIcon />}
// 			defaultExpandIcon={<ChevronRightIcon />}
// 		>
// 			{data && data.map((item, index) => renderTree(item, index, expanded, handleChange))}
// 		</TreeView>
// 	);
// }

// export default MyTreeview;

import React, { useEffect, useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { TreeView } from "@mui/x-tree-view/TreeView";
import { TreeItem } from "@mui/x-tree-view/TreeItem";
import { Box, Typography } from "@mui/material";

const renderTree = (nodes, expanded, handleChange) => (
    <TreeItem
        key={nodes.id}
        nodeId={nodes.id}
        label={
            <Box display="flex" justifyContent="space-between" width="100%">
                <Typography variant="h4">{nodes.name}</Typography>
                {!expanded.includes(nodes.id) && (
                    <Typography variant="h4" fontWeight="bold">{nodes.amount}</Typography>
                )}
            </Box>
        }
        onLabelClick={handleChange}
    >
        {Array.isArray(nodes.children) ? nodes.children.map((node) => renderTree(node, expanded, handleChange)) : null}
    </TreeItem>
);

function MyTreeview(props) {
    const { data } = props;
    const [expanded, setExpanded] = useState([]);
    const handleChange = (event, nodes) => {
        setExpanded(nodes);
    };
    return (
        <TreeView
            defaultCollapseIcon={<ExpandMoreIcon />}
            defaultExpandIcon={<ChevronRightIcon />}
            expanded={expanded}
            onNodeToggle={handleChange}
        >
            {data && data.map((item) => renderTree(item, expanded, handleChange))}
        </TreeView>
    );
}

export default MyTreeview;
