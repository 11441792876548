import AnalyticsIcon from "@mui/icons-material/Analytics";
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import ApartmentIcon from '@mui/icons-material/Apartment';
import GroupIcon from '@mui/icons-material/Group';
import BadgeIcon from '@mui/icons-material/Badge';
import ConnectWithoutContactIcon from '@mui/icons-material/ConnectWithoutContact';
import TocIcon from '@mui/icons-material/Toc';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import DashboardIcon from '@mui/icons-material/Dashboard';
import HttpsIcon from '@mui/icons-material/Https';
import ReceiptIcon from '@mui/icons-material/Receipt';
import JoinInnerIcon from '@mui/icons-material/JoinInner';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
// import QuickbooksIcon from '../assets/img/quickbooks.svg'

/*=============================================
ICONOS PARA EL MODULO DE CATALOGOS
=============================================*/

export const iconos = {
    AccountBalanceIcon,
    UploadFileIcon,
    AnalyticsIcon,
    AccountBalanceWalletIcon,
    ApartmentIcon,
    GroupIcon,
    BadgeIcon,
    ConnectWithoutContactIcon,
    TocIcon,
    AccountCircleIcon,
    DashboardIcon,
    HttpsIcon,
    ReceiptIcon,
    JoinInnerIcon,
    TrendingUpIcon,
    // QuickbooksIcon
};

// * CADA VEZ QUE SE AGREGUE UN MODULO DE CATALOGO AGREGAR EL ICONO EN EL MODULO DE CATALOGOS TAMBIEN*

/*=============================================
MODULOS PARA EL MENU DINAMICO
=============================================*/

// * CADA VEZ QUE SE AGREGUE UN MODULO NUEVO, FAVOR DE AGREGARLO A ESTE ARRAY PARA PODER UTILIZARLO DE MANERA DINAMICA CON SUS RESPECTIVOS ATRIBUTOS*

export const modulos = [

    /*=============================================
    SECCION DE CATALOGO
    =============================================*/
    { type: 'subheader', label: 'Catalogo', icono: 'TocIcon'},

    { value: 'catalogo.Empresas', label: 'Empresas', icono: 'ApartmentIcon', url: 'empresas' },
    { value: 'catalogo.Bancos', label: 'Bancos', icono: 'AccountCircleIcon', url: 'bancos' },
    { value: 'catalogo.Clientes', label: 'Clientes', icono: 'GroupIcon', url: 'clientes' },
    { value: 'catalogo.Ejecutivo', label: 'Ejecutivos', icono: 'BadgeIcon', url: 'ejecutivos' },
    { value: 'catalogo.RelacionCliente', label: 'Relación Cliente - Ejecutivo', icono: 'ConnectWithoutContactIcon', url: 'relaciones' },
    { value: 'catalogo.Permisos', label: 'Permisos', icono: 'HttpsIcon', url: 'permisos' },

    /*=============================================
    SECCION DE SALDO DE BANCO
    =============================================*/
    { type: 'subheader', label: 'Saldo de bancos', icono: 'AccountBalanceIcon'},

    { value: 'saldo de bancos.Dashboard', label: 'Dashboard', icono: 'DashboardIcon', url: 'dashboardBancos' },
    { value: 'saldo de bancos.Loadfile', label: 'Subir Archivo', icono: 'UploadFileIcon', url: 'loadfile' },
    { value: 'saldo de bancos.Analytics', label: 'Analytics', icono: 'AnalyticsIcon', url: 'analitycs' },

    /*=============================================
    SECCION DE CARTERA VENCIDA
    =============================================*/
    { type: 'subheader', label: 'Cartera Vencida', icono: 'AccountBalanceWalletIcon'},
    { value: 'cartera vencida.Comparativa', label: 'Comparativa', icono: 'AccountBalanceWalletIcon', url: 'comparativa' },

    /*=============================================
    SECCION DE INFORMACION RTS
    =============================================*/
    { type: 'subheader', label: 'Quickbooks', icono: 'ReceiptIcon'},
    { value: 'quickbooks.Comparativa', label: 'Comparativa', icono: 'JoinInnerIcon', url: 'ordencomparativa' },
    { value: 'quickbooks.ProfitNLoss', label: 'ProfitNLoss', icono: 'TrendingUpIcon', url: 'profitnl' },
];

