import React from 'react';
import logoSiif from "../../assets/img/LogoSiif_nuevo.png";
import fondoEBT from "../../assets/img/fondoebtsuite.png";
import Cookies from 'js-cookie';

// LIBRERIAS

import axios from 'axios';
import {baseURL} from '../../config/url';
import { useNavigate } from 'react-router-dom';
import { useState } from "react";

const Codigoauth = ({setShowLogin}) => {

  const navigate = useNavigate();

  // ===============================================
  // ACTUALIZAR VALORES DEL EMIAL Y EL PASSWORD
  // ===============================================

  const [codigo, setCodigo] = useState('');
  const [usuario, setUsuario] = useState('');

  const handleChange = (event) => {

    const val = event.target.value;
    const name = event.target.name;

    if(name === "codigo"){

        setCodigo(val)

    }

    if(name === "usuario"){

        setUsuario(val)
  
      }

  }

  // ===============================================  
  // FUNCION PARA EL SUBMIT DEL LOGIN
  // =============================================== 

  const handleSubmit = () => {

    let cookie = Cookies.get('user')
    
    if(codigo !== ''){

      axios.post(`${baseURL}authenticateCode`,{

        codigo: codigo,
        usuario: cookie
  
      })
      .then(result => {
        
        if(result.data.success === false)
        {
          //setErr(false);
        }
        else
        {
          const token = result.data.result.token;
          const modulos = JSON.stringify(result.data.result.modulos);
          const expires = new Date();
          expires.setDate(expires.getDate() + 1);

          expires.setDate(expires.getDate() + 1);
          Cookies.set("token", token, { expires });
          Cookies.set("login", "1", { expires });
          Cookies.set('menu', modulos, {expires});
          setShowLogin(false);

          navigate("/dashboard");

        }
  
      })
      .catch(error => {
  
        //setErr(false);
        console.log(error)
  
      })

    }

  };

    return (

        <div className="login-container" style={{backgroundImage: `url(${fondoEBT})`, backgroundSize: '100% 100%'}}>

          <form autoComplete="off">

            <div className="login-box">

              <div className="logo-container">
                <img src={logoSiif} style={{width: "200px"}}/>
              </div>

              <input 
                name="codigo"
                type="text" 
                placeholder="Código de acceso" className="input-field" 
                onChange={handleChange}
              />

              <button className="login-button" type='button' onClick={handleSubmit}>Mandar Codigo</button>

              </div>

            </form>

        </div>
        
    )

}

// Estilos CSS

const styles = `

  .login-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
  }

  .background-image {
    /* Estilos para la imagen de fondo */
  }

  .login-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #ffffff;
    padding: 20px;
    border-radius: 10px;
  }

  .logo-container {
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .input-field {
    width: 300px;
    height: 40px;
    margin-bottom: 10px;
    padding: 5px;
  }

  .login-button {
    width: 150px;
    height: 40px;
    background: linear-gradient(to right, #26A5E2, #2ED0C6);
    color: #ffffff;
    border: none;
    cursor: pointer;
    font-size: 18px;
    font-weight: bold;
  }

`;

// Aplicar los estilos al componente
const styleSheet = document.createElement('style');
styleSheet.innerText = styles;
document.head.appendChild(styleSheet);

export default Codigoauth