
import dayjs from 'dayjs';
import api from "../../../config/axios";
import { Box, Collapse, Grid, IconButton, TextField, Typography } from "@mui/material";

import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { useEffect, useState } from 'react';
import { useTheme } from '@emotion/react';
import { tokens } from '../../../theme';
import PlantillaPNL from '../plantillas/PlantillaPNL';

const ProfitLossEBT = (params) => {
    return (
		<>
            <Box m="20px">
                <PlantillaPNL {...params} realmId="1355379100" />               
            </Box>
        </>
	);
};
export default ProfitLossEBT;
