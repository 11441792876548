
import dayjs from 'dayjs';
import api from "../../../config/axios";
import { Box, Button, Collapse, Grid, IconButton, TextField, Typography } from "@mui/material";
import MyTreeview from "../../../components/MyTreeview";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { useEffect, useState } from 'react';
import { useTheme } from '@emotion/react';
import { tokens } from '../../../theme';
import { KeyboardArrowDown, KeyboardArrowRight } from '@mui/icons-material';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';

const PlantillaPNL = (params) => {
    const { realmId, setDisabled, setTabValue } = params;
    const hasConsolidation = () => {
        return localStorage.getItem('123145830174682_pnl') !== null && localStorage.getItem('9130349411431146_pnl') !== null && localStorage.getItem('1355379100_pnl') !== null;
    }
    const theme  = useTheme();
    const colors = tokens(theme.palette.mode);
    const [data, setData] = useState([]); // data from api
    const [inicioMes, setInicioMes] = useState(dayjs().startOf('month'));
	const [finMes, setFinMes] = useState(dayjs().endOf('month'));
    // 
    const [fechaInicio, setFechaInicio] = useState(dayjs().startOf('month').format('YYYY-MM-DD'));
	const [fechaFin, setFechaFin] =useState(dayjs().endOf('month').format('YYYY-MM-DD'));
    
    const [nombre_empresa, setNombreEmpresa] = useState('');
    const [periodo, setPeriodo] = useState(''); // [inicio, fin]
    
    const BotonRunReport = ({ onRunReport }) => {
        const handleClick = (params) => {
            onRunReport(params);
            setDisabled(!hasConsolidation());
        };
        return (
            <Button
                variant="contained"
                sx={{backgroundColor: colors.blueAccent[700], color: colors.grey[100], borderRadius: '15px'}}
                startIcon={<PlayArrowIcon />}
                onClick={() => handleClick()}
            >
                Ejecutar reporte
            </Button>
        );
    }
    const __setLocalStorage = (key, value) => {
        localStorage.setItem(`${key}_pnl`, JSON.stringify(value));
    }
    const __removeLocalStorage = (key) => {
        localStorage.removeItem(`${key}_pnl`);
    }
    const __getLocalStorage = (key) => {
        return JSON.parse(localStorage.getItem(`${key}_pnl`));
    }

    const runReport = async () => {
        const params = {
            realmId: realmId,
            start_date: fechaInicio,
            end_date: fechaFin,
        };
        const response = await api.post("/quickbooks/reports/profit_loss", params);
        if(response.data.success === true && response.data.result?.error){
            setTabValue(0);
            setData([]);
            __removeLocalStorage(realmId);
        }else if(response.data.success === true){
            setData(response.data.result);
            __setLocalStorage(realmId, response.data.result);
        }else{
            setData([]);
            __removeLocalStorage(realmId);
        }
        
    }
    const [isOpen, setIsOpen] = useState(false);

    const handleExpandClick = () => {
        setIsOpen(!isOpen);
    };
    useEffect(() => {
        setDisabled(!hasConsolidation());
    }, []);
    useEffect(() => {
        if(localStorage.getItem(`${realmId}_pnl`) !== null && data.length === 0) {
            setData(__getLocalStorage(realmId));
        }
        setNombreEmpresa(data[0]?.nombre_empresa || '')
        setPeriodo(data[0]?.periodo || '')
    }, [data]);
    return (
        <>
             <Box m="20px">
                <IconButton
                    aria-label="expand row"
                    size="small"
                    onClick={() => setIsOpen(!isOpen)}
                >
                    { isOpen ? (<KeyboardArrowDown />) : (<KeyboardArrowRight />) }
                    Filtros de búsqueda
                </IconButton>
                <Box marginBottom='20px'>
                    <Collapse in={isOpen} timeout="auto" unmountOnExit>
                    <Box display="flex" >
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                defaultValue={inicioMes}
                                sx={{width: '250px', marginRight: '10px'}}
                                views={['year', 'month', 'day']}
                                renderInput={(props) => <TextField {...props} fullWidth label="Fecha inicial" size="small" />}
                                onChange={(value) => setFechaInicio(dayjs(value).format('YYYY-MM-DD'))}
                            />
                        </LocalizationProvider>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                sx={{width: '250px', marginRight: '10px'}}
                                defaultValue={finMes}
                                views={['year', 'month', 'day']}
                                renderInput={(props) => <TextField {...props} fullWidth label="Fecha final" size="small" />}
                                onChange={(value) => setFechaFin(dayjs(value).format('YYYY-MM-DD'))}
                            />
                        </LocalizationProvider>
                        <BotonRunReport onRunReport={runReport}/>
                    </Box>
                    </Collapse>
                </Box>
                {
                    data.length === 0 ? (
                        <Box display="flex" justifyContent="center" alignItems="center">
                            <Typography variant="h4" color="textSecondary">No hay datos para mostrar</Typography>
                        </Box>
                    ) : (
                        <>
                            <Typography variant="h2" gutterBottom component="div" align="center">
                                {nombre_empresa}
                            </Typography>
                            <Typography variant="h4" fontWeight="bold" gutterBottom component="div" align="center">
                                Profit & Loss
                            </Typography>
                            <Typography variant="h4" gutterBottom component="div" align="center">
                                {periodo}                        
                            </Typography>
                            <MyTreeview data={data} />
                        </>
                    )
                }
            </Box>
        </>
    )
}
export default PlantillaPNL;