import React from "react";
import logoSiif from "../../assets/img/LogoSiif_nuevo.png";
import fondoEBT from "../../assets/img/fondoebtsuite.png";
import Cookies from "js-cookie";

import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';

// LIBRERIAS

import axios from "axios";
import { baseURL } from "../../config/url";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

const Login = ({ setShowLogin }) => {
  const navigate = useNavigate();

  // ===============================================
  // ACTUALIZAR VALORES DEL EMIAL Y EL PASSWORD
  // ===============================================

  const [user, setUser] = useState("");
  const [password, setPassword] = useState("");
  const [isLoggin, setisLoggin] = useState('');

  const handleChange = (event) => {
    const val = event.target.value;
    const name = event.target.name;

    if (name === "user") {
      setUser(val);
    }

    if (name === "password") {
      setPassword(val);
    }
  };

  // ===============================================
  // FUNCION PARA EL SUBMIT DEL LOGIN
  // ===============================================

  const handleLogin = () => {
    if (user !== "" && password !== "") {
      axios
        .post(`${baseURL}authenticate`, {
          email: user,
          password: password,
        })
        .then((result) => {
          if (result.data.success === false) {
            //setErr(false);
            setisLoggin('no')
          } else {
            
            Cookies.set("user", user);
            Cookies.set("user_id", result.data.result.id);
            /* const expires = new Date();
            expires.setDate(expires.getDate() + 1);
            
            Cookies.set('menu', modulos, {expires}); */
            
            navigate("/auth");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  return (
    <div
      className="login-container"
      style={{
        backgroundImage: `url(${fondoEBT})`,
        backgroundSize: "100% 100%",
      }}
    >
      <form autoComplete="off">
        <div className="login-box">
          <div className="logo-container">
            <img src={logoSiif} style={{ width: "200px" }} />
          </div>

          <input
            name="user"
            type="email"
            placeholder="Correo electrónico"
            className="input-field"
            onChange={handleChange}
          />

          <input
            name="password"
            type="password"
            placeholder="Contraseña"
            className="input-field"
            onChange={handleChange}
          />

          <button className="login-button" type="button" onClick={handleLogin}>
            Iniciar sesión
          </button>
        </div>
        {isLoggin == 'no' && (
          <Alert severity="error">
            <AlertTitle>Error</AlertTitle>
            Datos incorrectos — <strong>Verifica tus credenciales!</strong>
          </Alert>
        )}
      </form>
    </div>
  );
};

// Estilos CSS

const styles = `

  .login-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
  }

  .background-image {
    /* Estilos para la imagen de fondo */
  }

  .login-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #ffffff;
    padding: 20px;
    border-radius: 10px;
  }

  .logo-container {
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .input-field {
    width: 300px;
    height: 40px;
    margin-bottom: 10px;
    padding: 5px;
  }

  .login-button {
    width: 150px;
    height: 40px;
    background: linear-gradient(to right, #26A5E2, #2ED0C6);
    color: #ffffff;
    border: none;
    cursor: pointer;
    font-size: 18px;
    font-weight: bold;
  }

`;

// Aplicar los estilos al componente
const styleSheet = document.createElement("style");
styleSheet.innerText = styles;
document.head.appendChild(styleSheet);

export default Login;
