import { Box, Button, Tab, Tabs, useTheme } from "@mui/material";
import Header from "../../components/Header";
import { tokens } from "../../theme";
import React, { useEffect, useState } from "react";
import Consolidado  from "./tabs/Consolidado";
import Conexiones from "./tabs/Conexiones";
import ProfitLossEBT from "./tabs/ProfitLossEBT";
import ProfitLossEBF from "./tabs/ProfitLossEBF";
import ProfitLossEBL from "./tabs/ProfitLossEBL";
const ProfitNLoss = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [estatus, setEstatus] = useState([]);
    const [value, setValue] = React.useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const sx_props_tab = {
        fontSize: '14px',
    }
    const [disableConsolidado, setDisableConsolidado] = useState(true);
    const [disablePNLEBT, setDisablePNLEBT] = useState(false);
    const [disablePNLEBF, setDisablePNLEBF] = useState(false);
    const [disablePNLEBL, setDisablePNLEBL] = useState(false);
    
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    useEffect(() => {
        window.scrollTo(0, 0);
        setDisablePNLEBF(estatus.filter(e => e.realmId === "9130349411431146").some(e => e.estatus === false));
        setDisablePNLEBL(estatus.filter(e => e.realmId === "1355379100").some(e => e.estatus === false));
        setDisablePNLEBT(estatus.filter(e => e.realmId === "123145830174682").some(e => e.estatus === false));
    }, [estatus]);
    // const renderComponent = () => {
    //     return value === 0 ? <Peticiones /> : <Compras/>;//<Requisiciones />;
    // }
    const TAB_ITEMS = [
        { label: "Conexiones",  component: <Conexiones setEstatusParent={setEstatus}/>    },
        { label: "Consolidado", component: <Consolidado />, disabled: disableConsolidado  },
        { label: "PNL EBT",     component: <ProfitLossEBT setTabValue={setValue} setDisabled={setDisableConsolidado} />,  disabled: disablePNLEBT},
        { label: "PNL EBF",     component: <ProfitLossEBF setTabValue={setValue} setDisabled={setDisableConsolidado} />,  disabled: disablePNLEBF},
        { label: "PNL EBL",     component: <ProfitLossEBL setTabValue={setValue} setDisabled={setDisableConsolidado} />,  disabled: disablePNLEBL},
    ];
    return(
        <Box m="20px">
            <Header title="Profit & Loss" subtitle="Consolidacion" />
            <Tabs value={value} onChange={handleChange} variant="fullWidth" textColor="secondary" centered>
                {TAB_ITEMS.map((item, index) => (
                    <Tab style={{borderRadius:'15px', marginLeft:'10px', fontWeight: item.disabled ? 'normal' : 'bold', backgroundColor: value === index ? colors.greenAccent[800] : colors.grey[700]}} key={index} label={item.label} sx={sx_props_tab} disabled={item.disabled}/>
                ))}
            </Tabs>
            {TAB_ITEMS[value] && TAB_ITEMS[value].component}
        </Box>
    )
}
export default ProfitNLoss;