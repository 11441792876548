// LIBRERIAS
import React from 'react';
import { Formik, Form } from 'formik';
import { styled } from '@mui/system'
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material";
import { tokens } from '../../theme';
import Swal from 'sweetalert2';
import { Button, Modal, Paper, Typography, Box, Container, Grid  } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';

// ICONOS
import SaveIcon from '@mui/icons-material/Save';

// COMPONENTES
import TextInput from '../inputs/TextInput';
import api from '../../config/axios';
import MySelect from '../inputs/MySelect';
import MyMultipleSelect from '../inputs/MyMultipleSelect ';
import MySwitch from '../inputs/MySwitch';
import MyNumberInput from '../inputs/MyNumberInput';
import MyPhoneInput from '../inputs/MyPhoneInput';
import MyInputPassword from '../inputs/MyInputPassword';

import MyDateInput from '../inputs/MyDateInput';

// ===============================================
// INICIO DEL COMPONENTE
// ===============================================

const ModalCreateGM = ({

    // PROPS PARA USAR EN EL COMPONENTE

    modalTitle, // TITULO DEL MODAL
    swalTitle, // TITULO QUE MOSTRARA EL SWAL
    url, // URL PARA EL AXIOS 
    open, // FUNCION PARA ABRIR MODAL
    onClose, // FUNCION PARA CERRAR MODAL
    size, // TAMANIO DEL MODAL EN PORCENTAJE %
    initialValues, // OBJETO DE CAMPOS PARA EL FORMULARIO
    inputForms, // OBJETO CON INPUTS PARA ARMAR FORMULARIO
    yupValidation, // ESQUEMA DE VALIDACION DE YUP
    refresh // REFRESCAR TABLA DESPUES DEL SUBMIT

}) => {

    // COMPONENTE QUE FUNCIONARA COMO BODY DEL MODAL

    const ScrollableContainer = styled(Container)({
        maxHeight: '60vh', // Ajusta esta altura máxima como desees
        overflowY: 'auto',
    });

    // VALIDAR SI SE ESTA VISUALIZANDO DESDE UN DISPOSITIVO MOVIL

    const isNonMobile = useMediaQuery("(min-width:600px)");

    // COLORES PARA LOS ESTILOS

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    // FUNCION PARA EL SUBMIT

    const handleSubmit = (values) => {

        api.post(url, values)
        .then((response) => {

            if(response.data.success === true){

                onClose();

                refresh();

                Swal.fire({
                    icon: 'success',
                    title: swalTitle,
                })

            }

        })
        .catch((error) => {
            console.log("ocurrio un error: " +error);
        })

    }

    return (

        <Modal
            open={open}
            onClose={onClose}
            BackdropProps={{onClick: (event) => event.stopPropagation}}
            style={{ 
                display: 'flex', 
                alignItems: 'center', 
                justifyContent: 'center' ,
            }}
        >

            <Formik
                initialValues={initialValues}
                validationSchema={yupValidation}
                onSubmit={(values, { setSubmitting }) => {
                    handleSubmit(values)
                }}
            >

                <Paper sx={{ width: isNonMobile ? `${size}%` : '90%', p: 3, bgcolor: colors.primary[1000] }}>

                    <Form>

                        {/* HEADER DEL MODAL */}

                        <Typography variant="h3" align="center">
                            {modalTitle}
                        </Typography>

                        {/* BODY DEL MODAL */}

                        <ScrollableContainer>

                            {/* Inputs go here */}

                            <Grid container spacing={2} marginTop={"10px"} marginBottom={"10px"}>

                                {
                                    inputForms.map((op, index) => (

                                        <Grid item md={op.md} xs={op.xs} key={index}>

                                            {/* VALIDAR SI ES UN INPUT */}

                                            {
                                                op.componente === 'input' && (

                                                    <TextInput
                                                        label={op.label}
                                                        name={op.name}
                                                        key={op.name}
                                                        type={op.type}
                                                        placeholder={op.placeholder}
                                                    />

                                                )
                                            }

                                            {/* VALIDAR SI ES UN INPUT TIPO NUMBER */}

                                            {
                                                op.componente === 'number' && (

                                                    <MyNumberInput
                                                        label={op.label}
                                                        name={op.name}
                                                        key={op.name}
                                                    />

                                                )
                                            }

                                            {/* VALIDAR SI ES PARA UNA CONTRASENA */}

                                            {

                                                op.componente === 'password' && (

                                                    <MyInputPassword
                                                        label={op.label}
                                                        name={op.name}
                                                        key={op.name}
                                                    />

                                                )

                                            }

                                            {/* VALIDAR SI ES UN SWITCH */}

                                            {

                                                op.componente === 'switch' && (

                                                    <MySwitch
                                                        name={op.name}
                                                        label={op.label}
                                                    />

                                                )

                                            }

                                            {/* VALIDA SI ES UN SELECT */}

                                            {
                                                op.componente === 'phone' && (

                                                    <MyPhoneInput
                                                        label={op.label}
                                                        name={op.name}
                                                        key={op.name}
                                                    />

                                                )
                                            }

                                            {/* VALIDA SI ES UN SELECT */}

                                            {
                                                op.componente === 'select' && (

                                                    <MySelect label={op.label} name={op.name} key={op.name}>

                                                        {
                                                            op.options.map((op2) => (

                                                                <MenuItem value={op2[op.value]} key={op2[op.value]}>{op2[op.text]}</MenuItem>

                                                            ))
                                                        }

                                                    </MySelect>

                                                )
                                            }

                                            {/* VALIDAR SI ES UN SELECT MULTIPLE */}

                                            {
                                                op.componente === 'selectMultiple' && (

                                                    <MyMultipleSelect
                                                        name={op.name}
                                                        key={op.name}
                                                        label={op.label}
                                                        options={op.options}
                                                    />

                                                )
                                            }

                                            
                                            {/* VALIDAR SI ES UN INPUT TIPO DATE */}

                                            {


op.componente === 'inputDate' && (

    <MyDateInput
        label={op.label}
        name={op.name}
        key={op.name}
        format={op.format}
        Locale={op.Locale}
        placeholder={op.placeholder}
    />

)
}

                                        </Grid>

                                    ))
                                }

                            </Grid>

                        </ScrollableContainer>

                        {/* FOOTER DEL MODAL */}

                        <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>

                            <Button variant="contained" color="primary" onClick={onClose}>
                                Cancelar
                            </Button>

                            <Button type="submit" variant="contained" sx={{color: "white"}} color="success" startIcon={<SaveIcon />}>
                                Guardar
                            </Button>

                        </Box>

                    </Form>

                </Paper>

            </Formik>

        </Modal>

    )

}

export default ModalCreateGM