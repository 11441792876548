import React, { useState, useEffect } from "react";
import { Box, useTheme, Button } from "@mui/material";
import { tokens } from "../../theme";
import Swal from "sweetalert2";
import Header from "../../components/Header";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider, dateCalendarClasses } from "@mui/x-date-pickers";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DataGrid, GridToolbar, esES  } from "@mui/x-data-grid";

// ICONOS
import ManageSearchIcon from '@mui/icons-material/ManageSearch';

//COMPONENTES
import api from "../../config/axios";
import { InputLabel } from '@mui/material';

import 'dayjs/locale/es-mx';
import { useLocation, useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';

function Comparativa() {
  // ===============================================
  // COLORES PARA ESTILOS
  // ===============================================
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const navigate = useNavigate();

  var Locale = "es-mx";

  const location = useLocation();
  const { info } = location.state || {};

  // ===============================================
  // USES STATES
  // ===============================================

  const [selectedDate, setSelectedDate] = useState(null);

  const handleSubmit = async () => {
    if (selectedDate && selectedDate.$d!='Invalid Date') {
      // Aquí puedes utilizar la fecha seleccionada como necesites
      let fecha = selectedDate.$d.toJSON().substring(0, 10);
      buscarComparativa(fecha);
    } else {
      console.log("Ninguna fecha seleccionada");
      Swal.fire({
        icon: "error",
        title: "¡Seleccione una fecha, por favor!",
      });
    }
  };

  // ===============================================
  // USEEFECT PARA DETECTAR EL CAMBIO DE FECHA
  // ===============================================
  useEffect(() => {

    window.scrollTo(0, 0);

    if(info)
    {
        const objeto = JSON.parse(info);
        let fecha = objeto.fecha.substring(0, 10);
    
        let fecharecibida = dayjs(fecha);
        setSelectedDate(fecharecibida);

        let fecha2 = fecharecibida.$d.toJSON().substring(0, 10);
        buscarComparativa(fecha2);
    }    

  }, []);

  // ===============================================
  // FUNCION QUE SETEA LA FECHA
  // ===============================================
  const handleChange = (date) => {
    setSelectedDate(date);
    setInformacionTabla([]);
  };

  // ===============================================
  // SEGMENTO PARA LLENAR LA TABLA
  // ===============================================

  const [informacionTabla, setInformacionTabla] = useState([]);

  const buscarComparativa = async (fecha) => {
    const response = await api.get(`get/comparativa/${fecha}`);

    if (
      response.data.success === true &&
      response.data.result !== "Sin resultados"
    ) {
        //console.log(response.data.result)
      setInformacionTabla(response.data.result);
    } else {
      console.log("ERROR");
      setInformacionTabla([]);
    }
  };

  // ===============================================
  // COLUMNAS DE LAS TABLAS
  // ===============================================
  const columns = [
    { field: "id", headerName: "No.", flex: 2, },
    {
      field: "razon_social",
      headerName: "Empresa",
      flex: 2,
    },
    {
      field: "inicio",
      headerName: "Inicio",
      type: "number",
      flex: 2,
      renderCell: (params) => (
        // Formatear el valor de la celda como moneda con "$" y separador de miles
        `$${params.value.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`
      ),
    },
    {
      field: "fin",
      headerName: "Fin",
      type: "number",
      flex: 2,
      renderCell: (params) => (
        // Formatear el valor de la celda como moneda con "$" y separador de miles
        `$${params.value.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`
      ),
    },
    {
      field: "dif",
      headerName: "Diferencia",
      type: "number",
      flex: 2,
      renderCell: (params) => (
        // Formatear el valor de la celda como moneda con "$" y separador de miles
        `$${params.value.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`
      ),
    }
  ];

  const handleRowClick = (params) => {

    //console.log(typeof selectedDate)

    let parametros = { fecha: selectedDate, id_empresa: params.row.id, empresa: params.row.razon_social }

    let info = JSON.stringify(parametros);

    //console.log(info)

    navigate('/carteravencida', { state: { info: info } });

}

  return (
    <Box m="20px">
      <Header title="Comparativa" subtitle="Información de estado de cuenta de los clientes por empresa." />
      <form /* onSubmit={handleSubmit} */>
            <InputLabel id="demo-simple-select-label">Seleccione una fecha:</InputLabel>
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={Locale}>
              <DatePicker
                value={selectedDate}
                onChange={handleChange}
                format="DD/MM/YYYY"
              />
            </LocalizationProvider>
            <Button
              type="button"
              color="inherit"
              onClick={() => handleSubmit()}
              variant="contained"
              startIcon={<ManageSearchIcon />}
              sx={{ color: "primary", fontWeight: "bold", marginTop: "20px", marginLeft: "20px", width: "190px" }}
            >
              Buscar
            </Button>
      </form>
          <Box
            sx={{
              height: 400,
              width: "100%",
              "& .positive": {
                color: colors.blueAccent[700] === "#a4a9fc" ? "green" : "#66BB6A",
              },
              "& .negative": {
                color: colors.blueAccent[700] === "#a4a9fc" ? "red" : "#E86258",
              },
              "& .MuiDataGrid-root": {
                border: "none",
              },
              "& .MuiDataGrid-cell": {
                //backgroundColor: "#66BB6A",
                borderBottom: "none",
              },
              "& .name-column--cell": {
                color: colors.greenAccent[300],
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: colors.blueAccent[700],
                borderBottom: "none",
              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: colors.primary[400],
              },
              "& .MuiDataGrid-footerContainer": {
                borderTop: "none",
                backgroundColor: colors.blueAccent[700],
              },
              "& .MuiCheckbox-root": {
                color: `${colors.greenAccent[200]} !important`,
              },
              "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                color: `${colors.grey[100]} !important`
              }
            }}
          >
            <DataGrid
              rows={informacionTabla}
              columns={columns}
              components={{ Toolbar: GridToolbar }}
              componentsProps={{
                toolbar: {
                    csvOptions : { utf8WithBom: true }
                },
            }}
            /*
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 5,
                  },
                },
              }}*/
              getRowId={(row) => row.id}
              getCellClassName={(params) => {
                //console.log(params)
                if (params.field === "dif" && (params.row.inicio > 0 || params.row.fin > 0))
                {
                    if (params.value === 0) {
                        return "positive"
                    } else {
                        return "negative"
                    }
                } else {
                  return "";
                }
                }}
              //pageSizeOptions={[5]}
              //disableRowSelectionOnClick
              onRowClick={(params) => {handleRowClick(params)}}
              //onCellClick={handleCellClick}
              localeText={esES.components.MuiDataGrid.defaultProps.localeText}
            />
          </Box>
    </Box>
  );
}

export default Comparativa;
