import Cookies from 'js-cookie';

// FUNCION PARA MARCAR LA URL ACTUAL

export function marcarURL(url){

    // SETEAR RUTA DONDE NOS ENCONTRAMOS PARA EL REFRESH

    const expires = new Date();

    expires.setDate(expires.getDate() + 1);

    Cookies.set('modulo', url, {expires});

}

export function logOutFunction(){

    const expires = new Date();

    expires.setDate(expires.getDate() + 1);

    Cookies.remove('token');
    Cookies.set('login', "0", {expires});


    window.location.href = '/';

}