import React, { useState, useMemo, useEffect } from 'react'

import { Box, useTheme, Button, Typography } from "@mui/material";
import { tokens } from "../../theme";
import Header from '../../components/Header'
import api from '../../config/axios';
import { useDropzone } from "react-dropzone"
import * as XLSX from 'xlsx'
import Swal from 'sweetalert2';
import imgXls from "../../assets/img/xlsx_icon.svg"

// ICONOS
import UploadIcon from '@mui/icons-material/Upload';

const baseStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#1f2a40',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#1f2a40',
    outline: 'none',
    transition: 'border .24s ease-in-out'
};

const focusedStyle = {
    borderColor: '#2196f3'
};
  
  const acceptStyle = {
    borderColor: '#00e676'
};
  
  const rejectStyle = {
    borderColor: '#ff1744'
};

function CargaInfoMicro() {

    const theme = useTheme();

    function cerrarDialogo() 
    {
        window.parent.postMessage('cerrarDialogo', window.location);
    }

    const [empresa, setEmpresa] = useState("0");

    useEffect(() => {
      const urlParams = new URLSearchParams(window.location.search);
      const modo = urlParams.get('modo');
      const empresa = urlParams.get('e');

      if(empresa === "0")
        cerrarDialogo();
      else
        setEmpresa(empresa);
  
      if (modo === 'light') {
        theme.palette.mode = 'light';
        theme.palette.background = colors.primary[500];
      } else if (modo === 'dark') {
        theme.palette.mode = 'dark';
      }
    }, [theme]);

    const colors = tokens(theme.palette.mode);
  
    const [datos, setdatos] = useState([]);
    const {
        getRootProps,
        getInputProps,
        isFocused,
        isDragAccept,
        isDragReject,
        acceptedFiles
    } = useDropzone({
        accept: {'application/vnd.ms-excel': [], 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': []},
        maxFiles: 1,
        onDrop:(acceptedFiles) => {
            
            const reader = new FileReader();
    
            reader.onload = (e) => {
              const data = e.target.result;
              const workbook = XLSX.read(data, { type: 'binary' });
              const sheetName = workbook.SheetNames[0];
              const sheet = workbook.Sheets[sheetName];
              const jsonData = XLSX.utils.sheet_to_json(sheet);
    
              setdatos(jsonData)
            };
        
            reader.readAsBinaryString(acceptedFiles[0]);
        }
    });
    
    const style = useMemo(() => ({
        ...baseStyle,
        ...(isFocused ? focusedStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {})
    }), [
        isFocused,
        isDragAccept,
        isDragReject
    ]);

    const handleSubmit = () =>{
        if(datos.length !== 0){

            Swal.fire({
                icon: 'info',
                title: "Cargando...",
                allowOutsideClick: false, // Evita que se cierre haciendo clic fuera
                showConfirmButton: false, // Para evitar que el usuario cierre el mensaje
            });

            api.post('post/uploadMicro', {...datos, empresa: empresa})
            .then((response) => {
                console.log("Cargado", response.data); 
                Swal.close();
                Swal.fire({
                    icon: 'success',
                    title: "¡Archivo Cargado!",
                });            
            })
            .catch((error) => {
                console.log("ocurrio un error: " +error);
                Swal.close();
                Swal.fire({
                    icon: 'error',
                    title: "¡Ocurrió un error!",
                });
            })  
            .finally(() => {
                setdatos([]);
            });     
            /*    
            Swal.fire({
                icon: 'success',
                title: "¡Archivo Cargado!",
            })
            setdatos([]);
            */
        }else{
            Swal.fire({
                icon: 'error',
                title: "¡Seleccione un archivo!",
            })
        }
    }
    
  return (
        <Box m="20px">
            {/*<Header title="Cargar Antigüedad de Micro" subtitle="Cargue el documento..." />*/}
            <Header title="Cargar Info de Microsip" subtitle="Cargue el documento..." />
            <Box m="40px 0 0 0"
                height="75vh"
                width="100%">
                <div {...getRootProps({style})}>
                    <input {...getInputProps()} />
                    <p>Arrastre el archivo aquí o de clic para seleccionar el archivo.</p>
                </div>
                <aside>
                <h4>
                <p style={{ color: colors.blueAccent[100] }}>Archivo Cargado:</p>
                </h4>                
                <ul id='listaArchivo'>
                    {acceptedFiles.map(file => (
                    <li key={file.path}>
                        <Typography color={colors.blueAccent[100]}>
                        <img src={imgXls} style={{height: '20px'}}></img> {file.path} - {file.size} bytes
                        </Typography>
                    </li>
                    ))}
                </ul>
                </aside>
                <Button type="submit" onClick={() => handleSubmit()} color="success" variant="contained" startIcon={<UploadIcon />} sx={{color: "white", fontWeight: "bold", marginTop: "20px"}}>
                    Subir
                </Button>                   
            </Box>
        </Box>
  )
}

export default CargaInfoMicro