import { useTheme } from "@emotion/react";
import { Box, Button, FormControl, Grid, InputLabel, MenuItem, Modal, Paper, Select, TextField, Typography } from "@mui/material";
import { useMemo, useState } from "react";
import { tokens } from "../../../theme";
import { useEffect } from "react";
import { useDropzone } from "react-dropzone"

import * as XLSX from 'xlsx'
import imgXls from '../../../assets/img/xlsx_icon.svg'

// ICONOS
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
// import PublishIcon from '@mui/icons-material/Publish';

// import dayjs from 'dayjs';
import api from "../../../config/axios";
import Swal from "sweetalert2";
// import MySnackBar from "../../../components/feedback/MySnackBar";
import { format, set } from 'date-fns';


// const ExcelJS = require('exceljs');

const baseStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#1f2a40',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#1f2a40',
    outline: 'none',
    transition: 'border .24s ease-in-out'
};

const focusedStyle = {
    borderColor: '#2196f3'
};
  
  const acceptStyle = {
    borderColor: '#00e676'
};
  
  const rejectStyle = {
    borderColor: '#ff1744'
};


const ModalArchivo = (props) => {
	const {open, onClose, onOpen, setArchivo, filtros, setData, setShowButton, setLoading } = props;
	// Customize colors
	const theme  = useTheme();
    const colors = tokens(theme.palette.mode);
    const [display, setDisplay] = useState('flex');

	// ===============================================
    // COMPONENTE PARA CARGAR ARCHIVO EXCEL
    // ===============================================
	const [datosJson, setDatosJson] = useState([]);
    const [esFactura, setEsFactura] = useState(false);
    
	const {
        getRootProps,
        getInputProps,
        isFocused,
        isDragAccept,
        isDragReject,
        acceptedFiles
    } = useDropzone({
        accept: {'application/vnd.ms-excel': [], 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': []},
        maxFiles: 1,
        onDrop:(acceptedFiles) => {
            
            const reader = new FileReader();
    
            reader.onload = (e) => {
                const data = e.target.result;
                const workbook = XLSX.read(data, { type: 'binary' });

                 const jsonData = workbook.SheetNames.map(sheetName => {
                    const sheet = workbook.Sheets[sheetName];
                    return XLSX.utils.sheet_to_json(sheet);
                });

                setDatosJson(jsonData);
            };
            reader.readAsBinaryString(acceptedFiles[0]);
        },
        onFileDialogOpen: () => {setDatosJson([])},
    });
	const style = useMemo(() => ({
        ...baseStyle,
        ...(isFocused ? focusedStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {})
    }), [
        isFocused,
        isDragAccept,
        isDragReject
    ]);
    useEffect(() => {
        window.scrollTo(0, 0);
    },[]);
   
    const agregarArchivo = async () => {
		if(datosJson.length === 0){
            Swal.fire({
                title: 'Error!',
                text: 'No se ha cargado ningun archivo',
                icon: 'error',
                confirmButtonText: 'Aceptar'
            });
            return;
        }
        try {
            setLoading(true);
            const resp = await api.post("/rts/guardar", {data: JSON.stringify(datosJson[0])});
            if(resp.data.success === true){
                Swal.fire({
                    title: resp.data.text,
                    icon: resp.data.icon,
                    confirmButtonText: 'Aceptar'
                });
            }
            // let values = JSON.stringify(datosJson[0]);
            let filtros = JSON.parse(localStorage.getItem('filtros')) || props.filtros //  props.filtros || {};
            // filtros.json = JSON.stringify(datosJson[0]);
            filtros.archivo = JSON.stringify(datosJson[0]);
            setArchivo(datosJson[0]);
            localStorage.setItem('archivo', JSON.stringify(datosJson[0]));

            onClose();
            const response = await api.post("/get/data/comparativa", { ...filtros });
            if(response.data.success === true && response.data.result?.error){
                setData([]);
                Swal.fire({
                    title: 'Error!',
                    text: response.data.result.message,
                    icon: 'error',
                    confirmButtonText: 'Aceptar'
                });
            }else if (response.data.success === true && response.data.result !== "Sin resultados") {
                setData(response.data.result);
                setShowButton(response.data.result.needsAuth)
            }else{
                setData([]);
                setShowButton(response.data.result.needsAuth)
                // setShowButtonQB(false);
            }
            setLoading(false);
        } catch (error) {
            console.error("Upload failed:", error);
        }
	}
	
	return(
		<>
			<Modal
				open={open}
				onClose={onClose}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
                style={{
                    display: display,
                    alignItems: "center",
				    justifyContent: "center",
                }}
			>	
				<Paper sx={{
                    width: "30%",
                    p: 3,
                    bgcolor: colors.blueAccent[900],
                    borderRadius: "10px",
                }} >
					<Typography id="modal-modal-title" variant="h3" align="center">Agregar un archivo</Typography>				
					<Box m="40px 0 0 0"
						width="100%">
						<div {...getRootProps({style})}>
							<input {...getInputProps()} />
							<p>Haga clic aqui o arrastre documentos en esta zona para adjuntar archivos</p>
						</div>
						<aside>
							<h4>Archivo cargado:</h4>
							<ul id='listaArchivoFacturas'>
								{acceptedFiles.map(file => (
									<li key={file.path}>
										<img src={imgXls} style={{height: '20px'}}></img> {file.path} - {file.size} bytes
									</li>
								))}
							</ul>
						</aside>
					</Box>
                  
                    <Grid container spacing={2} sx={{marginTop: '1rem'}} justifyContent="flex-end">
                        <Grid item md={6} xs={6}>
                            <Button variant='contained' sx={{color: "white",width: "100%",borderRadius: "8px" ,marginRight: '10px'}} onClick={onClose} startIcon={<CloseIcon />}>
                                Cancelar
                            </Button>
                        </Grid>
                        <Grid item md={6} xs={6}>
                            <Button variant="contained" 
                                sx={{color: "white",width: "100%",borderRadius: "8px" ,marginRight: '10px'}}
                                color="success"
                                startIcon={<AddIcon />}
                                // onClick={() => procesoGenerarExcel()}
                                onClick={() => agregarArchivo()}
                            >
                                Agregar
                            </Button>
                        </Grid>
                    </Grid>
				</Paper>
			</Modal>
			{/* <MySnackBar open={openSnackbar} action={action} message={message} onClose={handleCloseSnackbar}/> */}

		</>
    )
};
export default ModalArchivo;